// @flow
import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { IContractListItem } from '../../../Services/ContractsListService'
import { timezone } from '../../../../config/constants'
import { DataRowKey, DataRow, DataRowValue } from '../Customership/CustomershipContactDetails'

type Props = {
    contract: IContractListItem
}

// export default class ConsumerContractDetails extends Component<Props> {
const ConsumerContractDetails: React.FunctionComponent<Props> = (props: Props) => {
    const { contract } = props
    const {
        contractProduct,
        contractStart,
        contractEnd,
        meteringpointIdentifier,
        gsrnIdentifier,
        address,
        zip,
        city
    } = contract

    const KayttopaikkaKeyTrimmed = meteringpointIdentifier.split('_').at(-1)
    const alkuPvm = moment(contractStart).tz(timezone).format('D.M.YYYY')
    const loppuPvm = contractEnd === "12/31/9999 12:00:00 AM"
        ? "Voimassa toistaiseksi"
        : moment(contractEnd).tz(timezone).format('D.M.YYYY')
    return (
        <GridDataRowWrapper>
            <GridDataRow>
                <GridDataRowKey>Tuote</GridDataRowKey>
                <GridDataRowValue>{contractProduct}</GridDataRowValue>
            </GridDataRow>
            <GridDataRowDarker>
                <GridDataRowKey>Sopimuksen alku pvm.</GridDataRowKey>
                <GridDataRowValue>{alkuPvm}</GridDataRowValue>
            </GridDataRowDarker>
            <GridDataRow>
                <GridDataRowKey>Sopimuksen loppu pvm.</GridDataRowKey>
                <GridDataRowValue>{loppuPvm}</GridDataRowValue>
            </GridDataRow>
            <GridDataRowDarker>
                <GridDataRowKey>Käyttöpaikan tunnus</GridDataRowKey>
                <GridDataRowValue>{KayttopaikkaKeyTrimmed}</GridDataRowValue>
            </GridDataRowDarker>
            <GridDataRow>
                <GridDataRowKey>Käyttöpaikan osoite</GridDataRowKey>
                <GridDataRowValue>{address}</GridDataRowValue>
            </GridDataRow>
            <GridDataRowDarker>
                <GridDataRowKey>Käyttöpaikan postinumero</GridDataRowKey>
                <GridDataRowValue>{zip}</GridDataRowValue>
            </GridDataRowDarker>
            <GridDataRow>
                <GridDataRowKey>Käyttöpaikan postitoimipaikka</GridDataRowKey>
                <GridDataRowValue>{city}</GridDataRowValue>
            </GridDataRow>
            <GridDataRowDarker>
                <GridDataRowKey>GSRN tunnus</GridDataRowKey>
                <GridDataRowValue>{gsrnIdentifier}</GridDataRowValue>
            </GridDataRowDarker>
        </GridDataRowWrapper>
    )
}

const GridDataRow = styled(DataRow)`
    padding: 0;
    margin: 0 10%;
`
const GridDataRowKey = styled(DataRowKey)`
    padding-top: 10px;
    padding-left: 2px;
    margin: 0;
`
export const GridDataRowValue = styled(DataRowValue)`
    border-bottom: 0;
    padding-top: 10px;
    margin: 0;

    @media(max-width: 480px) {
        padding-left: 2px;
    }
`

const GridDataRowDarker = styled(GridDataRow)`
    background-color: #f5f5f6;
`

const GridDataRowWrapper = styled.div`
    padding-top: 46px;
    padding-bottom: 46px;
`

export default ConsumerContractDetails;
