// @flow
import React from 'react'
import { NavLink } from 'react-router-dom'
type Props = {
   link: string,
   name: string,
   icon: string,
   productClass: string,
}
const LinkBox: React.FunctionComponent<Props> = (props: Props) => {
    const { name, icon, link, productClass} = props
    return (
        <div className={productClass} id={productClass}>
            <NavLink to={link}>
                <div className="top-wrapper noselect">
                    <div className="left">
                        <div className="icon">
                            <img src={icon} alt={name} />
                        </div>
                    </div>
                    <div className="linkbox-name">
                        <h1>{name}</h1>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}

export default LinkBox