// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

//import { ReactComponent as Isannointi } from '../../../assets/images/isannointi.svg'

import Background from '../../../assets/images/home-bg.jpg' // Comment this out if not using
import LoginStore from '../../../store/LoginStore'
// import Introduction from '../../../theme/components/Introduction'
import Introduction from '../../Elements/Introduction'
import LinkWithIconAndArrow from '../../Elements/LinkWithIconAndArrow'
import { textStrings, themeDetails } from '../../../theme/theme.ts'

// Uncomment this import & line 57 if we need error message
// import ErrorMessage from '../../Elements/ErrorMessage';

type Props = {
  LoginStore: typeof LoginStore
}

class Home extends Component<Props> {
  constructor() {
    super()
    this.removeAuthStateCookie()
  }
  removeAuthStateCookie() {
    document.cookie =
      'auth_state=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/'
  }
  render() {
    const hostName = window.location.origin
    const consumerDashboardUrl =
      hostName +
      '/auth/realms/assari/protocol/openid-connect/auth?client_id=assari&redirect_uri=' +
      hostName +
      '/kirjaudu&response_type=code&scope=openid+email+profile'
      const companyDashboardUrl =
        hostName +
        '/auth/realms/assari/protocol/openid-connect/auth?client_id=assari&redirect_uri=' +
        hostName +
        '/yritys/kirjaudu&response_type=code&scope=openid+email+profile'
    // const remDashboardUrl =
    //   hostName +
    //   '/auth/realms/master/protocol/openid-connect/auth?client_id=assari&redirect_uri=' +
    //   hostName +
    //   '/isannointi/kirjaudu&response_type=code&scope=openid+email+profile'

    // for now the link to B2B side is omitted for RVK and KENVE
    const isRVKorKENVE =
      themeDetails.theme === 'rvk' || themeDetails.theme === 'kenve'
        ? true
        : false

    return (
      <>
        {/* Uncomment the error message component if we need it */}
        {/* <ErrorMessage /> */}
        <div className="wrapper home">
          {/* Comment this image element out if you don't want to show a background image */}
          <img src={Background} className="home-bg" alt="Background" />
          <div className="container">
            <Introduction {...textStrings.home} />
            <div className="categories">
              <LinkWithIconAndArrow
                type="Kuluttajat"
                url={consumerDashboardUrl}
              />
              {!isRVKorKENVE && (
                <LinkWithIconAndArrow
                  type="Yritysasiakkaat"
                  url={companyDashboardUrl}
                />
              )}
              {/* <div className="isannointi">
                <a href={remDashboardUrl}>
                  <Isannointi className="logo" alt="Isännöinti" />
                  <button>
                    Isännöitsijät
                    <i className="fa fa-chevron-right" aria-hidden="true" />
                  </button>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default inject('LoginStore')(observer(Home))
