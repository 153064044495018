import React from 'react'

type IntroductionTextStrings = {
  introduction: string
  subHeader?: string
  link?: string
}

const Introduction = (textStrings: IntroductionTextStrings): JSX.Element => {
  const link = <a href={`http://www.${textStrings.link}`}>{textStrings.link}</a>

  return (
    <div className="introduction">
      <h1>{textStrings.introduction}</h1>
      {textStrings.subHeader &&
        (textStrings.link ? (
          <h2>
            {textStrings.subHeader + ' '}
            {link}
          </h2>
        ) : (
          <h2>{textStrings.subHeader}</h2>
        ))}
    </div>
  )
}

export default Introduction
