// @flow

const getHeaders = (requireAuth?: boolean, token?: string): Headers => {
  if (requireAuth && token) {
    return new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'cache-control': 'no-store',
    })
  } 
  return new Headers({
    'Content-Type': 'application/json',
    'cache-control': 'no-store',
  })
}

export default getHeaders
