import moment from 'moment'
import React, { useEffect } from 'react'

import {
  DateRangePicker,
  FocusedInputShape,
  isInclusivelyBeforeDay,
} from 'react-dates'
import { START_DATE } from 'react-dates/constants'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { ReactComponent as ChevronUp } from '../../assets/images/chevron-up.svg'
import { UsageData } from '../../store'

const Calendar = ({
  updateUsageData,
  usageData,
}: {
  updateUsageData?: void
  usageData?: UsageData
}): JSX.Element => {
  const [tempStartDate, setTempStartDate] = React.useState<moment>(
    moment(usageData?.start)
  )
  const [tempEndDate, setTempEndDate] = React.useState<moment>(
    moment(usageData?.end)
  )
  const [startDate, setStartDate] = React.useState<moment>(
    moment(usageData?.start)
  )
  const [endDate, setEndDate] = React.useState<moment>(moment(usageData?.end))
  const [
    focusedInput,
    setFocusedInput,
  ] = React.useState<FocusedInputShape | null>(null)

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }): void => {
    setTempStartDate(startDate)
    setTempEndDate(endDate)
  }

  const cancelDatesChange = (): void => {
    setFocusedInput(null)
    setTempStartDate(startDate)
    setTempEndDate(endDate)
  }

  const confirmDatesChange = (): void => {
    setFocusedInput(null)
    setStartDate(tempStartDate)
    setEndDate(tempEndDate)
    const start = moment(tempStartDate).format('YYYY-MM-DD')
    const end = moment(tempEndDate).format('YYYY-MM-DD')
    let level = 'day'

    if (start === end) level = 'hour'

    updateUsageData && updateUsageData({ ...usageData, level, start, end })
  }

  useEffect(() => {
    setTempStartDate(moment(usageData?.start))
    setTempEndDate(moment(usageData?.end))
  }, [usageData])

  const presets = [
    {
      text: 'Vuosi',
      start: moment()
        .subtract(1, 'year')
        .startOf('year'),
      end: moment()
        .subtract(1, 'year')
        .endOf('year'),
      resolution: 'month',
    },
    {
      text: 'Kvartaali',
      start: moment()
        .subtract(3, 'month')
        .startOf('month'),
      end: moment()
        .subtract(1, 'month')
        .endOf('month'),
      resolution: 'month',
    },
    {
      text: 'Kuukausi',
      start: moment()
        .subtract(1, 'month')
        .startOf('month'),
      end: moment()
        .subtract(1, 'month')
        .endOf('month'),
      resolution: 'day',
    },
  ]

  const renderPresets = (): JSX.Element => (
    <>
      {presets && startDate && endDate && (
        <div className="CalendarInfo__presets">
          <span className="title">Edellinen</span>
          {presets.map(({ text, start, end }) => {
            return (
              <button
                key={text}
                className="btnPreset"
                onClick={(): void => {
                  handleDatesChange({ startDate: start, endDate: end })
                }}
              >
                {text}
              </button>
            )
          })}
        </div>
      )}
      <div className="CalendarInfo__footer">
        <button className="btn" onClick={(): void => cancelDatesChange()}>
          Peruuta
        </button>
        <button
          className="btn btnConfirm"
          onClick={(): void => confirmDatesChange()}
        >
          Vahvista
        </button>
      </div>
    </>
  )

  const PrevMonth = (): JSX.Element => (
    <div className="MonthNav__prev">
      <ChevronUp />
    </div>
  )

  const NextMonth = (): JSX.Element => (
    <div className="MonthNav__next">
      <ChevronUp />
    </div>
  )

  return (
    <DateRangePicker
      showDefaultInputIcon
      minimumNights={0}
      startDate={tempStartDate}
      startDateId="pset_companyCard_start"
      endDate={tempEndDate}
      endDateId="pset_companyCard_end"
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput): void =>
        setFocusedInput(focusedInput ?? START_DATE)
      }
      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
      displayFormat="D.M.YYYY"
      numberOfMonths={2}
      renderCalendarInfo={(): JSX.Element => renderPresets()}
      navPrev={<PrevMonth />}
      navNext={<NextMonth />}
      daySize={window.outerWidth < 576 ? 39 : 44}
    />
  )
}

export default Calendar
