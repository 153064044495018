import React from 'react'

import { ReactComponent as Arrow } from '../../assets/images/arrow-right.svg'
import { ReactComponent as ConsumerIcon } from '../../assets/images/house.svg'
import { ReactComponent as B2bIcon } from '../../assets/images/hotel.svg'

type Props = {
  type: string
  url: string
}

const LinkWithIconAndArrow = (props: Props): JSX.Element => {
  return (
    <a href={props.url} className="link-with-icon">
      <div className="icon-wrapper">
        {props.type === 'Kuluttajat' ? <ConsumerIcon /> : <B2bIcon />}
      </div>
      <span>
        {props.type}
        <Arrow />
      </span>
    </a>
  )
}

export default LinkWithIconAndArrow
