// @flow
import React, {useMemo, useEffect, ChangeEvent} from 'react'
import moment from 'moment-timezone'
import SelectFilter from './SelectFilter'

type Props = {
    selectedYear: number;
    earliestYear: number;
    selectedProductType: string;
    onChange: ChangeEvent<HTMLSelectElement>
}

const YearFilter: React.FunctionComponent<Props> = (props: Props) => {
    const { onChange, earliestYear, selectedYear, selectedProductType } = props
    //useMemo for not calculating this again, if earliest year has not changed
    useEffect(() => {
        onChange(moment().year())
    },[selectedProductType])
    const yearRange: number[] = useMemo(
        () => {
            const currentYear = moment().year()
            const years: number[] = [];
            for(let year = earliestYear; year <= currentYear; year++){
                years[year] = year;
            }
            return years
        },
        [earliestYear]
    )
    return (
        <SelectFilter options={yearRange} selectedOption={selectedYear} onChange={(): void => {onChange}}/>
    )
}

export default YearFilter;
