// @flow
import React from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  to: string,
}

const BackButton: React.FunctionComponent<Props> = (props: Props) => {
  const { to } = props

  return (
    <NavLink to={to}>
      <button className="basic left icon">
        <span className="btn-wrapper">
          <i
            className="fa fa-chevron-left"
            id="back-btn"
            aria-hidden="true"
          />
        </span>
      </button>
    </NavLink>
  )
}

export default BackButton
