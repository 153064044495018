// @flow
import React from 'react'
import { inject, observer } from 'mobx-react'
import ConsumerProductTypesTabView, {
  TabViewWrapper,
} from '../ProductTypes/ConsumerProductTypesTabView'
import ConsumerContractsList from './ConsumerContractsList'
import ConsumerCustomershipForm from '../Customership/ConsumerCustomershipForm'
import LoginStore from '../../../../store/LoginStore'
import BackButton from '../../../Elements/BackButton'
type Props = {
  LoginStore: typeof LoginStore,
}
// type State = {}

class ConsumerContracts extends React.Component<Props/*, State*/> {
  componentDidMount = async () => {
    window.scrollTo(0, 0)
    const { LoginStore } = this.props
    if (!LoginStore.logged) {
      await LoginStore.login('consumer', true)
    }
  }

  render() {
    return (
      <div className="wrapper contracts">
        <div className="btn-row">
          <BackButton to="/dashboard" />
          <h1>Käyttöpaikan tiedot</h1>
        </div>
        <ConsumerProductTypesTabView>
          {({
            selectedProductType,
            selectedProductName,
            onlyWaterContracts,
          }) => {
            //TODO: Remove, when water contracts are available
            if (onlyWaterContracts) {
              return (
                <p>Veden sopimustiedot tulossa seuraavissa julkaisuissa.</p>
              )
            } else {
              return (
                <React.Fragment>
                  <TabViewWrapper>
                    <ConsumerCustomershipForm
                      productType={selectedProductType}
                    />
                  </TabViewWrapper>
                  <h5 style={{ fontWeight: '600' }}>Käyttöpaikan tiedot</h5>
                  <TabViewWrapper>
                    <ConsumerContractsList
                      selectedProductName={selectedProductName}
                      selectedProductType={selectedProductType}
                    />
                  </TabViewWrapper>
                </React.Fragment>
              )
            }
          }}
        </ConsumerProductTypesTabView>
      </div>
    )
  }
}

export default inject('LoginStore')(observer(ConsumerContracts))
