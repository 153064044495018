// @flow
import React, {ChangeEvent} from 'react'
import styled from 'styled-components'

type Props = {
    onChange: ChangeEvent<HTMLSelectElement>;
    options: Array<number>;
    selectedOption: number;
}

export default function SelectFilter(props: Props): JSX.Element {
    const { onChange, options, selectedOption } = props
    return (
        <Wrapper>
            <Select onChange={(e) => onChange(e.target.value)} value={selectedOption}>
                {options.map(option => {return <option key={option} value={option}>{options[option]}</option>})}
            </Select>
            <ArrowBox>
                <Arrow/>
            </ArrowBox>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
`

const Select = styled.select`
    display: flex;
    margin: 0;
    padding: 0;
    border-radius: 0;
    min-width: 200px;
`

const ArrowBox = styled.div`
    background-color: #a5a5a5;
    width: 30px;
    height: 30px;
    pointer-events: none;
    margin-left: -30px;
`

const Arrow = styled.div`
    background: transparent;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    margin-top: 10px;
    margin-left: 7px;
`
