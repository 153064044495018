// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Modal from 'react-modal'
import { apiUrl } from '../../../config/constants'
import RegisterSuperuserForm from '../Shared/RegisterSuperuserForm'
import LoginStore from '../../../store/LoginStore'
import getHeaders from '../../../utils/getHeaders'

type Props = {
  modalIsOpen: boolean,
  updateModalState: () => void,
  updateClients: () => void,
  addManager: (value: string) => void,
  LoginStore: typeof LoginStore,
}

type State = {
  acceptConditionsCheckbox: boolean,
  errorMessage: string,
  loading: boolean,
  modalIsOpen: boolean,
  exists: boolean,
}

class ManagerModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { modalIsOpen } = this.props
    this.state = {
      acceptConditionsCheckbox: false,
      errorMessage: '',
      loading: false,
      modalIsOpen: modalIsOpen,
      exists: false,
    }
  }

  // TODO Update all of these component mounting functions.
  UNSAFE_componentWillMount = async () => {
    Modal.setAppElement('body')
  }

  UNSAFE_componentWillReceiveProps = async (nextProps: Props) => {
    await this.setState({ modalIsOpen: nextProps.modalIsOpen })
  }

  closeModal = async () => {
    const { updateModalState } = this.props
    this.setState({
      errorMessage: '',
      acceptConditionsCheckbox: false,
      exists: false,
    })
    await updateModalState()
  }

  handleSubmit = async (form: FormState, checked: boolean) => {
    if (!checked) {
      this.setState({
        errorMessage: 'Hyväksy ehdot',
        loading: false,
      })
      return
    }
    const { addManager } = this.props

    this.setState({ loading: true })

    if (!form || !form.valid) {
      // console.log('No form data or invalid form')
      this.setState({ loading: false })
      return
    }

    const formData = form.fields
      .mapEntries(([field, data]) => [field, data.value])
      .toJS()

    const body = {
      email: formData.email,
      phone: formData.phone,
      firstName: formData.firstName,
      lastName: formData.lastName,
    }

    const headers = getHeaders()

    const req = new Request(`${apiUrl}/user/remUser`, {
      body: JSON.stringify(body),
      headers: headers,
      method: 'POST',
      mode: 'cors',
    })

    try {
      const response = await fetch(req)
      if (response.status === 400) {
        // console.log('400: bad request')
        this.setState({
          errorMessage: 'Virhe rekisteröinnissä',
          loading: false,
        })
      } else if (response.status === 409) {
        // console.log('409: conflict')
        this.setState({
          errorMessage: 'Sähköpostiosoite on jo käytössä',
          exists: true,
          loading: false,
        })
      } else if (response.ok) {
        this.setState({
          loading: false,
          modalIsOpen: false,
        })
        await addManager(formData.firstName + ' ' + formData.lastName)
      } else {
        this.setState({
          loading: false,
          errorMessage: 'Virhe rekisteröityessä',
        })
      }
    } catch (error) {
      this.setState({
        errorMessage: 'Yhteysvirhe rekisteröinnissä',
        loading: false,
      })
    }
  }

  render() {
    const { loading, modalIsOpen, errorMessage, exists } = this.state

    if (exists)
      return (
        <div className="wrapper">
          <h1>Sähköpostiosoite on jo rekisteröity</h1>
        </div>
      )

    return (
      <Modal
        isOpen={modalIsOpen}
        className={'manager-modal'}
        overlayClassName={'manager-modal-overlay'}
        shouldCloseOnOverlayClick={true}
      >
        <input
          type="submit"
          value="Sulje"
          id="close-button"
          onClick={this.closeModal}
          disabled={loading}
        />
        <h1>Täytä henkilötiedot</h1>

        {errorMessage && <p className="errortext">{errorMessage}</p>}

        <RegisterSuperuserForm
          onSubmit={this.handleSubmit}
          submitText={loading ? 'Rekisteröidään' : 'Rekisteröidy'}
          loading={loading}
        />
      </Modal>
    )
  }
}

export default inject('LoginStore')(observer(ManagerModal))
