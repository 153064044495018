import React from 'react';
import PostponeInvoiceDate from './PostponeInvoiceDate';
// Types.
import { getRopoPdf } from '../../../../actions/getRopoDetails';
import { ropoInvoice } from '../../../../types/ropo';

interface RopoInvoiceDetailsProps {
  setInvoices: React.Dispatch<React.SetStateAction<ropoInvoice[]>>;
  invoice: ropoInvoice;
  onlineMaxSaldoLimit: number;
}


const RopoInvoiceDetails: React.FunctionComponent<RopoInvoiceDetailsProps> = ({
  setInvoices,
  invoice,
  onlineMaxSaldoLimit
}) => {
  const [shouldFetchPDF, setShouldFetchPDF] = React.useState(false);

  const invoiceStatusStrings: { [key: number]: string } = {
    19001: 'Keskeneräinen',
    19002: 'Valmis tarkistettavaksi',
    19003: 'Tarkistettu',
    19004: 'Hyväksytty jakeluun',
    19005: 'Avoin',
    19006: 'Maksettu',
    19008: 'Kehotus lähetetty',
    19009: 'Maksukehotus lähetetty',
    19010: 'Ylimääräinen maksukehotus lähetetty',
    19011: 'Perintävaroitus lähetetty',
    19012: 'Pikaperintä',
    19013: 'Perintätoimiston perittävänä',
    19014: 'Katkaisuvaroitus lähetetty',
    19015: 'Katkaisussa',
    19016: 'Luottotappioehdokas',
    19017: 'Luottotappio',
    19018: 'Katkaistu',
    19019: 'Huomautettu',
    19020: 'Palautetaan',
    19021: 'Keskeytetty',
    19022: 'Avoimen laskun uudelleenjakelu',
    19023: 'Mitätöity'
  }

  const invoiceTypeStrings: { [key: number]: string } = {
    46001: 'Arvio',
    46002: 'Kulutus',
    46003: 'Tasaus',
    46004: 'Kertaluontoinen maksu',
    46005: 'Liittymä',
    46006: 'Aika'
  }

  React.useEffect(() => {
    async function fetchRopoPdf(): Promise<void> {
      const ropoBlob = await getRopoPdf(invoice.invoice_number);
      const linkSource = `data:application/pdf;base64,${ropoBlob}`;
      const downloadLink = document.createElement("a");
      const fileName = `lasku_${invoice.invoice_number}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      setShouldFetchPDF(false);
    }

    if (shouldFetchPDF) {
      fetchRopoPdf();
    }
  }, [shouldFetchPDF, setShouldFetchPDF]);

  const formatCurrency = (suppliedAmount: string): string => {
    let formattedAmount: string;

    const amount = suppliedAmount.split('.');
    if (amount.length === 2) {
      formattedAmount = `${amount[0]},${amount[1].length === 1 ? `${amount[1]}0` : amount[1]}`
    }
    else {
      formattedAmount = `${suppliedAmount},00`;
    }

    return formattedAmount;
  }

  const dueDate = new Date(invoice.duedate);
  const formattedTotal = formatCurrency(`${invoice.amount_total}`);
  const formattedOpen = formatCurrency(`${invoice.amount_open}`);
  const shouldShowPostponeInvoiceDate = invoice.invoice_status === 19005 &&
                                        invoice.is_payment_agreement_allowed === true &&
                                        invoice.duedate_changed === false &&
                                        invoice.payment_agreement_max_duedate != null &&
                                        invoice.amount_open <= onlineMaxSaldoLimit;
  const hideInvoiceDetails = invoice.third_party_invoicing_invoice_type === 269002;


  return (
    <tr>
      <td>{dueDate.toLocaleDateString('fi-fi')}</td>
      <td>{invoice.invoice_number}</td>
      <td>{invoiceTypeStrings[invoice.invoice_type]}</td>
      <td>{formattedTotal}</td>
      <td>{formattedOpen}</td>
      {hideInvoiceDetails ? (
        <>
          <td></td>
          <td></td>
        </>
      ) : (
        <>
          <td>{invoiceStatusStrings[invoice.invoice_status]}</td>
          <td>
            <a href="#root" onClick={(e): void => { e.preventDefault(); setShouldFetchPDF(true); }}>
              Lataa pdf
            </a>
          </td>
        </>
      )}
      {shouldShowPostponeInvoiceDate && (
          <PostponeInvoiceDate
            invoiceNumber={invoice.id}
            paymentAgreementMaxDueDate={invoice.payment_agreement_max_duedate}
            originalDueDate={invoice.duedate}
            setInvoices={setInvoices}
          />
      )}
    </tr>
  );
}

export default RopoInvoiceDetails;
