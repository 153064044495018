// @flow
import React, { Component } from 'react'
import { scaleBand, scaleLinear } from 'd3-scale'
import Axes from './Axes'
import Bars from './Bars'
import responsive from './ResponsiveWrapper'
import type { Data, Tick } from './index'
import type { DataPoint, UsageData } from '../../store'

type Props = {
  parentWidth: number,
  width: number,
  height: number,
  data: Data,
  id: string,
  sum: number,
  tick?: Tick,
  timeframe: string,
  unit: string,
  showComparison: boolean,
  usageData?: UsageData,
  contractFromId: { address: string }
  updateUsageData: (value: React.SetStateAction<any>) => void, // eslint-disable-line
  maximumResolution: string;
}

class BarChart extends Component<Props> {
  render() {
    const {
      data,
      tick,
      sum,
      id,
      timeframe,
      unit,
      parentWidth,
      width,
      height,
      showComparison,
      usageData,
      contractFromId,
      updateUsageData,
      maximumResolution
    } = this.props

    const margins = { top: 120, right: 50, bottom: 50, left: 50 }
    const svgDimensions = {
      width: width && width < parentWidth ? width : Math.max(parentWidth, 350),
      height: Number(height),
    }

    //take max value to be the max of production vs consumption, for graph scaling -ADP 
    let maxConsumptionValue = Math.max(
      ...data.map((d: DataPoint) =>
        Math.max(d.v, d.comparison ? d.comparison.v : 0)
      )
    )
    if (maxConsumptionValue < 0) {
      maxConsumptionValue = 15
    }

    const maxProductionValue = Math.max(
      ...data.map((d: DataPoint) =>
        Math.max(d.vp ? d.vp : 0, d.comparison && d.comparison.vp ? d.comparison.vp : 0)
      )
    )

    const maxValue: number = Math.max(maxConsumptionValue, maxProductionValue);
    const xScale = scaleBand()
      .padding(0.2)
      .domain(data.map(d => d.ts))
      .range([margins.left, svgDimensions.width - margins.right])

    const yScale = scaleLinear()
      .domain([0, maxValue])
      .range([svgDimensions.height - margins.bottom, margins.top])

    const yScale2 = scaleLinear()
      .domain([maxValue, 0])
      .range([svgDimensions.height - margins.top, margins.bottom]);

    return (
      <>
        <svg width={svgDimensions.width} height={svgDimensions.height}>
          <Axes
            scales={{ xScale, yScale }}
            margins={margins}
            svgDimensions={svgDimensions}
            unit={unit}
            location={id}
            timeframe={timeframe}
            sum={sum}
            data={data}
            tick={tick}
            isProductionData={false}  //ADP
            contractFromId={contractFromId}
          />
          <Bars
            scales={{ xScale, yScale }}
            margins={margins}
            data={data}
            usageData={usageData}
            updateUsageData={updateUsageData}
            maxValue={maxValue}
            svgDimensions={svgDimensions}
            unit={unit}
            tick={tick}
            showComparison={showComparison}
            isProductionData={false}
            maximumResolution={maximumResolution}
          />
        </svg>

        {usageData?.type === ('electricity') && (!maxProductionValue || maxProductionValue <= 0.01) &&
          <p>Sähköntuotannon tieto ei ole saatavilla tälle ajanjaksolle</p>}

        {/* Also return an svg for electricity production data if available -ADP */}
        {usageData?.type === ('electricity') && maxProductionValue > 0.01 &&

          <svg width={svgDimensions.width} height={160}> {/* reduce height -ADP */}
            <Axes
              scales={{ xScale, yScale, yScale2 }}
              margins={margins}
              svgDimensions={svgDimensions}
              unit={unit}
              location={id}
              timeframe={timeframe}
              sum={sum}
              data={data}
              tick={tick}
              isProductionData={true}
              contractFromId={this.props.contractFromId}
            />
            <Bars
              scales={{ xScale, yScale, yScale2 }}
              margins={margins}
              data={data}
              usageData={usageData}
              updateUsageData={updateUsageData}
              maxValue={maxValue}
              svgDimensions={svgDimensions}
              unit={unit}
              tick={tick}
              showComparison={showComparison}
              isProductionData={true}
              maximumResolution={maximumResolution}
            />
          </svg>
        }
      </>
    )
  }
}

export default responsive(BarChart)
