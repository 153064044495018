// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import LoginStore from '../../../store/LoginStore'

import { themeDetails } from '../../../theme/theme.ts'

type Props = {
  LoginStore: typeof LoginStore,
}

class CompanyRegisterDetails extends Component<Props> {
  componentDidMount = () => {
    const { LoginStore } = this.props
    if (LoginStore.logged) {
      LoginStore.logout()
    }
  }

  render() {
    const { customerServiceEmail } = themeDetails

    return (
      <div className="container wrapper">
        <h1>
          Ilmoittamaanne y-tunnusta ei löytynyt isännöitsijärekisteristämme.
        </h1>
        <p>
          Pyydämme ilmoittamaan isännöitsijätoimistonne tiedot osoitteeseen{' '}
          <a href={`mailto:${customerServiceEmail}`}>{customerServiceEmail}</a>.
        </p>
      </div>
    )
  }
}

export default inject('LoginStore')(observer(CompanyRegisterDetails))
