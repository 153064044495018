// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import { NavLink, Redirect } from 'react-router-dom'
import moment from 'moment-timezone'
import Usage from './Usage'
import type { Contract } from '../../../../store/index'
import { apiUrl } from '../../../../config/constants'

type Props = {
  contracts: Array<Contract>,
  name: string,
  duration: number,
  type: string,
  icon: string,
  pathname: string,
  isIncoming?: boolean,
  isIncomingMessage?: string,
}

type State = {
  expanded: boolean,
  loading: boolean,
  contractsWithUsageData: Array<Contract>,
  redirect: boolean,
  errorMessage: string,
  unit: string
}

class Product extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      expanded: false,
      redirect: false,
      loading: true,
      errorMessage: '',
      contractsWithUsageData: [],
      unit: ""
    }

    this.getContracts = this.getContracts.bind(this)
  }

  componentDidMount = async (): Promise<void> => {
    this.getContracts()
  }

  getContracts = async (): Promise<void> => {
    const { contracts } = this.props
    this.setState({
      loading: true,
      errorMessage: '',
    })
    try {
      const contractsWithUsageData = await this.getContractsData(contracts)
      const filteredContracts = contractsWithUsageData.filter(
        value => value !== undefined
      )
      if (contractsWithUsageData.length === filteredContracts.length) {
        // remove duplicate contracts based on meteringpointIdentifier
        const uniqueContracts = Array.from(new Set(filteredContracts.map(contract => contract.meteringpointIdentifier))).map(id => filteredContracts.find(contract => contract.meteringpointIdentifier === id));
        this.setState({
          loading: false,
          contractsWithUsageData: uniqueContracts,
          errorMessage: '',
        })
      } else {
        this.setState({
          loading: false,
          contractsWithUsageData: [],
          errorMessage: 'Lataus epäonnistui',
        })
      }
    } catch (err) {
      this.setState({
        errorMessage: 'Yhteysvirhe kulutustietoja ladattaessa',
        loading: false,
      })
    }
  }

  // TODO Determine type.
  getContractsData = async (contracts: any): Promise<void> => { // eslint-disable-line
    if (!contracts) {
      return
    }

    const result = Promise.all(
      contracts.map(async contract => {
        return this.getContract(contract)
      })
    )
    return result
  }

  getContract = async (contract): Promise<any> => { // eslint-disable-line
    // Load contract data
    const data = await this.loadData(this.props.type, contract.meteringpointIdentifier)
    let sum = 0
    // var cumulative = undefined
    let timestampPeriod: string[] = []

    if (data) {
      const rawsum = data.map(d => d.v).reduce((a, b) => a + b, 0)
      if (contract.utility === 'water') sum = +rawsum.toFixed(3)
      // kuutiot, kolmen desimaalin tarkkuudella
      else sum = Math.round(rawsum)

      if (data.length > 0) {
        const periodStart = moment(data[0].ts)
        const periodEnd = moment(data[data.length - 1].ts)
        if (data.length === 1) {
          periodEnd.endOf('month')
        }
        timestampPeriod = [
          periodStart.format('DD.MM.'),
          periodEnd.format('DD.MM.'),
        ]
      }

      data.forEach((o, i, a) => {
        a[i].ts = moment(a[i].ts).format('DD.MM.')
      })
    } else {
      return
    }
    contract.usage = {
      data: data,
      period: timestampPeriod,
      periodType: '',
      usageSum: sum,
    }

    return contract
  }

  loadData = async (type, id): Array<DataPoint> | Promise<any> => { // eslint-disable-line
    let start = ''
    let end = ''
    let period = ''

    // Set API call period by usage type
    if (type === 'electricity') {
      period = 'thisyear'
    } else if (type === 'districtheat') {
      period = 'thisyear'
    } else if (type === 'water') {
      period = 'month'
    }

    // Set API call date format by usage period
    if (period === 'day' || period === 'hour') {
      start = moment()
        .startOf('year')
        .format('YYYY-MM-DD')
      end = moment().format('YYYY-MM-DD')
    } else if (period === 'month') {
      start = moment()
        .startOf('year')
        .format('YYYY-MM')
      end = moment().format('YYYY-MM')
    }

    if (type === 'districtheat') {
      type = 'heat'
    }

    const req = new Request(
      //companyid parameter not necessary? -ADP
      `${apiUrl}/${type}/${period}?start=${start}&end=${end}&mp=${id}&companyid=`,
      {
        method: 'GET',
        mode: 'cors',
      }
    )

    let response
    let responseData
    try {
      response = await fetch(req)
      if (response.status === 401) {
        return
      } else if (response.ok) {
        responseData = await response.json()
        const { data, unit } = responseData
        this.setState({ unit: unit });
        return data
      } else {
        return
      }
    } catch (error) {
      throw error
    }
  }

  expand = (): void => {
    const { expanded, errorMessage, contractsWithUsageData } = this.state

    if (contractsWithUsageData.length > 0) {
      if (contractsWithUsageData.length > 1) {
        this.setState({ expanded: !expanded })
      } else if (!errorMessage) {
        this.setState({ redirect: true })
      }
    }
  }

  getUsagePlaces = (
    contracts: Array<Contract>,
    pathname: string,
  ): any => { // eslint-disable-line
    if (contracts !== null) {
      if (contracts.length > 1) {
        // TODO Determine type.
        const usagePlaces = contracts.map<any>((contract, i) => { // eslint-disable-line
          return (
            <NavLink
              key={i}
              className="list-item"
              to={`/` + pathname + `/${contract.meteringpointIdentifier}`}
            >
              <div key={i} className="info-row">
                <div className="info-street">
                  <p>
                    {contract.address}
                    <br />
                    {contract.zip}, {contract.city}
                  </p>
                  <p />
                </div>
                <div className="info-usage">
                  <p>
                    {contract.usage.usageSum}
                    <span>{this.state.unit}</span>
                  </p>
                </div>
                <div className="info-arrow">
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </div>
              </div>
            </NavLink>
          )
        })
        return usagePlaces
      }
    }
  }

  render(): JSX.Element {
    const {
      name,
      type,
      icon,
      contracts,
      duration,
      pathname,
      isIncoming,
      isIncomingMessage,
    } = this.props
    const {
      redirect,
      expanded,
      loading,
      contractsWithUsageData,
      errorMessage,
      unit
    } = this.state

    const usagePlaces = this.getUsagePlaces(
      contractsWithUsageData,
      pathname
    )

    const period =
      contractsWithUsageData &&
      contractsWithUsageData.length > 0 &&
      contractsWithUsageData[0].usage &&
      `${contracts[0].usage.period[0]} - ${contracts[0].usage.period[1]}`

    let height = ''
    if (contractsWithUsageData !== null) {
      height = `${58 * contractsWithUsageData.length}px`
    }

    if (redirect) {
      const url = `/${pathname}/${contractsWithUsageData[0].meteringpointIdentifier}`
      return <Redirect to={url} />
    }

    if (isIncoming) {
      return (
        <div className={`${type} incoming`}>
          <div className="top-wrapper noselect" onClick={this.expand}>
            <div className="left">
              <div className="icon">
                <img src={icon} alt={name} />
              </div>
            </div>
            <div className="right">
              <IncomingServiceMessage>
                {isIncomingMessage}
              </IncomingServiceMessage>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={`${type}${expanded ? ' expanded' : ''}`}>
        <div className="top-wrapper noselect" onClick={this.expand}>
          <div className="left">
            <div className="icon">
              <img src={icon} alt={name} />
            </div>
          </div>
          {loading && (
            <MessageWrapper>
              <LoadingElement>Ladataan lukemia</LoadingElement>
            </MessageWrapper>
          )}
          {errorMessage && (
            <MessageWrapper>
              <ErrorMessageElement>{errorMessage}</ErrorMessageElement>
              <ReloadButton onClick={this.getContracts}>
                Yritä uudestaan
              </ReloadButton>
            </MessageWrapper>
          )}
          {!loading && !errorMessage && contractsWithUsageData.length > 0 && (
            <Usage
              usageSum={contractsWithUsageData[0].usage.usageSum}
              unit={unit}
              duration={duration}
              name={name}
              type={type}
              period={period}
              address={contractsWithUsageData[0].address}
              zip={contractsWithUsageData[0].zip}
              city={contractsWithUsageData[0].city}
            />
          )}
        </div>

        <div
          className="more-info"
          style={{ height: `${expanded ? height : '0px'}` }}
        >
          {usagePlaces}
        </div>
      </div>
    )
  }
}

export default Product

const IncomingServiceMessage = styled.h1`
  color: #fff;
  font-size: 24px;
  text-align: center;
  padding: 5px;
  &&& {
    margin: 0;
  }
`

const LoadingElement = styled.h1`
  color: #fff;
  padding-top: 55px;
  text-align: center;
  padding: 0;
  &&& {
    margin: 0;
  }
`

const ErrorMessageElement = styled(LoadingElement)`
  &&& {
    margin-bottom: 20px;
  }
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: calc(100% - 30vw);
  width: calc(100% - 160px);
`

const ReloadButton = styled.button`
  color: #fff;
  width: auto;
  border: 1px solid #fff;
  border-radius: 3px;
  cursor: pointer;
  height: 40px;

  &:hover {
    color: #0074bc;
    background-color: #fff;
  }
`
