// @flow
import './config/constants'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'
import App from './components/App'
import LoginStore from './store/LoginStore'
// import WebFont from 'webfontloader'
import './styles/main.css'

// WebFont.load({
//   typekit: {
//     families: ['proxima-nova'],
//     id: 'nfo2jjj',
//   },
//   timeout: 2000,
// })

const Root = (
  <Provider LoginStore={LoginStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

const start = () => {
  const element = document.getElementById('root')
  if (!element) return null
  ReactDOM.render(Root, element)
}

start() 
