// @flow
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import LoginStore from '../../../store/LoginStore'
import Loading from '../Shared/Loading'

type Props = {
  LoginStore: typeof LoginStore,
}

type State = {
  errorMessage?: string | null,
  loading: boolean,
}

class ManagerLogin extends Component<Props, State> {
  state = {
    errorMessage: null,
    loading: true,
  }

  componentDidMount = async () => {
    const response = await LoginStore.login('rem')

    if (!response || response.status === 204) {
      this.setState({
        errorMessage: ' Käyttäjätietoja ei löydetty. Ongelmatilanteen jatkuessa ota yhteyttä asiakaspalveluun.',
        loading: false,
      })
    }
    if (response && response.status === 408) {
      this.setState({
        errorMessage: 'Taustajärjestelmissä huoltokatko, kulutustiedot eivät ole saatavilla. Yritä myöhemmin uudestaan',
        loading: false,
      })
    }
    if (response && response.status === 401) {
      this.setState({
        errorMessage: 'Väärä sähköposti tai salasana',
        loading: false,
      })
    } else if (response && response.status === 403) {
      this.setState({
        errorMessage:
          'Kirjautuminen epäonnistui. Virhe asiakkuustietoja hakiessa. Ota yhteyttä asiakaspalveluun.',
        loading: false,
      })
    } else if (response && response.ok) {
      this.setState({ loading: false })
    }
  }

  render() {

    const { LoginStore } = this.props
    const { loading, errorMessage } = this.state

    if (loading) {
      return <Loading message="Ladataan käyttötietoja..." />
    }

    if (LoginStore.type === 'remUser')
      return <Redirect to="/isannointi/dashboard" />
    else if (LoginStore.type === 'remCompany')
      return <Redirect to="/paakayttaja/dashboard" />

    if (errorMessage) {
      return (
        <div>{errorMessage}</div>
      )
    }

    return <Redirect to="/forbidden" />
  }
}

export default inject('LoginStore')(observer(ManagerLogin))
