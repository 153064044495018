// @flow

import React from 'react'
import styled from 'styled-components'
import { ICustomership } from '../../../Services/CustomershipService'

type Props = {
    data: ICustomership,
}

const CustomershipContactDetails: React.FunctionComponent<Props> = (props: Props) => {
    const { data } = props;
    const customerIdentifierList =  data.customerIdentifier.reduce(
      (accumulator, currentValue) => accumulator + ', ' + currentValue,
    );

    return (
        <div>
            <DataRowHeader>Asiakkuustiedot</DataRowHeader>
            <DataRow>
                <DataRowKey>Asiakasnumero</DataRowKey>
                <DataRowValue>{customerIdentifierList}</DataRowValue>
            </DataRow>
            <DataRow>
                <DataRowKey>Nimi</DataRowKey>
                <DataRowValue>{data.name}</DataRowValue>
            </DataRow>
            <DataRow>
                <DataRowKey>Sähköpostiosoite</DataRowKey>
                <DataRowValue>{data.email}</DataRowValue>
            </DataRow>
        </div>
    );
}

export const DataRowHeader = styled.h5`
    font-weight: 600;
`

export const DataRow = styled.div`
    display: flex;
    text-align: left;
    color: #a5a5a5;
    padding: 0 10%;
    align-items: flex-end;
    flex-wrap: wrap;

    @media(max-width: 480px) {
        flex-direction: column;
        align-items: stretch;
    }
`

export const DataRowKey = styled.p`
    width: 230px;
`
export const DataRowValue = styled.p`
    border-bottom: 1px solid #a5a5a5;
    flex-grow: 1;
`

export default CustomershipContactDetails
