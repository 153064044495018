// @flow

import React, { Component } from 'react'
import {
  Field,
  FieldErrors,
  Form,
  Input,
  FormState,
  validators as rules,
} from '../../Form'
import { apiUrl } from '../../../config/constants'
import { NavLink } from 'react-router-dom'
import getHeaders from '../../../utils/getHeaders'

// TODO Fix this type.
type Props = any // eslint-disable-line
type State = {
  form: any // eslint-disable-line
  loading: boolean
  logout: boolean
  success: boolean
  errorMessage: string
}

export default class ContactDetailsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      form: null,
      loading: false,
      logout: false,
      errorMessage: '',
      success: false,
    }
    this.setFormState = this.setFormState.bind(this)
    this.getFormState = this.getFormState.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  getFormState = (): any => this.state.form // eslint-disable-line

  setFormState = (diff: FormState): Promise<void> =>
    new Promise(resolve => {
      this.setState(
        prevState => {
          const form = prevState.form
          if (!form) {
            return { form: diff }
          } else {
            return {
              form: {
                ...form,
                ...diff,
                fields: form.fields.merge(diff.fields),
              },
            }
          }
        },
        () => resolve()
      )
    })

  onSubmit = async (): Promise<void> => {
    const { form } = this.state

    if (form.valid) {
      const formData = form.fields
        .mapEntries(([field, data]) => [field, data.value])
        .toJS()

      const body = {
        phonenumber: formData.phonenumber.replace(/[\s]/g, ''),
        email: formData.email,
      }

      if (body.phonenumber === '') delete body.phonenumber
      if (body.email === '') delete body.email

      const headers = getHeaders()

      const req = new Request(`${apiUrl}/customership`, {
        // TODO: check this endpoint, whether the user is consumer or B2B, might need a different endpoint
        method: 'POST',
        body: JSON.stringify(body),
        headers,
      })

      this.setState({
        loading: true,
      })

      try {
        const response = await fetch(req)

        if (response.ok === true) {
          this.setState({
            loading: false,
            success: true,
          })
        } else {
          this.setState({
            loading: false,
            errorMessage:
              'Virhe lomaketta lähettäessä, yritä myöhemmin uudelleen',
          })
        }
      } catch (err) {
        this.setState({
          loading: false,
          errorMessage:
            'Yhteysvirhe lomaketta lähettäessä, yritä myöhemmin uudelleen',
        })
      }
    }
  }

  /*
    TODO: add some logic to check whether user is a consumer or B2B user
    For consumers, there's a link to the users contract details
    For B2B users, maybe not or some othe information
    Returns true or false
  */
  isConsumer = (): boolean => true

  render(): JSX.Element {
    const valid = this.state.form && this.state.form.valid
    const { success, errorMessage } = this.state
    const infoText = this.isConsumer() ? (
      <p className="info">
        Mikäli yhteystietosi muuttuvat, voit ilmoittaa uudet yhteystietosi tällä
        lomakkeella. Muutamme uudet tiedot kaikkiin voimassa oleviin
        sopimuksiisi. Voit tarkistaa nykyiset yhteystietosi{' '}
        <NavLink to="/sopimukset">sopimustiedoista</NavLink>.
      </p>
    ) : (
      <p className="info">
        Mikäli yhteystietosi muuttuvat, voit ilmoittaa uudet yhteystietosi tällä
        lomakkeella. Muutamme uudet tiedot kaikkiin voimassa oleviin
        sopimuksiisi.
      </p>
    )
    return (
      <div className="wrapper container contact-details-form">
        <h1>Yhteystietojen muutosilmoitus</h1>
        {success ? (
          <p>Yhteystietojen päivityspyyntö on lähetetty onnistuneesti.</p>
        ) : (
          <React.Fragment>
            {infoText}
            <Form
              getState={this.getFormState}
              setState={this.setFormState}
              onSubmit={this.onSubmit}
              className="form"
            >
              <Field
                name="phonenumber"
                validators={validators.phonenumber}
                initialValue=""
              >
                <label
                  htmlFor="phonenumber"
                  className="input-wrapper"
                  invalidClass="invalid"
                >
                  <Input
                    type="text"
                    name="phonenumber"
                    className="form-input"
                    maxLength={20}
                    style={{ fontSize: '28px' }}
                  />
                  <span>Uusi puhelinnumero</span>
                  <FieldErrors className="errors" />
                </label>
              </Field>
              <Field name="email" validators={validators.email} initialValue="">
                <label
                  htmlFor="email"
                  className="input-wrapper"
                  invalidClass="invalid"
                >
                  <Input
                    type="text"
                    name="email"
                    className="form-input"
                    maxLength={320}
                    style={{ fontSize: '28px' }}
                  />
                  <span>Uusi sähköpostiosoite</span>
                  <FieldErrors className="errors" />
                </label>
              </Field>
              {errorMessage.length > 0 && (
                <p className="error-message">{errorMessage}</p>
              )}
              <input
                type="submit"
                name="submit"
                value="Lähetä"
                disabled={!valid}
              />
            </Form>
          </React.Fragment>
        )}
      </div>
    )
  }
}

const validators = {
  phonenumber: [
    rules.either('email', 'Sähköpostiosoite tai puhelinnumero on pakollinen'),
    rules.phone('Lisää puhelinnumero muodossa +358123456789'),
  ],
  email: [
    rules.either(
      'phonenumber',
      'Sähköpostiosoite tai puhelinnumero on pakollinen'
    ),
    rules.email('Lisää sähköpostiosoite muodossa exampleuser@example.com'),
  ],
}
