// @flow
import { ThemeProvider } from 'styled-components'
import React from 'react'
import { styledComponentsThemes as themes } from '../../theme/theme'

const Theme: React.FunctionComponent<any> = (props: any) => ( // eslint-disable-line
  <ThemeProvider theme={themes[props.themeName]}>
    <div>
      {props.children}
    </div>
  </ThemeProvider>
)
export default Theme
