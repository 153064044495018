// @flow
import React, { Component } from 'react'
// TODO Determine if this is correct or where we should really get the type from.
import type { Node } from 'react'
import Arrow from '../../assets/images/NEVE_alas.png'

type Props = {
    title: Node;
    // Its some function but not finding its definition. Probably old code.
    getDetails?: any; // eslint-disable-line
    id: string;
    loading: boolean;
    collapseByClickingContent: boolean;
    children: any; // eslint-disable-line
}
type State = {
    collapsed: boolean;
}
export default class Accordion extends Component<Props, State> {
    constructor(props: Props){
        super(props)
        this.state = {
            collapsed: false
        }
        this.toggle = this.toggle.bind(this)
    }
    toggle = (): void => {
        const { collapsed } = this.state
        const { getDetails } = this.props
        if(!collapsed && getDetails){
            getDetails()
        }
        this.setState({ collapsed: !collapsed})
    }
    
    render(): JSX.Element {
        const { title, children, collapseByClickingContent } = this.props
        const { collapsed } = this.state
        const classNames = collapsed ? 'arrow collapsed' : 'arrow'
        const wrapperProps: any = {} // eslint-disable-line
        const headerProps: any = {} // eslint-disable-line
        if(collapseByClickingContent) {
            wrapperProps.onClick = this.toggle
        } else {
            headerProps.onClick = this.toggle
        }
        return (
            <div className="accordion" {...wrapperProps}>
                <div className="accordion-header" {...headerProps}>
                    {title}
                    <img className={classNames} src={Arrow} alt="arrow"/>
                </div>
                <div className="accordion-content">
                    {collapsed && children}
                </div>
            </div>
        )
    }
}
