// @flow
const correspondingPlurals = {
    "Osoitteeseen": "Osoitteisiin",
    "osoitteeseen": "osoitteisiin"
}
const pluralize = (text: string, count: number ): string => {
    if(count > 1){
        Object.keys(correspondingPlurals).forEach( singular => {
            text = text.replace(singular, correspondingPlurals[singular])
        })
    }
    return text
}
export default pluralize
