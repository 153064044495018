// @flow
import React, { FormEvent, SyntheticEvent, ChangeEvent } from 'react'
import { Map, List } from 'immutable'

import { Field } from './'
import type {
  FieldState,
  // FieldValidationState,
  FormState,
  ValidatorResult,
  ValidationTrigger,
  Validator,
} from './'

type InputEvent = ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>

type Props = {
  children: React.Node,
  setState: (diff: FormState) => Promise<void>,
  getState: () => FormState,
  onSubmit: (event: SyntheticEvent<HTMLFormElement>) => mixed,
}

// TODO Double check these are correct.
type FieldProps = {
  errors: List<string>,
  onBlur: Promise<void>,
  onChange: Promise<void>,
  value: string,
}

class Form extends React.PureComponent<Props> {
  componentDidMount(): void {
    if (!this.props.getState()) {
      const state = this.buildState()
      this.props.setState(state)
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   // TODO: Changing fields after initial render is currently not supported
  //   // Should rebuild state and merge with previous state
  // }

  buildFieldState = (
    validators: List<Validator>,
    batch: number,
    initialValue = ''
  ): FieldState => ({
    value: initialValue,
    errors: List(),
    validation: {
      validators: List(validators),
      data: {
        blur: {
          batch,
          errors: List(),
        },
        change: {
          batch,
          errors: List(),
        },
      },
    },
  })

  buildState = (): FormState => {
    const fieldElements: Array<JSX.Element> = []
    React.Children.forEach(this.props.children, child => {
      if (child && child.type === Field) {
        fieldElements.push(child)
      }
    })

    const fields: Map<string, FieldState> = Map(
      fieldElements.reduce(
        (acc, field) => ({
          ...acc,
          [field.props.name]: this.buildFieldState(
            List(field.props.validators),
            0,
            field.props.initialValue || ''
          ),
        }),
        {}
      )
    )

    return {
      valid: true,
      fields,
    }
  }

  getData = (): FormState => {
    const state = this.props.getState()
    if (!state) {
      throw new Error(
        `Form state not initialized. Be sure not to call getData before form is ready`
      )
    }
    return state
  }

  handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    // Clear validation errors
    const batch = Date.now()
    const fields = Map(
      this.getData().fields.mapEntries(([field, data]) => [
        field,
        {
          ...this.buildFieldState(data.validation.validators, batch),
          value: data.value,
        },
      ])
    )

    await this.props.setState({
      valid: false,
      fields: Map(fields),
    })

    // Revalidate
    const errors: List<ValidatorResult> = List(
      await Promise.all(
        fields
          .entrySeq()
          .reduce(
            (acc, [field, data]) =>
              acc.push(
                this.validateField(
                  field,
                  data.value,
                  data.validation.validators,
                  'change',
                  batch
                ),
                this.validateField(
                  field,
                  data.value,
                  data.validation.validators,
                  'blur',
                  batch
                )
              ),
            List()
          )
      )
    ).flatten()

    await this.props.setState({
      ...this.getData(),
      valid: errors.isEmpty(),
    })

    this.props.onSubmit(event)
  }

  handleInputChange = async (field: string, event: InputEvent): Promise<void> => {
    const value = event.currentTarget.value
    const data = this.getFieldData(field)
    const batch = Date.now()

    // Set value, reset validation state
    await this.updateField(field, {
      ...this.buildFieldState(data.validation.validators, batch),
      value,
    })

    // Run relevant validators
    this.validateField(
      field,
      value,
      data.validation.validators,
      'change',
      batch
    )
  }

  handleBlur = async (field: string/*, event: InputEvent*/): Promise<void> => {
    const data = this.getFieldData(field)
    const batch = Date.now()

    // Set blur validation batch
    await this.updateField(field, {
      ...data,
      validation: {
        ...data.validation,
        data: {
          ...data.validation.data,
          blur: {
            batch,
            errors: List(),
          },
        },
      },
    })

    // Run relevant validators
    this.validateField(
      field,
      data.value,
      data.validation.validators,
      'blur',
      batch
    )
  }

  getFieldData = (field: string): FieldState => {
    const state = this.props.getState()
    if (state && state.fields) {
      const data = state.fields.get(field)
      if (data) {
        return data
      }
    }
    throw new Error(
      `Field '${field}' not found in form state. Is form state corrupt?`
    )
  }

  getFieldProps = (field: string): FieldProps => {
    const data = this.getFieldData(field)
    return {
      errors: data.errors,
      onBlur: this.handleBlur,
      onChange: this.handleInputChange,
      value: data.value,
    }
  }

  updateField = async (field: string, data: FieldState): Promise<void> => {
    const fields = this.getData().fields.merge(Map({ [field]: data }))
    const errors = fields
      .toList()
      .reduce((errors, field) => errors.push(...field.errors), List())
    const valid = errors.isEmpty()
    await this.props.setState({ valid, fields })
  }

  addFieldError = (
    field: string,
    trigger: ValidationTrigger,
    error: string,
    batch: number
  ): void => {
    const data = this.getFieldData(field)
    const currBatch = data.validation.data[trigger].batch
    if (batch === currBatch) {
      const validationData = {
        ...data.validation,
        data: {
          ...data.validation.data,
          [trigger]: {
            ...data.validation.data[trigger],
            errors: data.validation.data[trigger].errors.push(error),
          },
        },
      }

      this.updateField(field, {
        ...data,
        validation: validationData,
        errors: ((Object.values(validationData.data))).reduce((acc, group) => List([...acc, ...group.errors]), List()),
      })
    } else {
      // console.log(`Ignored validation error result ${field}:${trigger}:${batch} (curr: ${currBatch})`);
    }
  }

  validateField = async (
    field: string,
    value: string,
    validators: List<Validator>,
    trigger: ValidationTrigger,
    batch: number
  ): Promise<List<ValidatorResult>> => {
    const relevantValidators = validators.filter(
      validator => validator.trigger === trigger
    )
    const result = await Promise.all(
      relevantValidators.map(async validator => {
        const error = await validator.validate(value, this.getData())
        if (error) {
          this.addFieldError(field, trigger, error, batch)
        }
        return error
      })
    )
    return List(result).filter(error => error != null)
  }

  render(): JSX.Element {
    const { children, /*setState,*/ getState, ...other } = this.props
    if (!getState()) {
      return <div>Initializing form...</div>
    }

    return (
      <form {...other} onSubmit={this.handleSubmit}>
        {React.Children.map(children, child => {
          if (child && child.type === Field) {
            return React.cloneElement(child, {
              ...child.props,
              ...this.getFieldProps(child.props.name),
            })
          }
          return child
        })}
      </form>
    )
  }
}

export default Form
