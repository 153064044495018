// @flow
import React from 'react'
import Accordion from '../../../Elements/Accordion'
import { IContractListItem } from '../../../Services/ContractsListService'
import ConsumerContractDetails from './ConsumerContractDetails'
type Props = {
    contractListItem: IContractListItem
}

const ConsumerContractListItem: React.FunctionComponent<Props> = (props: Props) => {
    const { contractListItem } = props
    const accordionTitle = 
    <h3 className="accordion-title">{
        `${contractListItem.contractType}
        ${contractListItem.contractIdentifier},
        ${contractListItem.address}`
        }
    </h3>
    return (
    <Accordion key={contractListItem.address} collapseByClickingContent={true} title={accordionTitle} id={contractListItem.address} loading={false}>
        <ConsumerContractDetails contract={contractListItem}/>
    </Accordion>
    )
}

export default ConsumerContractListItem;
