// @flow

import React, { Component } from "react";
import { apiUrl } from '../../config/constants'

const identificationRegistrationLinkPath = "/auth/reg/vatu/link";
const identificationRetrieveCredentialsLinkPath = "/auth/cred/vatu/link";

type Props = {
    isRegistration?: boolean;
    label: string;
}
type State = {
    identificationLink: string;
    errorMessage: string;
}

class Vatu extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            identificationLink: "",
            errorMessage: ""
        };
    }

    componentDidMount = (): void => {
        this.loadLink();
    }

    getQueryParam = (location: string, param: string): string|null => {
        const regex = RegExp(`${param}=([^&]*)?`, 'g');
        const result = regex.exec(location);
        return result !== null ? result[1] : null;
    }

    loadLink = async (): Promise<void> => {
        const { isRegistration } = this.props;

        const identificationLinkPath = isRegistration
            ? identificationRegistrationLinkPath
            : identificationRetrieveCredentialsLinkPath

        const req = new Request(`${apiUrl}${identificationLinkPath}`, {
            method: "GET",
            mode: "cors"
        });

        let response;
        try {
            response = await fetch(req);
            if (response.ok) {
                response
                    .text()
                    .then((link: string) => {

                        this.setState({
                            identificationLink: link
                        });
                    })
            } else {
                this.setState({ errorMessage: (isRegistration ? "Rekisteröitymis" : "Salasanan vaihto ") + "linkin hakeminen epäonnistui" })
            }
        } catch (error) {
            this.setState({ errorMessage: 'Yhteysvirhe pankkitietoja ladatessa' })
        }
    }
    setAuthStateCookie(minutes: number): void {
        const expiryTime = new Date();
        let expiryTimeInMilliSeconds = expiryTime.getTime();
        const secondsInAMinute = 60;
        const milliSecondsInASecond = 1000;

        expiryTimeInMilliSeconds += minutes * secondsInAMinute * milliSecondsInASecond;
        expiryTime.setTime(expiryTimeInMilliSeconds);
        const stateCookie = "auth_state=" + this.getQueryParam(this.state.identificationLink, "state") + ";expires=" + expiryTime + "; path =/;samesite=lax;domain=exove.eu;";
        document.cookie = stateCookie;
    }

    render(): JSX.Element {
        const { identificationLink, errorMessage } = this.state;
        const { label/*, isRegistration*/ } = this.props;
        const expiryTimeInMinutes = 300000000000;

        return (
            <div className="vatu container">
                {errorMessage && <p className="errortext">{errorMessage}</p>}
                {!errorMessage &&
                    <a href={identificationLink} /*TODO Name doesn't exist on this type... name={isRegistration ? "register-link" : "retrieve-cred-link"}*/ onClick={() => this.setAuthStateCookie(expiryTimeInMinutes)}>{label}</a>
                }
            </div>
        );
    }
}
export default Vatu;
