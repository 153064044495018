// @flow
import React from 'react'
import CustomershipService from '../../../Services/CustomershipService'
import Loading from '../../Shared/Loading'
import CustomershipDetails from './CustomershipDetails'

type Props = {
    productType: string;
}

// TODO Determine type
type customership = {
    loading: boolean;
    customership: any; // eslint-disable-line
    errorMessage: string;
}

const LoadingComponent = <Loading message="Ladataan asiakkuustietoja"/>
// export default class ConsumerCustomershipForm extends Component<Props> {
const ConsumerCustomershipForm: React.FunctionComponent<Props> = (props: Props) => {
    const { productType } = props
    return (
        <React.Suspense fallback={LoadingComponent}>
            <CustomershipService productType={productType}>
                {({loading, customership,errorMessage}: customership) => {
                    if(loading && !customership){
                        return LoadingComponent
                    } else if(!loading && !errorMessage) {
                        return <CustomershipDetails customership={customership} productType={productType}/>
                    } else if(errorMessage) {
                        return <h1>{errorMessage}</h1>
                    }
                }}
            </CustomershipService>
        </React.Suspense>
    )
}

export default ConsumerCustomershipForm