// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import LoginStore from '../../../../store/LoginStore'
import HouseData from './HouseData'
import YrittajaGray from '../../../../assets/images/yrittaja-gray.svg'
import DownArrow from '../../../../assets/images/NEVE_alas.png'
import UpArrow from '../../../../assets/images/NEVE_ylös.png'
import type { ContractMapped, Address } from '../../../../store/index'

type Props = {
  LoginStore: typeof LoginStore,
  contractsMapped: ContractMapped,
}

type State = {
  info: boolean,
  address: Address,
}

class HouseSingleton extends Component<Props, State> {
  constructor() {
    super()
    this.state = {
      info: false,
      address: [],
    }
  }

  componentDidMount = async () => {
    const address = await this.getAddress()
    if (address) {
      this.setState({ address: address })
    }
  }

  getAddress = async () => {
    const { contractsMapped } = this.props
    let address = []
    contractsMapped.map(contracts => {
      return contracts.contracts.map(contract => {
        const city = contract.mp.address.city
        const street = contract.mp.address.street
        const zip = contract.mp.address.zip
        if (city && street && zip) {
          address = {
            city: city,
            street: street,
            zip: zip,
          }
          return address
        } else {
          return []
        }
      })
    })
    return address
  }

  showAdditionalInfo = async () => {
    const { info } = this.state
    this.setState({ info: !info })
  }

  render() {
    const { info, address } = this.state
    const { contractsMapped } = this.props
    if (!contractsMapped || contractsMapped.length === 0) {
      return null
    }

    return (
      <div>
        {contractsMapped.map(contracts => (
          <div key={contracts.id} className="house">
            <div className="house-container">
              <div>
                <img
                  src={YrittajaGray}
                  className="house-img"
                  alt={address.street}
                />
              </div>
              <div className="details">
                <p className="title name">{contracts.name}</p>
                <div className={contracts.id}>
                  <p>{address.street}</p>
                  <p>
                    {address.zip} {address.city}
                  </p>
                </div>
              </div>
              <div className="additional-info">
                {info ? (
                  <h3 id="house-show-less-trigger" className="show-less-trigger manager-link" onClick={() => this.showAdditionalInfo()}>
                    Näytä Vähemmän{' '}
                    <img src={UpArrow} className="arrow-img " alt="up-arrow" />
                  </h3>
                ) : (
                  <h3 id="house-show-more-trigger" className="show-more-trigger manager-link" onClick={() => this.showAdditionalInfo()}>
                    Näytä lisää{' '}
                    <img
                      src={DownArrow}
                      className="arrow-img "
                      alt="down-arrow"
                    />
                  </h3>
                )}
              </div>
            </div>
            {info && (
              <div className="house-data">
                {contracts.contracts.map((contract, index) => (
                  <HouseData contract={contract} key={index} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }
}

export default inject('LoginStore')(observer(HouseSingleton))
