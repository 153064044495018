// @flow
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import AppTheme from '../Providers/Theme'
import { ReactComponent as BarsIcon } from '../../assets/images/bars.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'

type Props = {
  title: string
  isMobile: boolean
  children?: any // eslint-disable-line
}

export default function BurgerMenu(props: Props): JSX.Element {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  useEffect(() => {
    setOpen(false)
  }, [location])
  const { children } = props

  return (
    <AppTheme themeName={location.pathname === '/' ? 'inverted' : 'default'}>
      <BurgerMenuWrapper>
        <BurgerMenuHeader open={open}>
          {open && <BurgerMenuContent>{children}</BurgerMenuContent>}
          <BurgerMenuButton
            onClick={(): void => setOpen(!open)}
            id="burgermenu-button"
          >
            {!open ? <BarsIcon /> : <CloseIcon />}
          </BurgerMenuButton>
        </BurgerMenuHeader>
      </BurgerMenuWrapper>
    </AppTheme>
  )
}

const BurgerMenuWrapper = styled.div`
  position: relative;
  display: flex;
  height: 60px;
  width: 60px;
`

const BurgerMenuButton = styled.div`
  align-self: flex-start;
  padding: 10px;
  cursor: pointer;
  color: $text-color;
`

const BurgerMenuContent = styled.nav`
  text-align: left;
  padding: 2.5rem 1.75rem;
  width: max-content;
`

const BurgerMenuHeader = styled.div`
  position: absolute;
  display: flex;
  padding: 10px;
  top: 0;
  right: 0;
  background: ${props => (props.open ? '#fff' : 'transparent')};
  border: ${props => (props.open ? '1px solid #f3f3f3' : '1px transparent')};
  box-shadow: 0 2px 4px 0
    ${props => (props.open ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
  transition: borderr 0.2s, box-shadow 0.2s;
  z-index: 1;
`
