// @flow
import React, {useEffect, useReducer} from 'react'
import { apiUrl } from '../../config/constants'
import mapProductTypeForURL from '../../utils/mapProductTypeForURL'
import Logout from '../Elements/Logout'

interface IInvoiceRow {
    TuotekomponenttiTunnus: string,
    Maara: number,
    MaaranYksikko: string,
    YksikkohintaALV0: number,
    HintaYksikko: string,
    VeloitusALV0: number,
    ALV: number,
}
interface IVATParticle {
    Veroton: string,
    Vero: string,
    Yht: string
}
interface IVAT {
    [string]: IVATParticle,
}

export interface IInvoiceListItem {
    AsiakasKey: string,
    KayttopaikkaKey: string,
    Mittalaitenro: string,
    Laskunumero: string,
    LaskutusjaksoAlkuPvm: Date,
    LaskutusjaksoLoppuPvm: Date,
    Rivit: Array<IInvoiceRow>,
    LaskunSumma: string,
    ALV: IVAT
}
export interface IInvoiceList {
    [number]: IInvoiceListItem;
}

// TODO Determine type
type Props = {
    selectedProductType: string;
    selectedMP: string;
    selectedYear: string;
    children: any; // eslint-disable-line
}

const initialState = {
    invoices: [],
    loading: true,
    errorMessage: '',
    logout: false,
}

const invoicesServiceReducer = (state, action) => {
    switch(action.type){
        case 'error':
            return {
                loading: false,
                errorMessage: action.errorMessage,
                logout: false
            }
        case 'logout':
            return {
                loading: false,
                errorMessage: action.errorMessage,
                logout: true
            }
        case 'success':
            return {
                invoices: action.data,
                errorMessage: '',
                loading: false
            }
        case 'loading':
            return {
                loading: true
            }
        default:
            return state
    }
}

// TODO Determine return type.
function InvoicesService(props: Props): any {// eslint-disable-line
    const [state, dispatch] = useReducer(invoicesServiceReducer, initialState)
    const { selectedProductType, selectedMP, selectedYear } = props
    useEffect(() => {
        getInvoices(selectedProductType, selectedMP, selectedYear)
    }, [selectedProductType, selectedMP, selectedYear])

    async function getInvoices(productType: string, mp: string, year: string) {
        const req = new Request(`${apiUrl}/invoices?productType=${mapProductTypeForURL(productType)}&mp=${mp}&year=${year}`)
        try {
            dispatch({type: 'loading'})
            const response = await fetch(req)

            if(response.status === 401){
                const errorMessage = 'Ei oikeuksia tiedon lataamiseen. Kirjaudutaan ulos ...'
                dispatch({type: 'logout', errorMessage})

            } else if ([404,500,502].includes(response.status)) {
                const errorMessage = 'Tapahtui virhe, yritä myöhemmin uudelleen'
                dispatch({type: 'error', errorMessage})
            } else if(response.ok){
                const data = await response.json();
                dispatch({type: 'success', data})
            } else {
                const errorMessage = 'Virhe laskuja ladattaessa'
                dispatch({type: 'error', errorMessage})
            }
        } catch (err) {
            const errorMessage = 'Yhteysvirhe laskuja ladattaessa'
            dispatch({type: 'error', errorMessage})
        }
    }

    const { children } = props
    const { invoices, errorMessage, loading, logout } = state
    if(logout) return <Logout/>
    return children({invoices, errorMessage, loadingInvoices: loading})
}
export default InvoicesService
