// @flow
import React from 'react'

type Props = {
  error: string,
}

const ErrorElement: React.FunctionComponent<Props> = (props: Props) => {
  const { error } = props;

  return(
    <li>{error}</li>
  );
}

export default ErrorElement
