// @flow
import React, { Component } from 'react'
import { apiUrl } from '../../../config/constants'

const identificationRetrieveCredentialsLinkPath = "/auth/cred/vatu/link";
const epochTime = Date.now();

type State = {
  identificationLink: string;
  errorMessage: string;
}

// type Props = {}
type Props = Record<string, never>;

class RetrieveCredentials extends Component<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
        identificationLink: "",
        errorMessage: ""
    };
  }

  componentDidMount = (): void => {
    this.loadLink();
  }

  loadLink = async (): Promise<void> => {
    const requestUrl = apiUrl + identificationRetrieveCredentialsLinkPath + "?state=" + epochTime;
    const req = new Request(requestUrl, {
        method: "GET",
        mode: "cors"
    });

    let response;
    try {
        response = await fetch(req);
        if (response.ok) {
            response
                .text()
                .then((link: string) => {
                  this.setState({
                    identificationLink: link
                  });
                })
        } else {
            this.setState({errorMessage: "Salasanan vaihto linkin hakeminen epäonnistui"})
        }
    } catch (error) {
        this.setState({ errorMessage: 'Yhteysvirhe pankkitietoja ladatessa' })
    }
}

setAuthStateCookie(minutes: number): void {
  const expiryTime = new Date();
  let expiryTimeInMilliSeconds = expiryTime.getTime();
  const secondsInAMinute = 60;
  const milliSecondsInASecond = 1000;

  expiryTimeInMilliSeconds += minutes * secondsInAMinute * milliSecondsInASecond;
  expiryTime.setTime(expiryTimeInMilliSeconds);
  const stateCookie = "auth_state=" + epochTime + ";expires=" + expiryTime + "; path =/;samesite=lax;domain=azure.eu;";
  document.cookie = stateCookie;
}
  render(): JSX.Element {
    const { identificationLink, errorMessage } = this.state;
    const expiryTimeInMinutes = 300000000000;
    this.setAuthStateCookie(expiryTimeInMinutes);

    if(identificationLink !== "") {
      window.location.href = identificationLink;
    }
    return (<div>{errorMessage && <p className="errortext">{errorMessage}</p>}</div>);
  }
}
export default RetrieveCredentials
