// @flow

import React from 'react'
// import CustomershipAddressDetails from './CustomershipAddressDetails'
import CustomershipContactDetails from './CustomershipContactDetails'
// import InvoiceLink from '../../../Elements/InvoiceLink'
// import Accordion from '../../../Elements/Accordion'
import { TabViewSection } from '../ProductTypes/ConsumerProductTypesTabView'
import type { ICustomership } from '../../../Services/CustomershipService'

// import { themeDetails } from '../../../../theme/theme.ts'

type Props = {
  customership: ICustomership,
  productType: string,
}

const CustomershipDetails: React.FunctionComponent<Props> = (props: Props) => {
  const { customership } = props
  // const billingAddresses = customership.addresses.filter(
  //   address => address.Osoitetyyppi === 'Billing address'
  // )
  // const officialAddress = customership.addresses.filter(
  //   address => address.Osoitetyyppi === 'Official address'
  // )[0]
  // const numberOfBillingAddresses = billingAddresses.length
  // const billingAddressesHeaderStyle = {
  //   fontWeight: '600',
  // }

  // const { services } = themeDetails

  return (
    <React.Fragment>
      <TabViewSection key="contact-details">
        <CustomershipContactDetails data={customership} />
      </TabViewSection>

      {/* {services.indexOf('billingInfo') > -1 && (
        <InvoiceLink productType={productType} />
      )}

      <TabViewSection key="official-address">
        <CustomershipAddressDetails
          data={officialAddress}
          title="Virallinen osoite"
        />
      </TabViewSection>
      {numberOfBillingAddresses === 1 && (
        <TabViewSection key="billing-address-0">
          <CustomershipAddressDetails
            data={billingAddresses[0]}
            title="Laskutusosoite"
          />
        </TabViewSection>
      )}
      {numberOfBillingAddresses > 1 && (
        <h5 style={billingAddressesHeaderStyle}>Laskutusosoitteet</h5>
      )}
      {numberOfBillingAddresses > 1 &&
        billingAddresses.map((address, index) => (
          <TabViewSection key={`billing-address-${index}`}>
            <Accordion
              key={index}
              title={
                <h2 className="accordion-title">
                  Laskutusosoite, {address.Osoite}
                </h2>
              }
              collapseByClickingContent={true}
            >
              <CustomershipAddressDetails data={address} />
            </Accordion>
          </TabViewSection>
        ))} */}
    </React.Fragment>
  )
}

export default CustomershipDetails;
