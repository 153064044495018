// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import LoginStore from '../../store/LoginStore'
type Props = {
    LoginStore: typeof LoginStore
}
class Logout extends Component<Props>{
    componentDidMount() { 
        const { LoginStore } = this.props
        LoginStore.logout()
        window.location.assign(window.location.origin)
    }
    render(){
        return (
            <h3>Kirjaudutaan ulos</h3>
        );
    }
}
export default inject('LoginStore')(observer(Logout))