import React from 'react'
import { formatConsumption } from '../../Elements/CompanyCard'

const TotalUsageCircle = ({ sum, heading, unit }: { sum: number; heading: string; unit: string; }): JSX.Element => {

  if (heading === 'Tuotanto') return (<></>)

  return (
    <div className="consumption">
      <h3>{heading}</h3>
      <p>Yhteensä</p>
      <div className="consumption-circle">
        <p>
          <span className="consumption-value">
            {formatConsumption(sum?.toString())}
          </span>
          {unit}
        </p>
      </div>
    </div>
  )
}

export default TotalUsageCircle
