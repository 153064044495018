// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { apiUrl } from '../../config/constants'
import getHeaders from '../../utils/getHeaders'

import ElectricityActiveIcon from '../../assets/images/icon-electricity_blue.svg'
import ElectricityInactiveIcon from '../../assets/images/icon-electricity_grey.svg'
import WaterActiveIcon from '../../assets/images/icon-water_blue.svg'
import WaterInactiveIcon from '../../assets/images/icon-water_grey.svg'
import HeatActiveIcon from '../../assets/images/icon-heat_blue.svg'
import HeatInactiveIcon from '../../assets/images/icon-heat_grey.svg'
import FiberActiveIcon from '../../assets/images/icon-fiber_blue.svg'
import FiberInactiveIcon from '../../assets/images/icon-fiber_grey.svg'
import LoginStore from '../../store/LoginStore'
import Logout from '../Elements/Logout'

interface IProductTypeIcons {
    active: string,
    inactive: string
}

export interface IProductTypeDetails {
    icon: IProductTypeIcons,
    title: string,
    count?: number
}

export interface IProductTypes {
    electricity?: IProductTypeDetails,
    water?: IProductTypeDetails,
    heat?: IProductTypeDetails,
    fiber?: IProductTypeDetails
}

// TODO Determine type.
type Props = {
    children: any; // eslint-disable-line
}

type State = {
    productTypes: Array<IProductTypes>;
    errorMessage: string;
    loading: boolean;
    logout: boolean;
}

const validProductTypes: IProductTypes = {
    electricity: {
        icon: {
            active: ElectricityActiveIcon,
            inactive: ElectricityInactiveIcon
        },
        title: "Sähkö"
    },
    water: {
        icon: {
            active: WaterActiveIcon,
            inactive: WaterInactiveIcon
        },
        title: "Vesi"
    },
    heat: {
        icon: {
            active: HeatActiveIcon,
            inactive: HeatInactiveIcon
        },
        title: "Lämpö"
    },
    fiber: {
        icon: {
            active: FiberActiveIcon,
            inactive: FiberInactiveIcon
        },
        title: "Valokuitu"
    }
}
const productTypesMap = {
    "Sähkö": "electricity",
    "Lämpö": "heat",
    "Valokuitu": "fiber",
    "Vesi": "water"
}
export const mapProductTypes = (productTypeNames: Array<string>): IProductTypes => {
    return productTypeNames.reduce((acc,productType: string) => {
        const mappedProductType = productTypesMap[productType];
        const productTypeWithDetails = validProductTypes[mappedProductType]
        if(productTypeWithDetails){
            acc[mappedProductType] = productTypeWithDetails
        }
        return acc
    },{})
}
class ProductTypesService extends Component<Props,State> {
    constructor(props: Props){
        super(props)
        this.state = {
            productTypes: [],
            errorMessage: '',
            loading: true,
            logout: false,
        }
    }

    componentDidMount = async () => {
        const headers = getHeaders(true)
        const req = new Request(`${apiUrl}/product_types`,{
            headers,
            method: "GET",
            mode: "cors"
        })
        try {
            const response = await fetch(req)
            if(response.status === 401){
                this.setState({
                    errorMessage: 'Ei oikeuksia tiedon lataamiseen. Kirjaudutaan ulos ...',
                    loading: false,
                    logout: true
                })
                LoginStore.logout()

            } else if ([404,500,502].includes(response.status)) {
                this.setState({
                    loading: false,
                    productTypes: mapProductTypes(["Sähkö","Lämpö"]),
                })
                return null
            } else if(response.ok){
                const data = await response.json();
                this.setState({
                    productTypes: mapProductTypes(data),
                    loading: false
                })
            } else {
                this.setState({
                    loading: false,
                    errorMessage: 'Virhe tuotetietoja haettaessa',
                })
            }
        } catch (err){
            this.setState({
                errorMessage: 'Yhteysvirhe tuotetietoja ladattaessa',
                loading: false
            })
        }
    }
    render() {
        const { children } = this.props
        const { productTypes, errorMessage, loading, logout } = this.state
        if(logout) return <Logout/>
        return children({productTypes,errorMessage, loading})
    }
}
export default inject('LoginStore')(observer(ProductTypesService))
