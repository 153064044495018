import moment from 'moment';
import React, { useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { ReactComponent as ChevronUp } from '../../assets/images/chevron-up.svg';

const InvoicesCalendar = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate
}: {
  setStartDate: (startDate: moment.Moment | null) => void;
  setEndDate: (endDate: moment.Moment | null) => void;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}): JSX.Element => {
  const [tempStartDate, setTempStartDate] = useState<moment.Moment | null>(startDate);
  const [tempEndDate, setTempEndDate] = useState<moment.Moment | null>(endDate);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const handleFocusChange = (focusedInput: FocusedInputShape | null) => {
    if (focusedInput !== null) {
      setFocusedInput(focusedInput);
    }
  };

  const cancelDatesChange = () => {
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setFocusedInput(null);
  };

  const confirmDatesChange = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setFocusedInput(null);
  };

  const presets = [
    {
      text: 'Vuosi',
      start: moment().subtract(1, 'year').startOf('year'),
      end: moment().subtract(1, 'year').endOf('year'),
    },
    {
      text: 'Kvartaali',
      start: moment().subtract(3, 'months').startOf('month'),
      end: moment().subtract(1, 'month').endOf('month'),
    },
    {
      text: 'Kuukausi',
      start: moment().subtract(1, 'month').startOf('month'),
      end: moment().subtract(1, 'month').endOf('month'),
    },
  ];

  const renderPresets = (): JSX.Element => (
    <>
      <div className="CalendarInfo__presets">
        <span className="title">Edellinen</span>
        {presets.map(({ text, start, end }) => (
          <button
            key={text}
            className="btnPreset"
            onClick={() => handleDatesChange({ startDate: start, endDate: end })}
          >
            {text}
          </button>
        ))}
      </div>
      <div className="CalendarInfo__footer">
        <button className="btn" onClick={cancelDatesChange}>
          Peruuta
        </button>
        <button className="btn btnConfirm" onClick={confirmDatesChange}>
          Vahvista
        </button>
      </div>
    </>
  );

  const PrevMonth = (): JSX.Element => (
    <div className="MonthNav__prev">
      <ChevronUp />
    </div>
  )

  const NextMonth = (): JSX.Element => (
    <div className="MonthNav__next">
      <ChevronUp />
    </div>
  )

  return (
    <DateRangePicker
      startDate={tempStartDate}
      endDate={tempEndDate}
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput}
      onFocusChange={handleFocusChange}
      startDateId="invoice_calendar_start_date"
      endDateId="invoice_calendar_end_date"
      isOutsideRange={() => false}
      numberOfMonths={2}
      displayFormat="DD.MM.YYYY"
      renderCalendarInfo={renderPresets}
      navPrev={<PrevMonth />}
      navNext={<NextMonth />}
    />
  );
};

export default InvoicesCalendar;
