// @flow
// The backend server is in the same domain - this works in other environment that DEV - in your local dev env, set the environment variable below:
// export REACT_APP_NEVEPORTAL_SERVER_API_URL=https://test.neve.fi/api and run "yarn build"
export const apiUrl = process.env.REACT_APP_NEVEPORTAL_SERVER_API_URL || '/api'
export const instrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY
export const timezone = process.env.TZ || 'Europe/Helsinki'

// TODO Determine what is up with this file. Doesn't really seem to make sense.
// Runs at some point during logout.
let logger = {
  trackEvent: (a: any, b: any) => {}, // eslint-disable-line
}
if (instrumentationKey) {
  import(/* webpackChunkName: "react-appinsights" */ 'react-appinsights').then(
    ReactAI => {
      ReactAI.init({ instrumentationKey: instrumentationKey })
      const _logger = ReactAI.ai()
      ReactAI.setAppContext({ 'ai.cloud.role': 'Frontend' })
      logger = _logger
    }
  )
}
export { logger }
export const PASSWORD_MAX_LENGTH = 100
export const EMAIL_MAX_LENGTH = 100
