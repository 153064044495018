// @flow
import React from 'react'
import styled from 'styled-components'

type Props = {
    title: string;
    children: any; // eslint-disable-line
}

export default function FilterField(props: Props): JSX.Element {
    const { children, title } = props
    return (
        <FilterFieldWrapper>
            <FilterFieldLabel>{title}</FilterFieldLabel>
            {children}
        </FilterFieldWrapper>
    )
}

const FilterFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 10px 10px 10px 0;
`

const FilterFieldLabel = styled.label`
    font-size: 18px;
    color: #a5a5a5;
`