// @flow
import * as React from 'react'
import { inject, observer } from 'mobx-react'
import DetailsPage from '../Shared/DetailsPage'
import { getContracts } from '../../../utils/getContracts'
import type { Customer, Contract } from '../../../store/index'
import LoginStore from '../../../store/LoginStore'


type Props = {
  LoginStore: typeof LoginStore,
  itemId: string,
}


type State = {
  customer: Customer,
  contract: Contract,
  type: string,
  id: string
}

class HouseDetailsPage extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      customer: null,
      type: '',
      id: '',
    }
  }

  componentDidMount = async () => {

    if (!LoginStore.logged) {
      await LoginStore.login('rem',true)
    }

    const params = window.location.pathname.split('/')

    this.setState({ type: params[2], id: params[3] })

    const contracts = getContracts()

    if (contracts && contracts.length > 0) {
      const contract = contracts.find(contract => {
        return (
          contract.mp.id === this.state.id
        )
      })

      if (contract && contract.customer) {
        const customer = contract.customer
        this.setState({ customer, contract })
      }
    }

  }

  render() {
    const { customer, contract, type, id } = this.state
    return (
      <React.Fragment>
        {type === "vesi" && (
          <DetailsPage
            type="water"
            id={id}
            customer={customer}
            contract={contract}
          />
          )}
        {type === "sahko" && (
            <DetailsPage
            type="electricity"
            id={id}
            customer={customer}
            contract={contract}
            />
          )}
        {type === "kaukolampo" && (
            <DetailsPage
              type="districtheat"
              id={id}
              customer={customer}
              contract={contract}
            />
          )}
        {type === "" && (
            <div className="wrapper container">
              Ei mittaustietoja saatavilla
            </div>
          )}
      </React.Fragment>
    )
  }
}

export default inject('LoginStore')(observer(HouseDetailsPage))
