// @flow
import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import getQueryParameter from '../../../utils/getQueryParameter'

type Props = {
  location: any; // eslint-disable-line
}
// export class ConsumerRegisterFailure extends Component<Props> {
const ConsumerRegisterFailure: React.FunctionComponent<Props> = (props: Props) => {
  const { location } = props
  const isRegisterError = location.pathname.indexOf('/rekisteroidy') !== -1
  const error = getQueryParameter(location.search, 'error')
  let errorText = ''
  switch (error) {
    case 'access_denied':
      errorText = 'Tunnistautuminen keskeytetty'
      break
    case 'state':
      errorText = 'Tunnistautuminen epäonnistui'
      break
    case 'fatal':
      errorText = 'Tunnistautuminen epäonnistui'
      break
    default:
      errorText = 'Tunnistautuminen epäonnistui'
      break
  }
  return (
    <div className="wrapper">
      <h1>{errorText}</h1>
      <NavLink to={isRegisterError ? '/rekisteroidy/aloita' : '/palauta'}>
        <button className="submit">
          {isRegisterError ? 'Rekisteröidy palveluun' : 'Vaihda salasana'}
        </button>
      </NavLink>
    </div>
  )
}

export default withRouter(ConsumerRegisterFailure)
