// @flow
import React, { Component } from 'react'
import { createBrowserHistory } from 'history'
import { Redirect, NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import RegisterUserForm from '../Shared/RegisterUserForm'
import { apiUrl } from '../../../config/constants'
import type { FormState } from '../../Form/index'
import getHeaders from '../../../utils/getHeaders'
import LoginStore from '../../../store/LoginStore'

type Props = {
    LoginStore: typeof LoginStore,
    location: {
        search: string,
    },
}

type State = {
    formState: number,
    exists: boolean,
    success: boolean,
    errorMessage?: string,
    loading: boolean,
    token: string
}

class ConsumerRegisterDetails extends Component<Props, State> {
    state = {
        formState: 0,
        exists: false,
        success: false,
        errorMessage: null,
        loading: false,
        token: ''
    }

    constructor() {
        super();
        this.removeAuthStateCookie();
    }
    removeAuthStateCookie() {
        document.cookie = "auth_state=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
    }

    componentDidMount = () => {
        const { LoginStore } = this.props
        if (LoginStore.logged) {
            LoginStore.logout()
        }
    }

    // onSubmit function passed to form
    handleSubmit = async (form: FormState) => {
        const { location } = this.props

        const parts = location.search.match('[?&]token=([^&]+)')
        const token = parts && parts.length >= 1 ? parts[1] : ''

        const history = createBrowserHistory()
        // Clears user info token from browser history
        history.replace({ pathname: '/' })

        this.setState({ loading: true })

        if (!form || !form.valid) {
            this.setState({ loading: false })
            return
        }

        const formData = form.fields
            .mapEntries(([field, data]) => [field, data.value])
            .toJS()

        const headers = getHeaders(true, token)

        const body = {
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.password,
            phone: formData.phone.trim(),
            termsAccepted: localStorage.getItem('terms') === 'approved',
            dataProtectionPlanAccepted:
                localStorage.getItem('privacy') === 'approved',
        }

        const req = new Request(`${apiUrl}/user/consumer`, {
            body: JSON.stringify(body),
            headers,
            method: 'POST',
            mode: 'cors',
        })

        try {
            const response = await fetch(req)

            if (response.status === 400) {
                this.setState({ exists: true, loading: false })
                return
            } else if (response.status === 403) {
                this.setState({
                    loading: false,
                    errorMessage:
                        'Käyttäjä rekisteröity. Virhe asiakkuustietoja hakiessa.',
                })
                return
            } else if (response.ok) {
                localStorage.removeItem('terms')
                localStorage.removeItem('privacy')
                this.setState({ success: true, loading: false })
            } else {
                this.setState({
                    loading: false,
                    errorMessage: 'Virhe rekisteröityessä',
                })
            }
        } catch (error) {
            this.setState({
                loading: false,
                errorMessage: 'Yhteysvirhe rekisteröityessä',
            })
        }
    }
    getToken() {
        const { location } = this.props
        const parts = location.search.match('[?&]token=([^&]+)')
        return parts && parts.length >= 1 ? parts[1] : ''
    }
    termsAccepted() {
        const terms = localStorage.getItem('terms')
        const privacy = localStorage.getItem('privacy')
        return terms === "approved" && privacy === "approved";
    }
    render() {
        const { loading, errorMessage, success, exists } = this.state



        // Email address or social security number already in use
        if (exists)
            return (
                <div className="wrapper">
                    <h1>
                        Olet jo rekisteröitynyt tällä sähköpostiosoitteella tai
                        henkilötunnuksella.
                    </h1>
                    <NavLink to={`/tunnus?token=${this.getToken()}`}>
                        <button className="submit">Palauta käyttäjätili</button>
                    </NavLink>
                </div>
            )

        // Succesful registration
        if (success) return <Redirect to="/rekisteroidy/success" push={true} />

        // Redirect to accept terms view
        if (!this.termsAccepted()) {
            return <Redirect to="/rekisteroidy" push={true} />
        }

        return (
            <div className="wrapper">
                <h1>Täytä henkilötietosi</h1>

                {errorMessage && <p className="errortext">{errorMessage}</p>}

                <RegisterUserForm
                    onSubmit={this.handleSubmit}
                    submitText={loading ? 'Rekisteröidään' : 'Rekisteröidy'}
                    loading={loading}
                />
            </div>
        )
    }
}

export default inject('LoginStore')(observer(ConsumerRegisterDetails))
