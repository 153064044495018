// @flow
import { action, extendObservable } from 'mobx'
// import { mock } from '../utils/mock' // Temporary mock data
import type { Customerships } from './index'
import { apiUrl } from '../config/constants'

type FibreAddress = {
  address: string;
  type: string;
}

// TODO Double check that the initialisations are correct.
class LoginStore {
  configuration: any; // eslint-disable-line
  customerData: Customerships[] = [];
  fibreAvailableList: FibreAddress[] = [];
  logged = false;
  // This is a function but can't type as function.
  restoreSession: any; // eslint-disable-line
  restoringSession = false;
  // This is a function but can't type as function.
  setRestoringSession: any; // eslint-disable-line
  type = '';
  firstName = '';
  lastName = '';
  username = '';

  constructor() {
    extendObservable(this, {
      configuration: <any>{}, // eslint-disable-line
      customerData: [],
      logged: <boolean>false,
      type: <string>'',
      firstName: <string>'',
      lastName: <string>'',
      username: <string>'',
      restoringSession: <boolean>true,
      fibreAvailableList: [],
    })
  }

  login = action(async (rt: string, refresh = false) => {

    if (rt === 'remUser' || rt === 'remCompany') {
      rt = 'rem'
    }

    const req = new Request(`${apiUrl}/user/details/${rt}?refresh=${refresh}`, {
      method: 'GET',
      mode: 'cors',
    })
  
    try { 
      const response = await fetch(req)
      //if response is succesful but no data has been found for user
      if (response.status === 204) {
        console.log(response.statusText)
        return response;
      }
      else if (response.ok) {
        const data = await response.json();

          if (data && data.userData.kind === 'consumer') {
            this.customerData = data.companies
          } else if (data.kind === 'remUser' || data.kind === 'remCompany') {
            this.customerData = data.clients
          } else if (data.userData.kind === 'companyManager') {
            this.customerData = data.companies
          } else if (data.userData.kind === 'companyUser') {
            this.customerData = data.companies
          }
          this.type = data.userData.kind
          // this.fibreAvailableList = data.fibreAvailableList
          this.firstName = data.userData.firstName
          this.username = data.userData.email
          if (data.name) {
            const [head, ...tail] = data.name.split(' ')
            this.firstName = head
            this.lastName = tail.join(' ')
          } else {
            this.lastName = data.userData.lastName
            this.username = data.userData.username
          }
          this.logged = true;
        
      }
      return response
     } catch (error) {
        console.log(error);
    }
  } 
  );

  setConfiguration = action(async (configuration: string) => {
    this.configuration = configuration
    return Promise.resolve()
  })



  logout = async () => {

    const req = new Request('/api/oauth/logout', {
      method: 'GET',
      mode: 'cors',
    })
    try {
      const res = await fetch(req)
      if (res.ok) {
        this.customerData = []
        this.type = ''
        this.firstName = ''
        this.lastName = ''
        this.username = ''
        this.logged = false
      }
    } catch (err) {
    }
  }

  getTemplate = (name: string, replacements: any = {}) => { // eslint-disable-line
    let html = this.configuration && this.configuration[name]
    if (!html) {
      return html
    }
    for (const r in replacements) {
      html = html.replace(r, replacements[r])
    }
    return html
  }

  getValue = (name: string) => {
    return this.configuration && this.configuration[name];
  }
}

export default new LoginStore()
