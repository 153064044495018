// @flow
import * as React from 'react'

/*type Props = {}*/
type Props = any // eslint-disable-line

type State = {
  containerWidth: number,
}

// This is really weirdly done need to fix it up.
const responsive = (WrappedComponent: React.ComponentType<any>) => // eslint-disable-line
  class ResponsiveWrapper extends React.Component<Props, State> {
    chartContainer: Element

    constructor() {
      super(WrappedComponent)
      this.state = {
        containerWidth: 0,
      }
    }

    componentDidMount = () => {
      this.fitParentContainer()
      window.addEventListener('resize', this.fitParentContainer)
    }

    componentWillUnmount = () => {
      window.removeEventListener('resize', this.fitParentContainer)
    }

    fitParentContainer = () => {
      const { containerWidth } = this.state
      const currentContainerWidth = this.chartContainer.getBoundingClientRect()
        .width

      const shouldResize = containerWidth !== currentContainerWidth

      if (shouldResize) {
        this.setState({
          containerWidth: currentContainerWidth,
        })
      }
    }

    renderChart = () => {
      const { containerWidth } = this.state
      return <WrappedComponent {...this.props} parentWidth={containerWidth} />
    }

    render() {
      const { containerWidth } = this.state
      const shouldRenderChart = containerWidth !== null

      return (
        <div
          ref={el => {
            if (el) this.chartContainer = el
          }}
          className="responsive-wrapper"
        >
          {shouldRenderChart && this.renderChart()}
        </div>
      )
    }
  }

export default responsive
