// @flow
import React, { Component, ChangeEvent } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment-timezone'
import SelectFilter from './SelectFilter'
import { getContracts } from '../../utils/getContracts'

type Props = {
    selectedProductType: string;
    selectedMP: string;
    onChange: ChangeEvent<HTMLSelectElement>;
    LoginStore: any; // eslint-disable-line
}
type State = {
    options: Array<any>  // eslint-disable-line
}

const correspondingProductTypes = {
    'electricity': 'electricity',
    'districtheat': 'heat',
    'water': 'water'
}

class MPFilter extends Component<Props,State> {
    constructor(props: Props){
        super(props)
        this.state = {
            options: []
        }
        this.getMPs = this.getMPs.bind(this)
    }
    componentDidMount = async () => {
        const { LoginStore } = this.props
        if (!LoginStore.logged) {
            await LoginStore.login('consumer',true)
        }
        this.getMPs()
    }

    componentDidUpdate(prevProps: Props, prevState: Props): void { // eslint-disable-line
        if(this.props.selectedProductType !== prevProps.selectedProductType){
            this.getMPs()
        }
    }
    

    getMPs = () => {
        const { onChange, selectedProductType, LoginStore } = this.props
        const contracts = getContracts(LoginStore.customerData)
        if(contracts) {

            const options = contracts.reduce((acc,contract) => {
                if(correspondingProductTypes[contract.utility] === selectedProductType){
                    acc[contract.mp.id] = `${contract.mp.address.street} (${contract.mp.id})`
                }
                return acc
            },{})
            onChange(Object.keys(options)[Object.keys(options).length - 1], moment().year())
            this.setState({
                options
            })
        }
    }
    
        
    render() {
        const { onChange, selectedMP } = this.props
        const { options } = this.state
        return (
            <SelectFilter
                options={options}
                selectedOption={selectedMP}
                onChange={onChange}
            />
        )
    }
}
export default inject('LoginStore')(observer(MPFilter))
