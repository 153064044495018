// @flow
import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import Accordion from '../../../Elements/Accordion'
import type {IInvoiceListItem}  from '../../../Services/InvoicesService'
// import NeveHead from '../../../../assets/images/nevehead.svg'
type Props = {
    invoice: IInvoiceListItem;
    productType: string;
}
const prepareSum = (sum: number, decimals: number) => {
    return sum.toFixed(decimals).toString().replace('.',',')
}


export default function ConsumerInvoiceListItem(props: Props): JSX.Element {
    const { invoice, productType } = props
    const {
        LaskutusjaksoAlkuPvm,
        LaskutusjaksoLoppuPvm,
        Laskunumero,
        AsiakasKey,
        KayttopaikkaKey,
        Mittalaitenro,
        Rivit,
        LaskunSumma,
        ALV
    } = invoice
    const invoicePeriodStart = moment(LaskutusjaksoAlkuPvm).format('D.M.YYYY')
    const invoicePeriodEnd = moment(LaskutusjaksoLoppuPvm).format('D.M.YYYY')
    const period = `${invoicePeriodStart} - ${invoicePeriodEnd}`
    const title = <h3 className="accordion-title">Lasku ajalta {period}</h3>
    const headerText = productType === 'electricity' ? 'LASKU SÄHKÖNSIIRROSTA' : 'LÄMPÖLASKU'
    const summaryHeaderText = productType === 'electricity' ? 'Sähkön siirto yhteensä' : 'Lämpölasku yhteensä'
    return (
        <Accordion title={title}>
            <React.Fragment>
                {/* <HeaderImage src={NeveHead}/> */}
                <ContentWrapper>
                    <InvoiceDataRow>
                        <InvoiceDataRowKey>Laskunnumero:</InvoiceDataRowKey>
                        <InvoiceDataRowValue>{Laskunumero}</InvoiceDataRowValue>
                    </InvoiceDataRow>
                    <InvoiceDataRow>
                        <InvoiceDataRowKey>Asiakasnumero:</InvoiceDataRowKey>
                        <InvoiceDataRowValue>{AsiakasKey}</InvoiceDataRowValue>
                    </InvoiceDataRow>
                    <InvoiceDataRow>
                        <InvoiceDataRowKey>Käyttöpaikka:</InvoiceDataRowKey>
                        <InvoiceDataRowValue>{KayttopaikkaKey}</InvoiceDataRowValue>
                    </InvoiceDataRow>
                    <InvoiceDataRow>
                        <InvoiceDataRowKey>Mittalaite:</InvoiceDataRowKey>
                        <InvoiceDataRowValue>{Mittalaitenro}</InvoiceDataRowValue>
                    </InvoiceDataRow>
                    <InvoiceHeader>{headerText} {period}</InvoiceHeader>
                    {Rivit.map((rivi, index) =>  (
                        <React.Fragment key={index}>                      
                            <InvoiceDataDetailsSectionHeader>{rivi.TuotekomponenttiTunnus}</InvoiceDataDetailsSectionHeader>
                            <InvoiceDataDetailsRow>
                                <InvoiceRowItem>{prepareSum(rivi.Maara,3)} {rivi.MaaranYksikko}</InvoiceRowItem>
                                <InvoiceRowItem>{prepareSum(rivi.YksikkohintaALV0,2)} {rivi.HintaYksikko}</InvoiceRowItem>
                                <InvoiceRowItem>{prepareSum(rivi.VeloitusALV0,2)} EUR</InvoiceRowItem>
                            </InvoiceDataDetailsRow>
                        </React.Fragment>
                    ))}
                    <InvoiceDataSummaryRowHeader>{summaryHeaderText}</InvoiceDataSummaryRowHeader>
                    <InvoiceDataSummaryRow>
                        <InvoiceRowItem>{prepareSum(parseFloat(LaskunSumma),2)} EUR</InvoiceRowItem>
                    </InvoiceDataSummaryRow>
                    <InvoiceVATHeaderRow>
                        <InvoiceRowItem>Alv -erittely</InvoiceRowItem>
                        <InvoiceRowItem>Veroton</InvoiceRowItem>
                        <InvoiceRowItem>Vero</InvoiceRowItem>
                        <InvoiceRowItem>Yhteensä</InvoiceRowItem>
                    </InvoiceVATHeaderRow>
                    {Object.keys(ALV).map((percent, index) => (
                        <React.Fragment key={index}>
                            <InvoiceVATDataRow>
                                <InvoiceRowItem>{percent}</InvoiceRowItem>
                                <InvoiceRowItem>{prepareSum(parseFloat(ALV[percent].Veroton),2)} EUR</InvoiceRowItem>
                                <InvoiceRowItem>{prepareSum(parseFloat(ALV[percent].Vero),2)} EUR</InvoiceRowItem>
                                <InvoiceRowItem>{prepareSum(parseFloat(ALV[percent].Yht),2)} EUR</InvoiceRowItem>
                            </InvoiceVATDataRow>
                        </React.Fragment>
                    ))}
                </ContentWrapper>
            </React.Fragment>
        </Accordion>
    )
}
export const InvoiceDataRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    color: #a5a5a5;
    margin: 10px 0;

    @media(max-width: 480px) {
        flex-direction: column;
        align-items: stretch;
    }
`

export const InvoiceDataRowKey = styled.div`
    text-align: left;
    min-width: 140px;
`

export const InvoiceDataRowValue = styled.div`
    text-align: left;
`

const ContentWrapper = styled.div`
    margin: 50px 3%;
    position: relative;
`

/* const HeaderImage = styled.img`
    position: absolute;
    width: 170px;
    top: -50px;
    right: -3px;
` */

const InvoiceDataDetailsRow = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: rgb(245, 245, 246);
    color: rgb(165, 165, 165);
    padding-right: 40px;
    padding-left: 4px;
    height: 36px;
    align-items: flex-end;
    

    & > div {
        text-align: left;
        width: 30%;
    }

    & > div:first-child {
        width: 40%;
    }

    & > div:last-child {
        text-align: right;
    }
`

const InvoiceDataSummaryRow = styled(InvoiceDataDetailsRow)`
    & > div {
        text-align: right;
    }
`

export const InvoiceRowItem = styled.div`
    flex-grow: 1;
`

const InvoiceDataDetailsSectionHeader = styled.h5`
    text-align: left;
    font-size: 16px;
    margin: 14px 0 3px 0;
    font-weight: 400;
`

const InvoiceDataSummaryRowHeader = styled(InvoiceDataDetailsSectionHeader)`
    font-weight: 700;
`

const InvoiceHeader = styled.h5`
    margin: 40px 0 40px 0;
    text-align: left;
    font-weight: 400;
`

const InvoiceVATDataRow = styled(InvoiceDataDetailsRow)`
    text-align: left;
`

const InvoiceVATHeaderRow = styled(InvoiceVATDataRow)`
    background-color: #fff;
    color: #000;
    font-weight: 700;
`