// @flow
import React, { Component } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { inject, observer } from 'mobx-react'
import RegisterUserForm from '../Shared/RegisterUserForm'
import RegisterCompanyForm from '../Shared/RegisterCompanyForm'
import { apiUrl } from '../../../config/constants'
import type { FormState } from '../../Form'
import LoginStore from '../../../store/LoginStore'
import getHeaders from '../../../utils/getHeaders'

type Props = {
  LoginStore: typeof LoginStore,
  location: {
    search: string,
  },
}

type State = {
  errorMessage: string,
  exists: boolean,
  formState: number,
  loading: boolean,
  success: boolean,
  sendingEmail: boolean,
  userForm?: FormState,
}

type UserFormData = {
  email: mixed,
  firstName: mixed,
  lastName: mixed,
  password: mixed,
  password2: mixed,
  phone: mixed,
}

type CompanyFormData = {
  name: mixed,
  postOffice: mixed,
  postalCode: mixed,
  street: mixed,
}

class ManagerRegisterDetails extends Component<Props, State> {
  state = {
    errorMessage: '',
    exists: false,
    formState: 0,
    loading: false,
    success: false,
    sendingEmail: false,
    userForm: null,
  }

  componentDidMount = () => {
    const { LoginStore } = this.props
    if (LoginStore.logged) {
      LoginStore.logout()
    }
  }

  incrementFormState = (form: FormState) => {
    if (!form || !form.valid) {
      // console.log('No form data or invalid form')
      this.setState({ loading: false })
      return
    }
    this.setState({ formState: 1, userForm: form })
  }

  // onSubmit function passed to form
  handleSubmit = async (companyForm: FormState) => {
    const { location } = this.props
    const { userForm } = this.state

    const parts = location.search.match('[?&]token=([^&]+)')
    const tupasToken = parts && parts.length >= 1 ? parts[1] : ''

    const history = createBrowserHistory()
    // Clears tupas token from browser history
    history.replace({ pathname: '/' })

    this.setState({ loading: true })

    if (!companyForm || !companyForm.valid) {
      // console.log('No company form data or invalid form')
      this.setState({ loading: false })
      return
    }

    if (!userForm) {
      // console.log('No user form data or invalid form')
      this.setState({ loading: false })
      return
    }

    const userFormData: UserFormData = userForm.fields
      .mapEntries(([field, data]) => [field, data.value])
      .toJS()

    const companyFormData: CompanyFormData = companyForm.fields
      .mapEntries(([field, data]) => {
        return [field, data.value]
      })
      .toJS()

    try {
      await this.registerUser(userFormData, tupasToken)
      try {
        await this.registerCompany(companyFormData, tupasToken)
      } catch (error) {

      }
    } catch (error) {

    }
  }

  registerUser = async (formData: UserFormData, tupasToken: string) => {

    const body = {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      phone: formData.phone,
    }

    const headers = getHeaders()

    const req = new Request(`${apiUrl}/user/remcompany`, {
      body: JSON.stringify(body),
      headers: headers,
      method: 'POST',
      mode: 'cors',
    })

    try {
      const response = await fetch(req)
      if (response.status === 400) {
        // console.log('User exists')
        this.setState({ exists: true, loading: false })
      } else if (response.status === 403) {
        // console.log('User registered but error fetcing user data')
        this.setState({
          errorMessage:
            'Käyttäjä rekisteröity. Virhe asiakkuustietoja hakiessa.',
        })
        this.setState({ loading: false })
        return
      } else if (response.ok) {
        // console.log('User registered succesfully')
        this.emailSend(formData, tupasToken)
      } else {
        this.setState({
          errorMessage: 'Virhe käyttäjää rekisteröidessä',
          loading: false,
        })
      }
    } catch (error) {
      // console.log('Error registering user')
      this.setState({
        errorMessage: 'Yhteysvirhe käyttäjää rekisteröidessä',
        loading: false,
      })
    }
  }

  registerCompany = async (formData: CompanyFormData/*, tupasToken: string*/) => {

    const body = {
      name: formData.name,
      street: formData.street,
      postalCode: formData.postalCode,
      postOffice: formData.postOffice,
    }

    const headers = getHeaders()

    const req = new Request(`${apiUrl}/remcompany`, {
      body: JSON.stringify(body),
      headers: headers,
      method: 'POST',
      mode: 'cors',
    })

    try {
      const response = await fetch(req)
      if (response.status === 400) {
        // console.log('Company exists')
        this.setState({ exists: true, loading: false })
        return
      } else if (response.ok) {
        // console.log('Company registered succesfully')
        this.setState({ success: true, loading: false })
      } else {
        this.setState({
          errorMessage: 'Virhe yritystä rekisteröidessä',
          loading: false,
        })
      }
    } catch (error) {
      // console.log('Error registering company')
      this.setState({
        errorMessage: 'Yhteysvirhe yritystä rekisteröidessä',
        loading: false,
      })
    }
  }

  render() {
    const { location } = this.props
    const { loading, errorMessage, formState } = this.state

    if (!/(^\?token=)/.test(location.search)) {
      return <Redirect to="/isannointi/rekisteroidy" push={true} />
    }

    const submitText = loading ? 'Rekisteröidään' : 'Rekisteröidy'
    const nextPageText = loading ? 'Ladataan' : 'Jatka'

    // Email address or company ID already in use
    if (this.state.exists)
      return (
        <div className="wrapper">
          <h1>
            Olet jo rekisteröitynyt tällä sähköpostiosoitteella tai
            Y-tunnuksella.
          </h1>
          <NavLink to="/palauta">
            <button className="submit">Palauta käyttäjätili</button>
          </NavLink>
        </div>
      )

    // Succesful registration
    if (this.state.success)
      return <Redirect to="/isannoitsija/rekisteroidy/success" push={true} />

    return (
      <div className="wrapper">
        <h1>Rekisteröityminen yritysasiakkaana</h1>

        {errorMessage && <p className="errortext">{errorMessage}</p>}

        {formState === 0 && (
          <RegisterUserForm
            onSubmit={this.incrementFormState}
            submitText={nextPageText}
            loading={loading}
          />
        )}
        {formState === 1 && (
          <RegisterCompanyForm
            onSubmit={this.handleSubmit}
            submitText={submitText}
            loading={loading}
          />
        )}
      </div>
    )
  }
}

export default inject('LoginStore')(observer(ManagerRegisterDetails))
