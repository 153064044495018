// @flow
import React, { Component } from 'react'
import DevTools, { configureDevtool } from 'mobx-react-devtools'

import Header from '../components/Elements/Header'
import Footer from '../components/Elements/Footer'
import Routes from '../router'
import { getConfiguration } from '../utils/getConfiguration'
import AppTheme from './Providers/Theme'
import { withRouter } from 'react-router-dom'
import { themeDetails, footerContent } from '../theme/theme.ts'

configureDevtool({
  // Turn on logging changes button programmatically:
  // logEnabled: true,
})

//type Props = {}

class App extends Component /*<Props>*/ {
  AppHeader = withRouter(props => <Header {...props} />)
  componentDidMount = async (): Promise<void> => {
    await getConfiguration()

    document.title = themeDetails.brandName
  }
  render(): JSX.Element {
    const { AppHeader } = this
    return (
      <div className="app-wrapper">
        <AppTheme themeName="default">
          <AppHeader /> 
          <Routes />
          <Footer {...footerContent} />
        </AppTheme>

        {process.env.NODE_ENV === 'development' && <DevTools />}
      </div>
    )
  }
}

export default App
