import React from 'react'
import { ReactComponent as LogoFooter } from '../../theme/images/logo.svg'

type FooterContent = {
  contactInfo: ContactInfoItem[]
  officeHours?: string[]
  faultReports?: FaultReports
  website?: string
}

type ContactInfoItem = {
  icon: string
  type: string
  href: string
  href2?: string
  title: string
  content: string | string[]
}

type FaultReports = {
  intro: string
  contacts?: {
    href: string
    text: string
    linkText: string
  }[]
}

const ContactInfoItem: React.FunctionComponent<ContactInfoItem> = (
  item
): JSX.Element => {
  return (
    <div className="service icon">
      <span>
        <i className={item.icon} aria-hidden="true" />
        <p className="service-title">{item.title}</p>
        {item.type === 'address' ? (
          // address split to two rows (street address and zip code + area)
          <>
            <span>{item.content[0]}</span>
            <br />
            <span>{item.content[1]}</span>
          </>
        ) : item.type === 'website' ? (
          <a href={item.href} target="_blank" rel="noopener noreferrer">
            {item.content}
          </a>
        ) : item.type === 'mixed' ? (
          <>
            <a href={item.href}>{item.content[0]}</a>
            <br />
            <a href={item.href2}>{item.content[1]}</a>
          </>
        ) : (
          <a href={item.href}>{item.content}</a>
        )}
      </span>
    </div>
  )
}

const Footer: React.FunctionComponent<FooterContent> = (
  footerContent
): JSX.Element => {
  const contactInfo = footerContent.contactInfo
  const officeHours = footerContent.officeHours
  const faultReports = footerContent.faultReports

  return (
    <footer className="footer">
      <div className="container">
        <div className="left">
          <div className="contact-info">
            <h5>Yhteystiedot</h5>
            {contactInfo.map((item, idx: number) => (
              <ContactInfoItem key={idx} {...item} />
            ))}
          </div>

          {officeHours && (
            <div className="office-hours">
              <h5>Aukioloajat</h5>
              {officeHours.map((item: string, idx: number) => (
                <p key={idx}>{item}</p>
              ))}
            </div>
          )}
        </div>
        <div className="right">
          {faultReports && (
            <div className="fault-reports">
              <h5>Vikailmoitukset</h5>
              {faultReports.intro && (
                <>
                  <p>{faultReports.intro}</p>
                  <br />
                </>
              )}
              {/* Companies can have 0 or more contacts for filing fault reports */}
              {faultReports.contacts &&
                faultReports.contacts.length > 0 &&
                faultReports.contacts.map((item, idx) => (
                  <p key={idx}>
                    {item.text}
                    <a href={item.href}>{item.linkText}</a>
                  </p>
                ))}
            </div>
          )}

          <div className="footer-logo-and-links">
            <a
              href={footerContent.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoFooter className="logo-footer" />
            </a>
            <div className="links">
              <p className="privacyStatement">
                <a href="/tietosuojaseloste.pdf" target="_blank">
                  Tietosuojaseloste
                </a>
              </p>
              <p className="termsConditions">
                <a href="/kayttoehdot.pdf" target="_blank">
                  Käyttöehdot
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
