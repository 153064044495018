// @flow
import React, { ChangeEventHandler, FormEventHandler } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  onSubmit: FormEventHandler<HTMLFormElement>|undefined,
  onChange: ChangeEventHandler<HTMLInputElement>,
  submitEnabled: boolean,
}
const Conditions = ({ onChange, onSubmit, submitEnabled }: Props): JSX.Element => (
  <form className="form" onSubmit={onSubmit}>
    <div className="input-wrapper checkbox">
      <label>
        <input
          type="checkbox"
          name="terms"
          onChange={onChange}
          style={{ display: 'none' }}
        />
        <div className="checkbox-check" />
        <h5>
          Olen lukenut ja hyväksynyt palvelun{' '}
          <Link to="/kayttoehdot.pdf" target="_blank">
            käyttöehdot
          </Link>
        </h5>
      </label>
    </div>
    <div className="input-wrapper checkbox">
      <label>
        <input
          name="privacy"
          onChange={onChange}
          type="checkbox"
          style={{ display: 'none' }}
        />
        <div className="checkbox-check" />
        <h5>
          Olen lukenut ja hyväksynyt palvelun{' '}
          <Link to="/tietosuojaseloste.pdf" target="_blank">
            tietosuojaselosteen
          </Link>
        </h5>
      </label>
    </div>
    <input
      className="login-button"
      type="submit"
      name="submit"
      value="Lähetä"
      disabled={!submitEnabled}
    />
  </form>
)

export default Conditions
