// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { apiUrl, PASSWORD_MAX_LENGTH } from '../../../config/constants'
import {
    Field,
    FieldErrors,
    Form,
    Input,
    type FormState,
    validators as rules,
} from '../../Form'
import getHeaders from '../../../utils/getHeaders'

type Props = {
    location: {
        search: string,
    },
}

type State = {
    form: FormState|null,
    success: boolean,
    accountNotFound: boolean,
    token: string,
    email: string,
    errorMessage?: string,
    loading: boolean,
    initialized: boolean
}
class RetrieveCredentialsForm extends React.Component<Props, State> {
    state = {
        form: null,
        success: false,
        accountNotFound: false,
        token: null,
        email: '',
        errorMessage: null,
        loading: true,
        initialized: false
    }
    constructor() {
        super();
        this.removeAuthStateCookie();
    }
    removeAuthStateCookie(): void {
        document.cookie = "auth_state=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
    }

    fetchUserId = async (token: string): Promise<void> => {
        const headers = getHeaders(true, token);

        const req = new Request(`${apiUrl}/user/email`, {
            headers,
            method: 'GET',
            mode: 'cors',
        })

        try {
            const response = await fetch(req)
            if (response.status === 401) {
                this.setState({ errorMessage: 'Virheellinen tunnistautumiskoodi' })
            } else if (response.status === 404) {
                this.setState({ accountNotFound: true })
            } else if (response.ok) {
                const data = await response.json()
                this.setState({ email: data })
            } else {
                this.setState({ errorMessage: 'Virhe käyttäjätietoja hakiessa' })
            }
        } catch (error) {
            this.setState({ errorMessage: 'Palvelinvirhe' })
        }
    }

    componentDidMount = async (): Promise<void> => {
        const token = this.getQueryParam("token");
        const history = createBrowserHistory()
        history.replace({ pathname: '/' })

        if (token) {
            await this.fetchUserId(token)

            this.setState({ token, loading: false, initialized: true })
        }
    }

    // TODO Determine correct type since I really doubt it is actually always null.
    getFormState = (): any => this.state.form // eslint-disable-line

    getQueryParam = (param: string): string|null => {
        const { location } = this.props;
        const regex = RegExp(`${param}=([^&]*)?`, 'g');
        const result = regex.exec(location.search);
        return result !== null ? result[1] : null;
    }

    setFormState = (diff: FormState): Promise<void> =>
        new Promise(resolve => {
            this.setState(
                prevState => {
                    const form = prevState.form
                    if (!form) {
                        return { form: diff }
                    } else {
                        return {
                            form: {
                                ...form,
                                ...diff,
                                fields: form.fields.merge(diff.fields),
                            },
                        }
                    }
                },
                () => resolve()
            )
        })

    handleSubmit = async (): Promise<void|null> => {
        const { token } = this.state;
        const form = this.state.form
        if (!form || !form.valid) return null

        const formData = form.fields
            .mapEntries(([field, data]) => [field, data.value])
            .toJS()

        const headers = getHeaders(true, token)

        const req = new Request(`${apiUrl}/user/password/resetforgotten`, {
            body: JSON.stringify(formData),
            headers,
            method: 'PATCH',
            mode: 'cors',
        })

        try {
            const response = await fetch(req)
            if (response.ok) {
                this.setState({ success: true })
            } else {
                this.setState({ errorMessage: 'Virhe kirjautumistietoja palauttaessa' })
            }
        } catch (error) {
            this.setState({ errorMessage: 'Palvelinvirhe' })
        }
    }

    render(): JSX.Element {
        const { form, token, initialized } = this.state
        const valid = form && form.valid
        const hostName = window.location.origin
        const consumerDashboardUrl = hostName + "/auth/realms/assari/protocol/openid-connect/auth?client_id=assari&redirect_uri=" + hostName
        + "/kirjaudu&response_type=code&scope=openid+email+profile"

        if (this.state.loading) {
            return (
                <div className="wrapper">
                    <h1>Ladataan</h1>
                </div>
            )
        }

        if (this.state.accountNotFound && initialized && token === null) {
            return <Redirect to="/rekisteroidy/failure" push={true} />
        }

        if (this.state.errorMessage) {
            return (
                <div className="wrapper">
                    <h1>{this.state.errorMessage}</h1>
                </div>
            )
        }

        if (this.state.success) {

            return (
                <div className="wrapper">
                    <h1>Salasana vaihdettu</h1>
                    <h5>
                        <a href={consumerDashboardUrl}>Kirjaudu palveluun</a> käyttäen uutta salasanaa
                    </h5>
                </div>
            )
        }

        return (
            <div className="wrapper">
                <h1>Uusi salasana</h1>
                <p>
                    <label>Käyttäjätunnuksesi on: {this.state.email}</label>
                </p>
                <h5>
                    Voit vaihtaa salasanasi alla olevalla lomakkeella <br />tai{' '}
                    <a href={consumerDashboardUrl}>kirjautua palveluun vanhalla salasanalla</a>
                </h5>
                <Form
                    getState={this.getFormState}
                    setState={this.setFormState}
                    className="form register"
                    onSubmit={this.handleSubmit}
                >
                    <Field name="password" validators={validators.password1}>
                        <label
                            htmlFor="password"
                            className="input-wrapper"
                            invalidClass="invalid"
                        >
                            <Input
                                type="password"
                                name="password"
                                className="form-input"
                                maxLength={PASSWORD_MAX_LENGTH}
                            />
                            <span>Anna uusi salasana *</span>
                            <FieldErrors className="errors" />
                        </label>
                    </Field>
                    <Field name="password2" validators={validators.password2}>
                        <label
                            htmlFor="password2"
                            className="input-wrapper"
                            invalidClass="invalid"
                        >
                            <Input
                                type="password"
                                name="password2"
                                className="form-input"
                                maxLength={PASSWORD_MAX_LENGTH}
                            />
                            <span>Toista uusi salasana *</span>
                            <FieldErrors className="errors" />
                        </label>
                    </Field>
                    <input
                        type="submit"
                        name="submit"
                        value="Tallenna"
                        disabled={!valid}
                    />
                </Form>
            </div>
        )
    }
}

const validators = {
    password1: [
        rules.required('Salasana on pakollinen'),
        rules.minLength(8, 'Salasanan on oltava vähintään 8 merkkiä pitkä'),
        rules.password(2, 'Salasana on liian yksinkertainen'),
    ],
    password2: [
        rules.required('Kenttä on pakollinen'),
        rules.samesAs('password', 'Salasanat eivät ole samat'),
    ],
}

export default RetrieveCredentialsForm
