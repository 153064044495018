import React, { SetStateAction, useState } from 'react';
import ReactPaginate from 'react-paginate';

import CompanyCard from './CompanyCard';
import PersonCard from './PersonCard';
import { Customerships, FormData } from '../../types/companies';
import { ReactComponent as Chevron } from '../../assets/images/chevron-up.svg';

const PaginatedItems = ({
  items,
  itemsPerPage,
  viewType,
  currentIndex,
  handleCardOpening,
  handleModalOpening,
  setAction,
  personsData,
  setPersonIndex,
  setCurrentIndex,
}: {
  items: Customerships[] | FormData[] // the type definition for actual company and person data will probably change
  itemsPerPage: number
  viewType: string
  currentIndex: number
  handleCardOpening: (idx: number) => void
  handleModalOpening: () => void
  setAction: React.Dispatch<React.SetStateAction<string>>
  personsData: FormData[]
  setPersonIndex: React.Dispatch<React.SetStateAction<number>>
  setCurrentIndex: React.Dispatch<SetStateAction<number>>
}): JSX.Element => {
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  const currentItems = items?.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(items?.length / itemsPerPage)

  const handlePageClick = (e: { selected: number }) => {
    const newOffset = (e.selected * itemsPerPage) % items?.length
    setItemOffset(newOffset)
    setCurrentIndex(-1) // closes open accordions when switching views
  }
  return (
    <>
      {viewType === 'company' ? (
        <>
          {(currentItems as Customerships[])?.map((company, idx) => (
            <CompanyCard
              key={idx}
              company={company}
              collapsed={idx !== currentIndex}
              handleCardOpening={() => handleCardOpening(idx)}
            />
          ))}
        </>
      ) : (
        <>
          {(currentItems as FormData[]).map((person, idx) => (
            <PersonCard
              key={idx}
              person={person}
              collapsed={idx !== currentIndex}
              handleCardOpening={() => handleCardOpening(idx)}
              handleModalOpening={handleModalOpening}
              setAction={setAction}
              personsData={personsData}
              setPersonIndex={setPersonIndex}
            />
          ))}
        </>
      )}
      <ReactPaginate
        breakLabel="..."
        nextLabel={<Chevron />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        pageClassName="page"
        previousLabel={<Chevron />}
        renderOnZeroPageCount={() => null}
        containerClassName="pagination"
      />
    </>
  )
}

export default PaginatedItems
