// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import LoginStore from '../../../store/LoginStore'

import { ReactComponent as Kuluttaja } from '../../../assets/images/kuluttaja.svg'
// import { ReactComponent as Isannointi } from '../../../assets/images/isannointi.svg'

type Props = {
  LoginStore: typeof LoginStore,
}

class NotFound extends Component<Props> {
  render() {
    const hostName = window.location.origin
    return (
      <div className="notfound-wrapper container">
        <h1>Yritit avata näkymän, johon sinulla ei ole käyttöoikeutta</h1>
        <div className="login-links-wrapper">
          <a href={hostName}>
            <Kuluttaja className="forbidden-login-logo" alt="Kuluttajat" />
            <button className="forbidden-login-btn">
              Kirjaudu kuluttajana
            </button>
          </a>

          {/* <a href={remDashboardUrl}>
            <Isannointi className="forbidden-login-logo" alt="Isännöinti" />
            <button className="forbidden-login-btn">
              Kirjaudu isännöitsijänä
            </button>
          </a> */}
        </div>
      </div>
    )
  }
}

export default inject('LoginStore')(observer(NotFound))
