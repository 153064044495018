// @flow
import type { Validator } from './'
import { List } from 'immutable'
import asdfgh from 'asdfgh'

export const EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&''*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
export const PHONE_REGEX = /^\s*\+[1-9][0-9\s]+\s*$/
export const NUMBER_REGEX = /^\d+$/

export const validators: {
  // What on earth is this trying to do?
  // Also type params.
  [string]: (...params: any) => Validator, // eslint-disable-line
} = {
  required: (error: string) => ({
    validate: (value: string) => {
      if (!value) return error
      return null
    },
    trigger: 'change',
  }),
  minLength: (len: number, error: string) => ({
    // I'm guessing that all these "value" variables are strings as they are validation.
    validate: (value: string) => {
      if (value && value.length < len) return error
      return null
    },
    trigger: 'change',
  }),
  maxLength: (len: number, error: string) => ({
    validate: (value: string) => {
      if (value && value.length > len) return error
      return null
    },
    trigger: 'change',
  }),
  email: (error: string) => ({
    validate: (value: string) => {
      if (value && !EMAIL_REGEX.test(value)) return error
      return null
    },
    trigger: 'change',
  }),
  phone: (error: string) => ({
    validate: (value: string) => {
      if (value && (value.length < 6 || !PHONE_REGEX.test(value))) return error
      return null
    },
    trigger: 'change',
  }),
  number: (error: string) => ({
    validate: (value: string) => {
      if (value && !NUMBER_REGEX.test(value)) return error
      return null
    },
    trigger: 'change',
  }),
  samesAs: (compareToField: string, error: string) => ({
    // TODO Determine what formState type is.
    validate: (value: string, formState: any) => { // eslint-disable-line
      const otherField = formState.fields.get(compareToField)
      if (otherField) {
        const otherValue = otherField.value
        if (value && value !== otherValue) return error
      }
      return null
    },
    trigger: 'change',
  }),
  either: (compareToField: string, error: string) => ({
    validate: (value: string, formState: any) => { // eslint-disable-line
      const otherField = formState.fields.get(compareToField)
      if (otherField.value || value) {
        otherField.errors = List()
        return null
      }
      otherField.errors = List.of(error)
      return error
    },
    trigger: 'change',
  }),
  password: (score: number, error: string) => ({
    validate: async (value: string) => {
      if (!value) return null

      try {
        // Check the password strength
        const strength = await asdfgh(value)

        if (strength.score < score) {
          return error
        }
        return null
      } catch (err) {
        throw err
      }
    },
    trigger: 'change',
  }),
}
