// @flow
import React from 'react'
import styled from 'styled-components'
import * as moment from 'moment-timezone'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import ConsumerProductTypesTabView from '../ProductTypes/ConsumerProductTypesTabView'
import MPFilter from '../../../Elements/MPFilter'
import YearFilter from '../../../Elements/YearFilter'
import InvoicesService from '../../../Services/InvoicesService'
import ConsumerInvoiceListItem from './ConsumerInvoiceListItem'
import Loading from '../../Shared/Loading'
import FilterField from '../../../Elements/FilterField'
import BackButton from '../../../Elements/BackButton'
// type Props = {}
type State = {
  year: number,
  mp: string,
}

const LoadingComponent = ()/*props*/ => <Loading message={`Ladataan laskuja...`} />
class ConsumerInvoices extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      year: moment().year(),
      mp: null,
    }
    this.onYearChange = this.onYearChange.bind(this)
    this.onMPChange = this.onMPChange.bind(this)
  }
  componentDidMount = async () => {
    window.scrollTo(0, 0)
    const { LoginStore } = this.props
    if (!LoginStore.logged) {
      await LoginStore.login('consumer', true)
    }
  }

  onYearChange = year => {
    this.setState({
      year: parseInt(year, 10),
    })
  }

  onMPChange = (mp, year) => {
    this.setState({
      mp,
      year: year || this.state.year,
    })
  }

  render() {
    const { match } = this.props
    const { mp, year } = this.state
    const selectedProductType = match.params.type
    const productTypesTabViewProps = {}
    if (selectedProductType) {
      productTypesTabViewProps.initialProductType = selectedProductType
    }
    return (
      <div className="wrapper">
        <div className="btn-row">
          <BackButton to="/sopimukset" />
          <h1>Laskut</h1>
        </div>
        <ConsumerProductTypesTabView {...productTypesTabViewProps}>
          {({
            selectedProductType,
            selectedProductName,
            onlyWaterContracts,
          }) => {
            if (onlyWaterContracts) {
              return <p>Veden laskutiedot tulossa seuraavissa julkaisuissa.</p>
            } else {
              const earliestYear = selectedProductType === 'heat' ? 2015 : 2020
              return (
                <InvoicesView>
                  <FilterFields>
                    <FilterField title="Valitse vuosi">
                      <YearFilter
                        earliestYear={earliestYear}
                        onChange={this.onYearChange}
                        productType={selectedProductType}
                        selectedYear={year}
                      />
                    </FilterField>
                    <FilterField title="Valitse käyttöpaikka">
                      <MPFilter
                        selectedProductType={selectedProductType}
                        selectedMP={mp}
                        onChange={this.onMPChange}
                      />
                    </FilterField>
                  </FilterFields>
                  {mp && (
                    <InvoicesService
                      selectedProductType={selectedProductType}
                      selectedMP={mp}
                      selectedYear={year}
                    >
                      {({ loadingInvoices, invoices, errorMessage }) => {
                        if (loadingInvoices) {
                          return <LoadingComponent />
                        } else if (invoices && invoices.length === 0) {
                          return (
                            <h1>
                              Hakukriteereillä(käyttöpaikka ja vuosi) ei
                              löytynyt yhtään{' '}
                              {selectedProductName.toLowerCase()}laskua
                            </h1>
                          )
                        } else if (invoices && invoices.length > 0) {
                          return invoices.map(invoice => (
                            <ConsumerInvoiceListItem
                              key={invoice.Laskunumero}
                              invoice={invoice}
                              productType={selectedProductType}
                            />
                          ))
                        } else {
                          return <h1>{errorMessage}</h1>
                        }
                      }}
                    </InvoicesService>
                  )}
                </InvoicesView>
              )
            }
          }}
        </ConsumerProductTypesTabView>
      </div>
    )
  }
}

const FilterFields = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InvoicesView = styled.div`
  max-width: 800px;
  margin: 50px auto;
`

export default withRouter(inject('LoginStore')(observer(ConsumerInvoices)))
