// @flow
import React, { Component } from 'react'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import { UsageData } from '../../store/index'

import 'moment/locale/fi'
import Calendar from './Calendar'
moment.locale('fi')

type Props = {
  updateUsageData: (usageData:UsageData)=>void
  usageData: UsageData
  userType: string
  isCompany: boolean
}

type State = {
  calendarStartOpen: boolean
  calendarEndOpen: boolean
  customStartDate?: moment.Moment | null
}

const SHOW = true
const HIDE = false

const show = {
  year: { electricity: SHOW, heat: SHOW, water: SHOW },
  month: { electricity: SHOW, heat: SHOW, water: SHOW },
  week: { electricity: SHOW, heat: SHOW, water: SHOW },
  day: { electricity: SHOW, heat: SHOW, water: HIDE },
  hour:{ electricity: SHOW, heat: HIDE, water: HIDE }, //ADP
} 

class TimeframeSelector extends Component<Props, State> {
  constructor(props:Props) {
    super(props)
    this.state = {
      calendarStartOpen: false,
      calendarEndOpen: false,
      customStartDate: null,
    }
  }

  toggleCalStart = (): void => {
    const { usageData } = this.props
    const { calendarStartOpen } = this.state

    usageData.frame = 'custom'
    this.setState({ calendarStartOpen: !calendarStartOpen })
  }

  handleCalChangeStart = (startdate: moment.Moment): void => {
    this.setState({
      customStartDate: startdate,
      calendarStartOpen: false,
      calendarEndOpen: true,
    })
  }

  handleCalChangeEnd = (enddate: moment.Moment): void => {
    const { usageData } = this.props
    const { customStartDate } = this.state

    this.setState({ calendarEndOpen: false })
    const start = moment(customStartDate).format('YYYY-MM-DD')
    const end = moment(enddate).format('YYYY-MM-DD')

    let level = 'day'

    if (start === end) {
      level = 'hour'
    }

    this.props.updateUsageData({
      ...usageData,
      level,
      start,
      end,
    })
  }

  changePeriod = (frame: string, level: string): void => {
    const { usageData, updateUsageData } = this.props
    const timeframe = frame as moment.unitOfTime.StartOf;
    const now = moment();


    updateUsageData({
      ...usageData,
      start: moment(now)
        .startOf(timeframe)
        .format('YYYY-MM-DD'),
      end: moment(now)
        .endOf(timeframe)
        .format('YYYY-MM-DD'),
      level,
      frame,
    })
  }
  render(): JSX.Element {
    const { usageData, userType } = this.props;
    const { changePeriod } = this;
    let year, month, week, cal

    if (usageData.frame === 'year') {
      year = 'selected'
    } else if (usageData.frame === 'month') {
      month = 'selected'
    } else if (usageData.frame === 'week') {
      week = 'selected'
    } else {
      cal = 'selected'
    }

    const type = usageData.type
    return (
      <ul className="graph-periods">
        <li className={year} onClick={() => changePeriod('year', 'month')}>
          {this.props.isCompany ? (
            <div className="input-wrapper">
              <input
                type="radio"
                name="time"
                id="year"
                checked={year === 'selected' ? true : false}
                readOnly
              />
              <label htmlFor="year">Vuosi</label>
            </div>
          ) : (
            <>Vuosi</>
          )}
        </li>

        {show['month'][type] && (
          <li className={month} onClick={() => changePeriod('month', 'day')}>
            {this.props.isCompany ? (
              <div className="input-wrapper">
                <input
                  type="radio"
                  name="time"
                  id="month"
                  checked={month === 'selected' ? true : false}
                  readOnly
                />
                <label htmlFor="month">Kuukausi</label>
              </div>
            ) : (
              <>Kuukausi</>
            )}
          </li>
        )}

        {show['week'][type] && (
          <li className={week} onClick={() => changePeriod('week', 'day')}>
            {this.props.isCompany ? (
              <div className="input-wrapper">
                <input
                  type="radio"
                  name="time"
                  id="week"
                  checked={week === 'selected' ? true : false}
                  readOnly
                />
                <label htmlFor="week">Viikko</label>
              </div>
            ) : (
              <>Viikko</>
            )}
          </li>
        )}

        {!['water'].includes(type) &&
          (show['day'][type] ||
            userType === 'manager' ||
            userType === 'superuser') && (
            <div
              className={`calendar-wrapper${cal ? ` ${cal}` : ''}`}
              onClick={this.toggleCalStart}
            >
              {this.props.isCompany && (
                <div className="input-wrapper">
                  <input
                    type="radio"
                    name="time"
                    id="custom"
                    checked={cal === 'selected' ? true : false}
                    readOnly
                  />
                  <label htmlFor="week">Määritä ajanjakso</label>
                </div>
              )}
              <li>
                <Calendar
                  updateUsageData={this.props.updateUsageData}
                  usageData={this.props.usageData}
                />
              </li>
            </div>
          )}
      </ul>
    )
  }
}

export default TimeframeSelector
