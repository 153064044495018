// @flow
import React, { Component } from 'react'
import moment, { DurationInputArg2 } from 'moment'
import { UsageData } from '../../store/index'

type Props = {
  usageData: UsageData
  updateUsageData: (usageData:UsageData)=>void
}
type State = {
  prevDisabled: boolean
  nextDisabled: boolean
}

type NavIcon = {
  disabled: boolean;
  onClickHandler: ()=>void;
  direction: string;

}

//refactor icon as custom JSX Element in order to define custom props without type errors -ADP
const NavIcon = ({
  disabled,
  onClickHandler,
  direction
}:NavIcon): JSX.Element => {

  return (
    <i  
    className={`fa fa-chevron-${direction}${disabled ? ' disabled' : ''}`}
    aria-hidden="true"
    onClick={() => !disabled && onClickHandler()}></i>
  )
}

class NavigationArrows extends Component<Props, State> {
  constructor(props:Props) {
    super(props)
    this.state = {
      prevDisabled: false,
      nextDisabled: false,
    }
  }
  getNavigationArrowState = (
    usageData: UsageData,
    thisDate: moment.Moment
  ): boolean[] => {
    const prevDisabled = false // TODO Apparently never reassigned so this probable doesn't work.
    let nextDisabled = false
    const startDate = moment(usageData.start)
    const endDate = moment(usageData.end).endOf('day')

    // Check if 'next' navigation should be disabled

    if (
      usageData.frame === 'year' &&
      endDate.get('year') >= thisDate.get('year')
    ) {
      nextDisabled = true
    } else if (
      usageData.frame === 'month' ||
      usageData.frame === 'week' ||
      usageData.frame === 'day'  
    ) {
      const isDateFrame = usageData.frame === 'day'
      const frame = isDateFrame ? 'date' : usageData.frame
      const suffixNow = isDateFrame
        ? thisDate.format('MMDD')
        : thisDate.get(frame)
      const suffixEnd = isDateFrame
        ? endDate.format('MMDD')
        : endDate.get(frame)
        //format dates to allow comparison:
      const thisYearAndMonth = `${thisDate.get('year')}${
        suffixNow as number < 10 && !isDateFrame ? '0' + suffixNow : suffixNow
      }`
      const endYearAndMonth = `${endDate.get('year')}${
        suffixEnd as number < 10 && !isDateFrame ? '0' + suffixEnd : suffixEnd
      }`
      nextDisabled = endYearAndMonth >= thisYearAndMonth
    } else if (usageData.frame === 'custom') {
      const endOfThisDay = moment(thisDate).endOf('day')
      nextDisabled =
        startDate.isSameOrAfter(endOfThisDay) ||
        (startDate.isSameOrBefore(endOfThisDay) &&
          endDate.isSameOrAfter(endOfThisDay))
    } 
   //check if next hour is in the future -ADP
    else if (usageData.frame === 'hour') {
      const endOfThisHour = moment(thisDate).endOf('hour')
      nextDisabled =
        startDate.isSameOrAfter(endOfThisHour) ||
        (startDate.isSameOrBefore(endOfThisHour) &&
          endDate.isSameOrAfter(endOfThisHour))
    } 

    return [prevDisabled, nextDisabled]
  }

  prevPeriod = (): void | null => {
    if (this.state.prevDisabled) return null

    const { usageData } = this.props
    const previousStart = usageData.start
    const previousEnd = usageData.end
    const timeframe = usageData.frame

    let start
    if (timeframe === 'year') {
      // Get new start by subtracting timeframe from old start
      start = moment(previousStart).subtract(1, timeframe)
    } else if (timeframe === 'custom') {
      // Get number of selected days
      const diff = moment(previousEnd).diff(moment(previousStart), 'days')
      // Get new start by subtracting number of selected days from old start
      start = moment(previousStart).subtract(diff + 1, 'day')
    } else {
      // Get new start by subtracting timeframe from old start
      start = moment(previousStart).subtract(1, timeframe as DurationInputArg2)
    }

    let end
    if (timeframe === 'custom') {
      end = moment(previousStart).subtract(1, 'day')
    } 
    //ADP
    else if (timeframe === 'hour') {
      end = moment(previousEnd).subtract(1, 'hour')
    }
    else {
      end = moment(start).clone().endOf(timeframe as moment.unitOfTime.StartOf)
    }
    //ADP
    if (timeframe === "hour") {
      this.props.updateUsageData({
        ...usageData,
        start: start.format('YYYY-MM-DD HH:mm:ss'),
        end: end.format('YYYY-MM-DD HH:mm:ss')
      })
    }
    else {
    //this.props.changePeriod(usageData.frame, usageData.level, start, end)
    this.props.updateUsageData({
      ...usageData,
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
    })
  }
  }

  nextPeriod = (): void | null => {
    if (this.state.nextDisabled) return null

    const { usageData } = this.props
    const previousStart = usageData.start
    const previousEnd = usageData.end
    const timeframe = usageData.frame


    let start
    //if timeframe is hour, get new start by adding one minute to old end -ADP
    if (timeframe === "hour") {start = moment(previousEnd).add(1, "second")
     } 

    // Get new start by adding one day to old end
    else
    {start = moment(previousEnd).add(1, 'day')}


    let end
    if (usageData.frame === 'custom') {
      // Get number of selected days
      const diff = moment(previousEnd).diff(moment(previousStart), 'days')

      // Check if selected days will go to future
      if (
        moment(previousEnd)
          .add(diff, 'day')
          .isAfter(moment())
      ) {
        // Set start to current day minus selected days
        start = moment().subtract(diff, 'day')
        // Set end to current day
        end = moment()
      } else {
        // Get new end by adding number of selected days to old end
        end = moment(previousEnd).add(diff + 1, 'day')
      }
    } else {
      end = start.clone().endOf(timeframe as moment.unitOfTime.StartOf)
    }

    //ADP
    if (timeframe === "hour"){
      this.props.updateUsageData({
        ...usageData,
        start: start.format('YYYY-MM-DD HH:mm:ss'), //CHECK
        end: end.format('YYYY-MM-DD HH:mm:ss'),
      })
    }
    else
   { //this.props.changePeriod(usageData.frame, usageData.level, start, end)
    this.props.updateUsageData({
      ...usageData,
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
    })
  }
  }

  render(): JSX.Element {
    const { usageData } = this.props
    const [prevDisabled, nextDisabled] = this.getNavigationArrowState(
      usageData,
      moment()
    )

    return (
      <div className="period-nav">
        <NavIcon disabled={prevDisabled} onClickHandler={this.prevPeriod} direction={'left'}/>
        <NavIcon disabled={nextDisabled} onClickHandler={this.nextPeriod} direction={'right'}/>
      </div>
    )
  }
}

export default NavigationArrows
