// @flow
const mapProductTypeForURL = (productType: string): string => {
    // const productTypeMap = {
    //     electricity: 'Sähkö',
    //     heat: 'Lämpö',
    //     water: 'Vesi',
    //     fiber: 'Valokuitu'
    // }

    // return productTypeMap[productType]
    // Replaced the above with this. Kept for now until we can test.
    // TODO Test and clean up.
    switch(productType) {
        case 'electricity':
            return 'Sähkö'
        case 'heat':
            return 'Lämpö'
        case 'water':
            return 'Vesi'
        case 'fiber':
            return 'Valokuitu'
        default:
            return ''
    }
}
export default mapProductTypeForURL