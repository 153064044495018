import React, { SetStateAction } from 'react'

import { FormData } from '../../types/companies';
import { personListData } from '../../types/companies';

import { ReactComponent as PenIcon } from '../../assets/images/pen.svg'
import { ReactComponent as TrashCanIcon } from '../../assets/images/trashcan.svg'
import { ReactComponent as ChevronUp } from '../../assets/images/chevron-up.svg'
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg'

export type Person = {
  name: string;
  email: string;
  companies: {
    businessId: string;
    companyName: string;
  }[];
}

const PersonCard = ({
  person,
  collapsed,
  handleCardOpening,
  handleModalOpening,
  setAction,
  personsData,
  setPersonIndex,
}: {
  person: personListData
  collapsed: boolean
  handleCardOpening: () => void
  handleModalOpening: () => void
  setAction: React.Dispatch<SetStateAction<string>>
  personsData: FormData[]
  setPersonIndex: React.Dispatch<SetStateAction<number>>
}): JSX.Element => {
  const splitEmailAddress = person.email.split('@')
  const getPersonIndex = () => {
    const index = personsData.findIndex(item => `${item.firstName} ${item.lastName}` === `${person.firstName} ${person.lastName}`)
    setPersonIndex(index)
  }
  return (
    <div className={'card' + (!collapsed ? ' open' : '')}>
      {person.role === 'admin' ? (
        <div className="person-info">
          <h2>Pääkäyttäjä: {`${person.firstName} ${person.lastName}`}</h2>
          <p>
            <>
              <span>{splitEmailAddress[0]}@</span>
              <span>{splitEmailAddress[1]}</span>
            </>
          </p>
        </div>
      ) : (
        <>
          <div className="person-info">
            <h2>{`${person.firstName} ${person.lastName}`}</h2>
            <p>
              <>
                <span>{splitEmailAddress[0]}@</span>
                <span>{splitEmailAddress[1]}</span>
              </>
            </p>
          </div>
          <div className="person-actions">
            <div className="edit-actions">
              <button
                className="action edit"
                onClick={() => {
                  setAction('edit');
                  handleModalOpening();
                  getPersonIndex();
                }}
              >
                <PenIcon />
                <span>Muokkaa</span>
              </button>
              <button
                className="action delete"
                onClick={() => {
                  setAction('delete');
                  handleModalOpening();
                  getPersonIndex();
                }}
              >
                <TrashCanIcon />
                <span>Poista</span>
              </button>
            </div>
            <button className="action more-info" onClick={handleCardOpening}>
              {collapsed ? (
                <>
                  Näytä lisää tietoja
                  <ChevronDown />
                </>
              ) : (
                <>
                  Näytä vähemmän
                  <ChevronUp />
                </>
              )}
            </button>
          </div>
          {!collapsed && (
            <div className="person-more-information">
              <table>
                <thead>
                  <tr>
                    <th>Yritys</th>
                    <th>Y-tunnus</th>
                  </tr>
                </thead>
                <tbody>
                  {person.companies.map((company, idx) => (
                    <tr key={idx}>
                      <td>{company.companyName}</td>
                      <td>{company.businessId}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PersonCard;
