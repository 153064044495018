import React, { useEffect, ChangeEvent } from 'react'

// Components.
import RopoInvoiceDetails from './RopoInvoiceDetails';
import BackButton from '../../../Elements/BackButton';
import InvoicesCalendar from '../../../Elements/InvoicesCalendar'
// Actions.
import { getRopoInvoices } from '../../../../actions/getRopoDetails'
// Types.
import { ropoInvoice, companyParams, IinvoicesResponse } from '../../../../types/ropo';
import { themeDetails } from '../../../../theme/theme.ts'
import moment from 'moment';

const RopoDetails: React.FunctionComponent = () => {
  const currentYear = new Date().getFullYear();
  const [invoices, setInvoices] = React.useState<ropoInvoice[]>();
  const [uniqueMeteringpoints, setUniqueMeteringpoints] = React.useState<JSX.Element[]>();
  const [uniqueYears, setUniqueYears] = React.useState<number[]>();
  const [activeMP, setActiveMP] = React.useState('all');
  const [activeYear, setActiveYear] = React.useState(currentYear);
  const [loading, setLoading] = React.useState(true);
  const [invoiceArray, setInvoiceArray] = React.useState<JSX.Element[]>();
  const [startDate, setStartDate] = React.useState<moment.Moment | null>(moment().subtract(1, 'year').startOf('year'));
  const [endDate, setEndDate] = React.useState<moment.Moment | null>(moment().endOf('year'));
  const [onlineMaxSaldoLimit, setOnlineMaxSaldoLimit] = React.useState<number>(0);

  useEffect(() => {
    async function fetchRopoInvoices(): Promise<void> {
      setLoading(true);

      /* Fetch the invoicing data from ropo.
       * This will return company params (used in postponing invoices) and
       * invoices array. These are destructed below.
       */
      const invoicingData: IinvoicesResponse | null = await getRopoInvoices();
      const { companyParams, invoices } = invoicingData;
      const onlineMaxSaldoLimitParam = companyParams.find((param: companyParams) => param.param === "OnlineMaxSaldoLimit");
      const onlineMaxSaldoLimitValue = onlineMaxSaldoLimitParam ? parseFloat(onlineMaxSaldoLimitParam.value) : 0;
      setOnlineMaxSaldoLimit(onlineMaxSaldoLimitValue);
      const ropoInvoices: ropoInvoice[] = invoices;

      if (ropoInvoices && ropoInvoices.length > 0) {
        // Sort the bills newest to oldest.
        const sortedInvoices = ropoInvoices.sort(
          (a, b) =>
            new Date(b.duedate).getTime() - new Date(a.duedate).getTime()
        )
        // We want unique endpoints to be used to populate the later select. If only 1 is returned
        // then we use that as the title for the table.
        let uniqueInvoiceMeteringpoints: JSX.Element[] = []
        // To prepare the optgroups we split them based on network id.
        const uniqueInvoiceMeteringpointsE: JSX.Element[] = []
        const uniqueInvoiceMeteringpointsH: JSX.Element[] = []
        const uniqueInvoiceYears: number[] = []
        const uniqueMeteringPoints: string[] = []
        sortedInvoices.map(invoice => {
          // Get unique metering points.
          if (invoice.calculation_periods && Array.isArray(invoice.calculation_periods)) {
            invoice.calculation_periods.map(period => {
              if (!uniqueMeteringPoints.includes(period.object_key)) {
                uniqueMeteringPoints.push(period.object_key)

                // According to client network_id will always be <COMPANY_CODE>000 for electricity and <COMPANY_CODE>000KL for heat.
                // Using this we check the last 2 digits, if it is 00 it is electricity.
                if (period.network_id && period.network_id.slice(-2) === '00') {
                  uniqueInvoiceMeteringpointsE.push(
                    <option
                      value={period.object_key}
                      key={period.object_key}
                    >{`${period.object_name}`}</option>
                  )
                } else {
                  uniqueInvoiceMeteringpointsH.push(
                    <option
                      value={period.object_key}
                      key={period.object_key}
                    >{`${period.object_name}`}</option>
                  )
                }
              }
              return false
            })
          }
          // Get unique years.
          if (invoice.duedate) {
            const year = new Date(invoice.duedate).getFullYear()
            if (!uniqueInvoiceYears.includes(year)) {
              uniqueInvoiceYears.push(year)
            }
          }
          return false
        })

        // If we have elements to show we put them into the corisponding optgroup.
        if (
          uniqueInvoiceMeteringpointsE.length > 0 &&
          uniqueInvoiceMeteringpointsH.length > 0
        ) {
          uniqueInvoiceMeteringpoints.push(
            <optgroup key="electricity" label={'Sähkösopimukset'}>
              {uniqueInvoiceMeteringpointsE}
            </optgroup>
          )
          uniqueInvoiceMeteringpoints.push(
            <optgroup key="heating" label={'Lämmityssopimukset'}>
              {uniqueInvoiceMeteringpointsH}
            </optgroup>
          )
        }
        // If there are no heating we can skip adding the optgroups.
        else if (uniqueInvoiceMeteringpointsE.length > 0) {
          uniqueInvoiceMeteringpoints = uniqueInvoiceMeteringpointsE
        }
        // Same if there are no electricity contracts, we skip the optgroups.
        else if (uniqueInvoiceMeteringpointsH.length > 0) {
          uniqueInvoiceMeteringpoints = uniqueInvoiceMeteringpointsH
        }

        setUniqueYears(uniqueInvoiceYears)
        setUniqueMeteringpoints(uniqueInvoiceMeteringpoints)
        setInvoices(sortedInvoices)
      }
      setLoading(false)
    }

    fetchRopoInvoices()
  }, [setUniqueMeteringpoints, setUniqueYears, setInvoices, setLoading])

  /**
   * Update the list of invoices when the year is changed.
   */
  useEffect(() => {
    if (invoices) {
      const updatedInvoiceArray: JSX.Element[] = []
      invoices.map(invoice => {
        if (
          activeMP === 'all' ||
          activeMP === invoice.calculation_periods[0].object_key
        ) {
          const invoiceYear = new Date(invoice.duedate).getFullYear()
          if (invoiceYear === activeYear) {
            updatedInvoiceArray.push(<RopoInvoiceDetails allInvoices={invoices} setInvoices={setInvoices} invoice={invoice} key={invoice.invoice_number} onlineMaxSaldoLimit={onlineMaxSaldoLimit}></RopoInvoiceDetails>);
          }
        }
        return false
      })
      if (
        updatedInvoiceArray.length === 0 &&
        activeYear === currentYear &&
        uniqueYears
      ) {
        // No invoices for current year.
        // Since we have sorted all invoices newest to oldest before setting unique years,
        // we can just use the first entry in that array, know it has invoices and is the
        // most recent year.
        setActiveYear(uniqueYears[0])
      }
      setInvoiceArray(updatedInvoiceArray)
      setLoading(false)
    }
  }, [invoices, activeYear, activeMP])
  /**
   * Called when the MP select is changed.
   * Updates the active metering point.
   */
  const updateSelectedMP = (event: ChangeEvent<HTMLSelectElement>): void => {
    event.persist()
    setActiveMP(event.target.value)
    setLoading(true)
  }

  /**
   * Called when the year select is changed.
   * Updates the active year.
   */
  const updateSelectedYear = (event: ChangeEvent<HTMLSelectElement>): void => {
    event.persist()
    setActiveYear(parseInt(event.target.value))
    setLoading(true)
  }

  // The optional select drop down.
  let invoiceOptions: JSX.Element
  // The full table that has the titles and optional select drop down in addition to invoiceArray.
  let invoiceTable: JSX.Element = <></>

  if (invoices) {
    if (uniqueMeteringpoints && uniqueMeteringpoints.length > 1) {
      // Since there are more than 1 unique endpoints we create a drop down
      // select to alow the user to choose what to show.
      invoiceOptions = (
        <select onChange={updateSelectedMP} value={activeMP}>
          <option value="all">Kaikki</option>
          {uniqueMeteringpoints}
        </select>
      )
    } else {
      // If there isn't more than 1 option we display the first as the title.
      invoiceOptions = <p>{invoices[0].calculation_periods[0].object_name}</p>
    }

    const yearOptions: JSX.Element[] = []
    if (uniqueYears) {
      uniqueYears.map(year => {
        yearOptions.push(<option value={year} key={year}>{`${year}`}</option>)
      })
    }
    const yearSelect = (
      <select onChange={updateSelectedYear} value={activeYear}>
        {yearOptions}
      </select>
    )

    invoiceTable = (
      <div className="ropo-table-responsive">
        {invoiceOptions}
        {yearSelect}
        <table>
          <tbody>
            <tr>
              <td>Eräpäivä</td>
              <td>Laskun numero</td>
              <td>Laskutyyppi</td>
              <td>Laskun summa</td>
              <td>Maksettava</td>
              <td>Laskun tila</td>
              <td>Laskun pdf</td>
              <td>Siirrä eräpäivää</td>
            </tr>
            {invoiceArray}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div>
      <div className="wrapper contracts">
        <div className="btn-row">
          <BackButton to="/dashboard" />
          <h1>Laskutiedot</h1>
        </div>
        <InvoicesCalendar
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
        />
        {loading && <p>Haetaan laskutietoja</p>}
        {!loading && !invoices && <p>Laskutietojen haku epäonnistui</p>}
        {!loading && invoices && invoiceTable}
    </div>
      {themeDetails.theme === 'tke' && (
        <div className="tke-invoice-support">
          <p>
            Laskun maksamiseen ja myöhästyneisiin maksuihin liittyvissä asioissa
            sinua palvelee kumppanimme Ropo:
            <a href="https://ropo.fi" target="_blank" rel="noopener noreferrer">
              ropo.fi
            </a>
          </p>
        </div>
      )}
    </div>
  )
}

export default RopoDetails
