// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import HouseSingleton from '../Manager/Dashboard/HouseSingleton'
import FiberIcon from '../../../assets/images/icon-fiber.svg'
import { apiUrl } from '../../../config/constants'
import HouseModal from '../Manager/HouseModal'

import ManagerModal from './ManagerModal'
import ManagerSingleton from './Dashboard/ManagerSingleton'
import { getMappedContracts } from '../../../utils/getMappedContracts'
import LoginStore from '../../../store/LoginStore'
import type { ContractMapped, Manager } from '../../../store/index'
import Loading from '../Shared/Loading'
import SuperuserTab from './SuperuserTab'

import { themeDetails } from '../../../theme/theme.ts'

type Props = {
  LoginStore: typeof LoginStore,
}

type State = {
  contracts: Array<ContractMapped>|null,
  managers: Array<Manager>|null,
  managerHasData: boolean,
  houseHasData: boolean,
  loading: boolean,
  managerIsOpen: boolean,
  houseIsOpen: boolean,
  modalManagerIsOpen: boolean,
  modalHouseIsOpen: boolean,
  managerRemoved: boolean,
  managerAdded: boolean,
  errorMessage: string,
  managerName: string,
}

class SuperuserDashboard extends Component<Props, State> {
  constructor() {
    super()
    this.state = {
      contracts: null,
      managers: null,
      managerHasData: false,
      houseHasData: false,
      loading: true,
      managerIsOpen: false,
      houseIsOpen: false,
      modalManagerIsOpen: false,
      modalHouseIsOpen: false,
      managerRemoved: false,
      managerAdded: false,
      errorMessage: '',
      managerName: '',
    }

    this.toggleManagerModal = this.toggleManagerModal.bind(this)
    this.toggleHouseModal = this.toggleHouseModal.bind(this)
  }

  serviceWrapperStyle = {
    clear: 'both',
  }
  componentDidMount = async () => {
    if (!LoginStore.logged) {
      await LoginStore.login('rem', true)
    }

    await this.getData()
    this.setState({ loading: false, managerIsOpen: true })
  }

  getData = async () => {
    // Get all mapped contracts
    const contractsMapped = getMappedContracts()

    if (contractsMapped && contractsMapped.length > 0) {
      await this.setState({ contracts: contractsMapped, houseHasData: true })
    } else {
      // No contract data
      this.setState({ houseHasData: false })
    }

    // Get managers and their companies
    const managers = await this.getManagers()
    if (managers && managers.length > 0) {
      await this.setState({ managers: managers, managerHasData: true })
    }
  }

  getManagers = async () => {
    const { LoginStore } = this.props

    const req = new Request(`${apiUrl}/user/myRemUsers`, {
      method: 'GET',
      mode: 'cors',
    })

    let response
    try {
      response = await fetch(req)
      if (response.status === 401) {
        LoginStore.logout()
        window.location.assign(window.location.origin)
        return
      } else if (response.ok) {
        const managers: Manager[] = []
        const data = await response.json()
        data.map(async (manager: Manager) => {
          managers.push(manager)
        })
        return managers
      } else {
        this.setState({
          errorMessage: 'Yhteysvirheitä isännöintitietoja ladatessa',
        })
      }
    } catch (error) {
      throw error
    }
  }

  toggleManagers = async () => {
    await this.setState({
      managerIsOpen: true,
      houseIsOpen: false,
    })
  }

  toggleHouses = async () => {
    await this.setState({
      houseIsOpen: true,
      managerIsOpen: false,
      managerRemoved: false,
      managerAdded: false,
      managerName: '',
    })
  }

  toggleManagerModal = async () => {
    const { modalManagerIsOpen } = this.state

    await this.setState({
      modalManagerIsOpen: !modalManagerIsOpen,
      modalHouseIsOpen: false,
    })
  }

  toggleHouseModal = async () => {
    const { modalHouseIsOpen } = this.state

    await this.setState({
      modalHouseIsOpen: !modalHouseIsOpen,
      modalManagerIsOpen: false,
    })
  }

  updateManagerModalState = async () => {
    await this.toggleManagerModal()
  }

  updateHouseModalState = async () => {
    await this.toggleHouseModal()
  }

  updateClients = async () => {
    await this.setState({ loading: true, contracts: [], managers: [] })
    await this.getData()
    await this.setState({
      loading: false,
      modalHouseIsOpen: false,
      modalManagerIsOpen: false,
    })
  }

  removeManager = async (name: string) => {
    await this.setState({
      managerRemoved: true,
      managerAdded: false,
      managerName: name,
    })
    await this.updateClients()
  }

  addManager = async (name: string) => {
    await this.setState({
      managerRemoved: false,
      managerAdded: true,
      managerName: name,
    })
    await this.updateClients()
  }

  render() {
    const {
      contracts,
      managers,
      managerHasData,
      houseHasData,
      loading,
      errorMessage,
      managerIsOpen,
      houseIsOpen,
      modalManagerIsOpen,
      modalHouseIsOpen,
      managerRemoved,
      managerAdded,
      managerName,
    } = this.state

    if (loading) {
      return <Loading message="Ladataan käyttötietoja..." />
    }

    return (
      <div className="wrapper dashboard superuser container">
        <SuperuserTab
          toggleManagerOpen={this.toggleManagers}
          toggleHouseOpen={this.toggleHouses}
        />
        {managerRemoved && (
          <h1 className="manager-info">
            {' '}
            Isännöitsijä {managerName} poistettu{' '}
          </h1>
        )}
        {managerAdded && (
          <h1 className="manager-info"> Isännöitsijä {managerName} lisätty </h1>
        )}

        {managerIsOpen && (
          <div className="products col-md-12">
            <div className="open-modal">
              <h3
                id="manager-add-trigger"
                className="open-modal-trigger manager-link"
                onClick={this.toggleManagerModal}
              >
                Lisää Isännöitsijä
              </h3>
            </div>
            <ManagerModal
              modalIsOpen={modalManagerIsOpen}
              updateModalState={this.updateManagerModalState}
              updateClients={this.updateClients}
              addManager={this.addManager}
            />

            {!managerHasData && (
              <h5 className="emptylist">Ei lisättyjä isännöitsijöitä</h5>
            )}

            {errorMessage && <p className="errortext">{errorMessage}</p>}

            {managerHasData && managers && (
              <ul className="managerlist">
                {managers.map((item, index) => (
                  <ManagerSingleton
                    loading={true}
                    key={index}
                    manager={item}
                    removeManager={this.removeManager}
                  />
                ))}
              </ul>
            )}
          </div>
        )}

        {houseIsOpen && (
          <div className="products col-md-12">
            <div className="open-modal contract-text">
              <h3
                id="house-add-trigger"
                className="open-modal-trigger manager-link"
                onClick={this.toggleHouseModal}
              >
                Lisää asunto-osakeyhtiö
              </h3>
            </div>

            <HouseModal
              modalIsOpen={modalHouseIsOpen}
              updateModalState={this.updateHouseModalState}
              updateClients={this.updateClients}
            />

            {!houseHasData && (
              <h5 className="emptylist">Ei lisättyjä asunto-osakeyhtiöitä</h5>
            )}

            {errorMessage && <p className="errortext">{errorMessage}</p>}

            {houseHasData && (
              <ul className="houselist">
                {contracts && contracts.map((item, index) => (
                  <HouseSingleton key={index} contractsMapped={item} />
                ))}
              </ul>
            )}
          </div>
        )}

        {themeDetails.services.indexOf('fibre') > -1 && (
          <div className="col-md-12" style={this.serviceWrapperStyle}>
            <div className="row">
              <div className="services col-md-6">
                <div
                  className="fiberad"
                  dangerouslySetInnerHTML={{
                    __html: LoginStore.getTemplate(`fiber0.html`, {
                      $FIBER_IMG_SRC: FiberIcon,
                    }),
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default inject('LoginStore')(observer(SuperuserDashboard))
