// @flow
import React, { Component } from 'react'
import {
  Field,
  FieldErrors,
  Form,
  Input,
  type FormState,
  validators as rules,
} from '../../Form'

type Props = {
  submitText: string,
  onSubmit: (value: FormState|null) => void,
  loading: boolean,
}

type State = {
  form: FormState|null,
}

class ManagerRegisterForm extends Component<Props, State> {
  state = {
    form: null,
  }

  // TODO Another type to determine!
  getFormState = (): any => this.state.form // eslint-disable-line

  setFormState = (diff: FormState): Promise<void> =>
    new Promise(resolve => {
      this.setState(
        prevState => {
          const form = prevState.form
          if (!form) {
            return { form: diff }
          } else {
            return {
              form: {
                ...form,
                ...diff,
                fields: form.fields.merge(diff.fields),
              },
            }
          }
        },
        () => resolve()
      )
    })

  render(): JSX.Element {
    const { loading } = this.props
    const { form } = this.state
    const valid = form && form.valid
    const { submitText, onSubmit } = this.props

    return (
      <Form
        getState={this.getFormState}
        setState={this.setFormState}
        className="form register"
        onSubmit={() => onSubmit(form)}
      >
        <Field name="password" validators={validators.password1}>
          <label
            htmlFor="password"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="password" name="password" className="form-input" />
            <span>Luo salasana *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="password2" validators={validators.password2}>
          <label
            htmlFor="password2"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="password" name="password2" className="form-input" />
            <span>Toista salasana *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <input
          className={loading ? 'loading' : ''}
          type="submit"
          name="submit"
          value={submitText}
          disabled={!valid}
        />
      </Form>
    )
  }
}

const validators = {
  password1: [
    rules.required('Salasana on pakollinen'),
    rules.minLength(8, 'Salasanan on oltava vähintään 8 merkkiä pitkä'),
    rules.password(2, 'Salasana on liian yksinkertainen'),
  ],
  password2: [
    rules.required('Kenttä on pakollinen'),
    rules.samesAs('password', 'Salasanat eivät ole samat'),
  ],
}

export default ManagerRegisterForm
