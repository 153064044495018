// @flow
import React, { Component, SetStateAction } from 'react'
//import { apiUrl } from '../../../config/constants'
import {
  Field,
  FieldErrors,
  Form,
  Input,
  FormState,
  validators as rules,
} from '../../Form'
//import { EMAIL_REGEX } from '../../Form/validators'
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg'
import { handleB2BRegistration } from '../../../actions/b2bUsers'

type Props = {
  submitText: string
  onSubmit: (value: FormState | null) => void
  loading: boolean
  setStatus: React.Dispatch<SetStateAction<boolean>>
}

type State = {
  form: FormState | null
  accountType?: string
  subsidiaryIds?: string[]
}

class B2BRegisterForm extends Component<Props, State> {
  state = {
    form: null,
    accountType: undefined,
    subsidiaryIds: [''],
  }

  // TODO: Gonna need to correctly type this.
  getFormState = (): any => this.state.form // eslint-disable-line

  setFormState = (diff: FormState): Promise<void> =>
    new Promise(resolve => {
      this.setState(
        prevState => {
          const form = prevState.form
          if (!form) {
            return { form: diff }
          } else {
            return {
              form: {
                ...form,
                ...diff,
                fields: form.fields.merge(diff.fields),
              },
            }
          }
        },
        () => resolve()
      )
    })

  setAccountType = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ accountType: e.target.value })
  }

  addSubsidiary = (): void => {
    this.setState({
      subsidiaryIds: [...this.state.subsidiaryIds, ''],
    })
  }
  updateSubsidiary = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const i = parseInt(e.target.name.split('subsidiaryId')[1])
    const subs = [...this.state.subsidiaryIds]
    subs[i] = e.target.value
    this.setState({
      subsidiaryIds: subs,
    })
  }
  removeSubsidiary = (i: number): void => {
    const subs = [...this.state.subsidiaryIds]
    subs.splice(i, 1)
    this.setState({
      subsidiaryIds: subs,
    })
  }

  handleSubmit = async (): Promise<void> => {
    const formData = this.state.form?.fields
      .mapEntries(([field, data]) => [field, data.value])
      .toJS()

    const body = {
      applyingCompany: formData.companyName,
      applyingPerson: formData.firstName + ' ' + formData.lastName,
      contactEmail: formData.email,
      applyingCompanyIdentifier: formData.companyId,
      daughterCompanyIdentifiers: this.state.subsidiaryIds,
      applyingCreds: this.state.accountType,
    }

    if (this.state.form && this.state.form.valid) {
      try {
        const response = await handleB2BRegistration(body)
        if (response) {
          this.props.setStatus(true)
        } else {
          this.props.setStatus(false)
        }
      } catch (error) {
        this.props.setStatus(false)
      }
    }
  }

  render(): JSX.Element {
    const { loading } = this.props
    const { form, subsidiaryIds } = this.state
    const valid = form && form.valid
    const { submitText } = this.props

    return (
      <Form
        getState={this.getFormState}
        setState={this.setFormState}
        className="form register"
        onSubmit={this.handleSubmit}
      >
        <div className="radio-wrapper">
          <p>Haluan tunnukset *</p>
          <div className="input-wrapper radio">
            <input
              type="radio"
              name="accountType"
              id="accountTypeBusiness"
              onChange={this.setAccountType}
              value="business"
            />
            <label htmlFor="accountTypeBusiness">Yritykselle</label>
          </div>
          <div className="input-wrapper radio">
            <input
              type="radio"
              name="accountType"
              id="accountTypeManager"
              onChange={this.setAccountType}
              value="manager"
            />
            <label htmlFor="accountTypeManager">Isännöintitoimistolle</label>
          </div>
        </div>

        <Field name="firstName" validators={validators.firstName}>
          <label
            htmlFor="firstName"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="text" name="firstName" className="form-input" />
            <span>Etunimi *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="lastName" validators={validators.lastName}>
          <label
            htmlFor="lastName"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="text" name="lastName" className="form-input" />
            <span>Sukunimi *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="email" validators={validators.email}>
          <label
            htmlFor="email"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="email" name="email" className="form-input" />
            <span>Sähköpostiosoite *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="companyName" validators={validators.companyName}>
          <label
            htmlFor="companyName"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="text" name="companyName" className="form-input" />
            <span>Yrityksen nimi *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="companyId" validators={validators.companyId}>
          <label
            htmlFor="companyId"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="text" name="companyId" className="form-input" />
            <span>Yrityksen Y-tunnus *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        {subsidiaryIds.map((sub, i) => (
          <div key={`subsidiary${i}`} className="form-field">
            <label
              htmlFor={`subsidiaryId${i}`}
              className="input-wrapper subsidiary"
            >
              <input
                type="text"
                name={`subsidiaryId${i}`}
                className="form-input"
                maxLength={9}
                value={sub}
                onChange={this.updateSubsidiary}
              />
              {i > 0 && <CloseIcon onClick={() => this.removeSubsidiary(i)} />}
              <span>Tytäryhtiön Y-tunnus</span>
              <FieldErrors className="errors" />
            </label>
          </div>
        ))}

        <div className="input-wrapper">
          <button
            onClick={this.addSubsidiary}
            type="button"
            className="add-subsidiary"
          >
            <CloseIcon /> Lisää uusi tytäryhtiö
          </button>
        </div>

        <input
          className={loading ? 'loading' : ''}
          type="submit"
          name="submit"
          value={submitText}
          disabled={!valid}
        />
      </Form>
    )
  }
}

// This is not currently used, will be added back later.
// const emailExists = (error: string) => ({
//   validate: async (value: string) => {
//     if (!value || !EMAIL_REGEX.test(value)) return null

//     try {
//       const response = await fetch(`${apiUrl}/user/userexists/?email=${value}`, {
//         method: 'HEAD',
//         mode: 'cors',
//       })
//       if (response.status === 200) {
//         // console.log('Email address exists')
//         return error
//       } else if (response.status === 404) {
//         // console.log('Email address free')
//         return null
//       } else {
//         // console.log('Error checking email address availability')
//         return null
//       }
//     } catch (err) {
//       // console.log('Error checking email address availability')
//       throw err
//     }
//   },
//   trigger: 'blur',
// })

const validators = {
  firstName: [
    rules.required('Etunimi on pakollinen'),
    rules.minLength(1, 'Etunimi on liian lyhyt'),
  ],
  lastName: [
    rules.required('Sukunimi on pakollinen'),
    rules.minLength(1, 'Sukunimi on liian lyhyt'),
  ],
  email: [
    rules.required('Sähköposti on pakollinen'),
    rules.email('Sähköpostiosoite on virheellinen'),
    //emailExists('Sähköpostiosoite on jo olemassa'),
  ],
  companyName: [
    rules.required('Yrityksen nimi on pakollinen'),
    rules.minLength(1, 'Yrityksen nimi on liian lyhyt'),
  ],
  companyId: [
    rules.required('Yrityksen Y-tunnus on pakollinen'),
    rules.minLength(9, 'Yrityksen Y-tunnus on liian lyhyt'),
    rules.maxLength(9, 'Yrityksen Y-tunnus on liian pitkä'),
  ],
}

export default B2BRegisterForm
