// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { apiUrl } from '../../../../config/constants'
import BarChart from '../../../BarChart/BarChart'
import fillDates from '../../../../utils/fillDates'
import getUsageData from '../../../../utils/getUsageData'
import LoginStore from '../../../../store/LoginStore'
import type { Tick } from '../../../BarChart/index'
import type { UsageData, Contract } from '../../../../store/index'

type Props = {
  LoginStore: typeof LoginStore,
  contract: Contract,
}

type State = {
  data: any, // eslint-disable-line
  usageData?: UsageData,
  axisClass: string,
  barsHidden: boolean,
  sum: number,
  tick?: Tick,
  timeframe: string,
  errorMessage: string,
  houseLink: string,
  linkEnabled: boolean
}

class HouseData extends Component<Props, State> {
  constructor() {
    super()
    this.state = {
      data: null,
      usageData: null,
      axisClass: '',
      barsHidden: true,
      sum: 0,
      tick: null,
      timeframe: '',
      errorMessage: '',
      houseLink: '',
      linkEnabled: false,
    }
  }

  componentDidMount = async () => {
    const { contract } = this.props
    const usageData: UsageData|null = await getUsageData(contract.utility);
    if (usageData)
      this.setState({
        usageData: usageData,
        barsHidden: true,
      })
    const filledData = await this.loadData(usageData.start, usageData.end)
    this.setState({
      usageData: {
        ...usageData,
        data: filledData,
      },
      linkEnabled: true,
    })
    this.handleData()
  }

  loadData = async (start_s: string, end_s: string): Promise<any> => {// eslint-disable-line
    // Hide bars for animation
    this.setState({ barsHidden: true })

    const { contract } = this.props

    const { usageData } = this.state
    if (!usageData) return null

    // Get measure point id from props or pathname
    const id = contract.mp.id

    let start = ''
    let end = ''


    // Set API call date format by usage period
    if (usageData.frame === 'year') {
      start = moment(start_s).format('YYYY-MM')
      end = moment(end_s).format('YYYY-MM')
    } else {
      start = moment(start_s).format('YYYY-MM-DD')
      end = moment(end_s).format('YYYY-MM-DD')
    }

    const path = usageData.level

    const req = new Request(
      `${apiUrl}/${usageData.type}/${path}?start=${start}&end=${end}&mp=${id}`,
      {
        method: 'GET',
        mode: 'cors',
      }
    )

    try {
      const response = await fetch(req)
      if (response.status === 401) {
        this.setState({
          errorMessage: '401 Unauthorized',
        })
        return null
      } else if (response.status === 403) {
        this.setState({
          errorMessage: '403 Forbidden',
        })
        return null
      } else if (response.ok) {
        this.setState({
          errorMessage: '',
        })
        const data = await response.json()
        // Add dates without datapoints
        const filledData = fillDates(data, start_s, end_s, usageData.level)
        if (!filledData) return null
        return filledData
      } else {
        this.setState({
          errorMessage: 'Virhe tietoja ladatessa',
        })
      }
    } catch (error) {
      // console.log('Error fetcing usage data')
    }
  }

  getUsageSum = async data => {
    if (data && data.data) {
      return Math.round(
        data.data
          .map(datapoint => datapoint.v)
          .reduce((total, amount) => total + amount, 0)
      )
    } else {
      return 0
    }
  }

  formatTimestamps = async (usageData: UsageData): Tick => {
    const period = usageData.level

    const tick = {
      labelFormat: 'DD.MM',
      hoverFormat: 'DD.MM.',
      length: 0,
      hoverFormatExtended: 'DD.MM.YY',
    }
    if (period === 'hour') {
      tick.labelFormat = 'HH:mm'
      tick.hoverFormat = 'DD.MM.YYYY HH:mm'
      tick.hoverFormatExtended = 'DD.MM.YYYY HH:mm'
    } else if (period === 'month') {
      tick.labelFormat = 'MMM'
      tick.hoverFormat = 'MMMM'
      tick.hoverFormatExtended = 'MMMM YYYY'
      tick.length = 3
    } else {
      tick.labelFormat = 'DD.MM.'
      tick.hoverFormat = 'DD.MM.'
      tick.hoverFormatExtended = 'DD.MM.YY'
    }

    return tick
  }

  // Format period shown on top of the graph
  formatPeriod = async (data): string => {
    let start = ''
    let end = ''
    if (data.periodType === 'month') {
      start = moment(data.period[0])
        .startOf('month')
        .format('DD.MM.YYYY')
      end = moment(data.period[1])
        .endOf('month')
        .format('DD.MM.YYYY')
    } else {
      start = moment(data.period[0]).format('DD.MM.YYYY')
      end = moment(data.period[1]).format('DD.MM.YYYY')
    }

    return `${start} - ${end}`
  }

  getGraphClassName = async (usageData: UsageData): string => {
    // Determine graph className used to determine timestamp frequency
    if (usageData.frame === 'custom' && usageData.level === 'hour') {
      return 'axis-0'
    } else if (usageData.level === 'hour') {
      return 'axis-0'
    } else if (usageData.frame === 'year') {
      // Show all months
      return 'axis-0'
    } else {
      // Determine value based on number of datapoints
      // Under 12 datapoints = axis-0
      return `axis-${Math.ceil(usageData.data.length / 12)}`
    }
  }

  getPeriod = async (usageData: UsageData): string => {
    // Get timestamps
    let firstTimestamp
    let lastTimestamp

    // Format timestamps
    if (usageData.frame === 'year') {
      firstTimestamp = moment
        .utc(usageData.start)
        .startOf('month')
        .format('DD.MM.YYYY')
      lastTimestamp = moment
        .utc(usageData.end)
        .endOf('month')
        .format('DD.MM.YYYY')
    } else {
      firstTimestamp = moment.utc(usageData.start).format('DD.MM.YYYY')
      lastTimestamp = moment.utc(usageData.end).format('DD.MM.YYYY')
    }

    let period
    // Build timestamp string
    if (usageData.frame === 'custom') {
      // Use first and last value
      period = `${firstTimestamp} - ${lastTimestamp}`
    } else if (usageData.level === 'hour') {
      // Show only last value
      period = lastTimestamp
    } else {
      // Use first and last value
      period = `${firstTimestamp} - ${lastTimestamp}`
    }

    return period
  }

  handleData = async () => {
    const { contract } = this.props
    const { usageData } = this.state
    if (!usageData || !usageData.data) return null

    // Total usage sum
    const sum = Math.round(
      usageData.data
        .map(datapoint => datapoint.v)
        .reduce((total, amount) => total + amount, 0)
    )

    // Get and format timeframe shown on top of the graph
    const timeframe = await this.getPeriod(usageData)

    // Format data timestamps on x-axis
    const tick = this.formatTimestamps(usageData)

    // Get graph className used for axis formatting
    // axis-1 shows all values, axis-2 every other value and so forth
    const axisClass = this.getGraphClassName(usageData)

    //Create link for the graph
    let type = ''

    if (usageData.type === 'water') {
      type = 'vesi'
    } else if (usageData.type === 'electricity') {
      type = 'sahko'
    } else if (usageData.type === 'heat') {
      type = 'kaukolampo'
    }
    const houseLink = `/taloyhtio/${type}/${contract.mp.id}`

    this.setState({
      barsHidden: false,
      axisClass: axisClass,
      sum: sum,
      tick: tick,
      timeframe: timeframe,
      houseLink: houseLink,
    })
  }

  render() {
    const { contract } = this.props
    const {
      usageData,
      axisClass,
      tick,
      timeframe,
      barsHidden,
      houseLink,
      linkEnabled,
    } = this.state
    if (!usageData || usageData.length === 0) {
      return null
    }

    const barsClassnames = barsHidden
      ? 'barchart-wrapper hidden ' + axisClass
      : 'barchart-wrapper visible ' + axisClass
    return (
      <div>
        <div className="row">
          <div>
            <div>
              <NavLink className={"list-item " + (linkEnabled ? "" : "link-disabled")} to={houseLink}>
                <p className="title">{usageData.title}</p>
              </NavLink>
            </div>
            <div className={barsClassnames}>
              <BarChart
                data={usageData.data}
                unit={usageData.unit}
                timeframe={timeframe}
                id={contract.mp.id}
                width="1100"
                height="270"
                tick={tick || null}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('LoginStore')(observer(HouseData))
