// @flow
import React, { Component } from 'react'
import { apiUrl } from '../../config/constants'
const tupasRegistrationPath = '/auth/reg/tupas/banks'
const tupasRetrieveCredentialsPath = '/auth/cred/tupas/banks'

type Bank = {
  algorithmType: string,
  bankAuthUrl: string,
  cancelLink: string,
  id: string,
  idType: string,
  identifier: string,
  imgPath: string,
  keyVersion: string,
  languageCode: string,
  mac: string,
  messageType: string,
  name: string,
  rejectLink: string,
  returnLink: string,
  vendorId: string,
  version: string,
}

type Props = {
  isRegistration?: boolean,
}

type State = {
  banks: Array<Bank>|null,
  errorMessage: string,
}

class Tupas extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      banks: null,
      errorMessage: '',
    }
  }

  componentDidMount = (): void => {
    this.loadBanks()
  }

  loadBanks = async (): Promise<void> => {
    const { isRegistration } = this.props

    const tupasPath = isRegistration
      ? tupasRegistrationPath
      : tupasRetrieveCredentialsPath

    const req = new Request(`${apiUrl}${tupasPath}`, {
      method: 'GET',
      mode: 'cors',
    })

    let response
    try {
      response = await fetch(req)
      if (response.ok) {
        const banks = await response.json()
        await this.setState({ banks: banks })
      } else {
        this.setState({ errorMessage: 'Virhe pankkitietoja ladatessa' })
      }
    } catch (error) {
      this.setState({ errorMessage: 'Yhteysvirhe pankkitietoja ladatessa' })
    }
  }

  handleBanks = (banks: Array<Bank>): JSX.Element[] => {
    return banks.map((bank, i) => {
      return (
        <li key={i} className="col-sm-3 col-md-2">
          <form
            id={bank.name.toLowerCase() + '-form'}
            method="POST"
            action={bank.bankAuthUrl}
            className="tupas-button"
          >
            <input className="bank-login-submit" type="submit" value="" />
            <div className={bank.name.toLowerCase() + '-login'}>
              <div className="bank-login-image">
                <img src={bank.imgPath} alt={bank.name} />
              </div>
              <div className="bank-login-name">{bank.name}</div>
            </div>
            <input
              name="A01Y_ACTION_ID"
              type="hidden"
              value={bank.messageType}
            />
            <input name="A01Y_VERS" type="hidden" value={bank.version} />
            <input name="A01Y_RCVID" type="hidden" value={bank.vendorId} />
            <input
              name="A01Y_LANGCODE"
              type="hidden"
              value={bank.languageCode}
            />
            <input name="A01Y_STAMP" type="hidden" value={bank.identifier} />
            <input name="A01Y_IDTYPE" type="hidden" value={bank.idType} />
            <input name="A01Y_RETLINK" type="hidden" value={bank.returnLink} />
            <input name="A01Y_CANLINK" type="hidden" value={bank.cancelLink} />
            <input name="A01Y_REJLINK" type="hidden" value={bank.rejectLink} />
            <input name="A01Y_KEYVERS" type="hidden" value={bank.keyVersion} />
            <input name="A01Y_ALG" type="hidden" value={bank.algorithmType} />
            <input name="A01Y_MAC" type="hidden" value={bank.mac} />
          </form>
        </li>
      )
    })
  }

  render(): JSX.Element {
    const { banks, errorMessage } = this.state

    let bankElements
    if (banks) {
      bankElements = this.handleBanks(banks)
    }

    return (
      <div className="banks container">
        {errorMessage && <p className="errortext">{errorMessage}</p>}

        <ul>{bankElements}</ul>
      </div>
    )
  }
}

export default Tupas
