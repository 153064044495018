// @flow
import * as React from 'react'
import { List } from 'immutable'

import { ErrorElement } from './'

type ErrorProps = {
  error: string,
}

type Props = {
  errorComponent: React.ComponentType<ErrorProps>,
  errors?: List<string>,
}

const FieldErrors: React.FunctionComponent<Props> = (props: Props) => {
  const { errorComponent, errors, ...other } = props

  if (!errors || errors.size === 0) return null
  return (
    <ul {...other}>
      {errors.map((error, i) =>
        React.createElement(errorComponent, { error, key: i })
      )}
    </ul>
  )
}

FieldErrors.defaultProps = {
  errorComponent: ErrorElement,
}

export default FieldErrors
