// @flow
import React, { Component } from 'react'
import AnimatedUsage from '../../../Elements/AnimatedUsage'

type Props = {
  usageSum: number,
  duration: number,
  name: string,
  unit: string,
  type: string,
  period: string,
  address: string,
  zip: string,
  city: string,
}

class Usage extends Component<Props> {
  render(): JSX.Element {
    const { usageSum, duration, name, unit, period, address, zip, city, type } = this.props
    let timeframe = ''

    if (period) {
      timeframe = unit + ' / kuluva vuosi'
    }
    /*
    if (period && type === 'water') {
      timeframe = 'Syötä ensimmäinen lukema'
    }
    */

    return (
      <div className="right">
        <div className="period">
          <p className="id">
            {address}
            <br />
            {zip}, {city}
          </p>
          <p className="name">{name}</p>
        </div>

        <AnimatedUsage usageSum={usageSum} duration={duration} type={type} heading={''} />

        <div className="unit">
          <p>{timeframe}</p>
        </div>
      </div>
    )
  }
}

export default Usage
