const ViewChart = {
    year: {
        electricity: true,
        heat: true,
        water: true,
    },
    month: {
        electricity: true,
        heat: true,
        water: true,
    },
    week: {
        electricity: true,
        heat: true,
        water: true,
    },
    day: {
        electricity: true,
        heat: true,
        water: false,
    },
    custom: {
        electricity: false,
        heat: false,
        water: false,
    },
}
export default ViewChart