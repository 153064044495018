// @flow
import { apiUrl } from '../config/constants'
import getHeaders from './getHeaders'
import LoginStore from '../store/LoginStore'

// TODO Determine if this type is correct.
const getConfiguration = async (): Promise<any> => { // eslint-disable-line
  const headers = getHeaders(true)
  // MITÄ HELVETTIA YO. TEKEE LOGOUTIN JA LOCALSTORAGE HÄVIÄÄ
  const req = new Request(`${apiUrl}/configuration`, {
    headers,
    method: 'GET',
    mode: 'cors',
  })

  try {
    const response = await fetch(req)
    if (response.status === 401) {
      // console.log('401: unauthorized,...')
    } else if (response.status === 404) {
      // console.log('404: not found')
    } else if (response.ok) {
      const conf = await response.json()
      return LoginStore.setConfiguration(conf)
    } else {
      // const error = new Error(response.statusText)
      // console.log(error)
    }
  } catch (error) {
  }
}

export { getConfiguration }
