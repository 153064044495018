// @flow
import LoginStore from '../store/LoginStore.ts'
import type { Customer, Contract, Customerships } from '../store/index'

export const getMappedContracts = (): Array<Contract> | null => {
  const clients = LoginStore.customerData

  if (!clients || clients.length === 0) {
    return null
  }

  const mappedContracts: Contract[][] = []
  clients.map(client => {
    mappedContracts.push(mapContracts(client))
    return undefined
  })

  // Sort contracts by water->electricity->districtheat
  mappedContracts.map(mappedContract => {
    return mappedContract.map((contracts: Contract) => {
      let contract = contracts.contracts
      contract = updateOfficial(contract)
      return contract.sort((a: Contract, b: Contract) => {
        if (a.utility === 'water') {
          return -1
        } else if (b.utility === 'water') {
          return 1
        } else if (a.utility === 'electricity') {
          return -1
        } else if (b.utility === 'electricity') {
          return 1
        } else {
          return 0
        }
      })
    })
  })

  // TODO Fix this, it throws errors because its an array but in the array there are
  // contracts so this is just trash.
  // Sort mappedContracts by id
  // mappedContracts.sort((a, b) => {
  //   return a.id > b.id ? 1 : b.id > a.id ? -1 : 0
  // })

  return mappedContracts;
}

// Combine consumer firstName and lastName to work consistently with manager users
const updateCustomer = (customer: Customer): Customer => {
  if (
    typeof customer.firstName !== 'undefined' &&
    typeof customer.lastName !== 'undefined'
  ) {
    customer.name = `${customer.firstName} ${customer.lastName}`

    delete customer.firstName
    delete customer.lastName
  }
  return customer
}

// Check that every customer has official numbet (Y-tunnus) for routing pusposes
const updateOfficial = (contracts: Array<Contract>): Array<Contract> => {
  let official = ''

  contracts.forEach(contract => {
    if (contract.customer.official) {
      official = contract.customer.official
    }
  })

  contracts.forEach(contract => {
    contract.customer.official = official
  })

  return contracts
}

const mapContracts = (client: Customerships) => {
  const mappedContracts: Contract[] = []

  client.customerships.forEach(customership => {
    customership.contracts.forEach((contract: Contract) => {
      contract.customer = updateCustomer(customership.customer)
      const cont = mappedContracts.find(c => c.id === client.id)
      if (cont === undefined) {
        const contractMapped = {
          id: client.id,
          name: contract.customer.name,
          contracts: [contract],
        }
        mappedContracts.push(contractMapped)
      } else {
        cont.contracts.push(contract)
      }
    })
  })
  return mappedContracts
}
