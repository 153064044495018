// @flow
import React, { Component } from 'react'
import Vatu from '../../Elements/Vatu'
import Conditions from '../../Elements/Conditions'
import { createBrowserHistory } from 'history'

type Props = {
    location: {
        search: string,
    },
}

type State = {
    terms: string,
    privacy: string,
    conditionsApproved: boolean,
    submitEnabled: boolean,
}

class ConsumerRegister extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            terms: '',
            privacy: '',
            conditionsApproved: false,
            submitEnabled: false,
        }
    }

    componentDidMount(): void {
        this.checkConditions()
    }

    checkConditions = (type?: string): void => {
        let { terms, privacy } = this.state
        if (!type) {
            const localTerms = localStorage.getItem('terms');
            const localPrivacy = localStorage.getItem('privacy');
            if (localTerms !== null) {
                terms = localTerms;
            }
            else {
                terms = '';
            }
            if (localPrivacy !== null) {
                privacy = localPrivacy;
            }
            else {
                privacy = '';
            }
        }

        const value = type || 'conditionsApproved'
        if (terms === 'approved' && privacy === 'approved') {
            this.setState({ [value]: true })
        } else {
            this.setState({ [value]: false })
        }
    }

    toggleApprove = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const item = e.target.name
        const value = this.state[item] === 'approved' ? 'declined' : 'approved'
        this.setState({ [item]: value }, () =>
            this.checkConditions('submitEnabled')
        )
    }

    onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        localStorage.setItem('terms', this.state.terms)
        localStorage.setItem('privacy', this.state.privacy)
        e.preventDefault()
        this.checkConditions()
    }

    getToken(): string {
        const { location } = this.props
        const parts = location.search.match('[?&]token=([^&]+)')
        return parts && parts.length >= 1 ? parts[1] : ''
    }

    render(): JSX.Element {
        const { location } = this.props
        const { conditionsApproved, submitEnabled } = this.state

        if (conditionsApproved) {
            const history = createBrowserHistory();
            history.goBack();
        }

        if (
            /(^\?error=cancel)/.test(location.search) ||
            /(^\?error=reject)/.test(location.search)
        ) {
            return (
                <div className="wrapper">
                    <h1>Tunnistautuminen keskeytyi</h1>
                    <h5>Jatka halutessasi takaisin tunnistautumiseen.</h5>
                    <Vatu label={''}/>
                </div>
            )
        }

        return (
                <div className="wrapper">
                    <h1>Palvelun käyttöehdot ja tietosuojaseloste</h1>
                    <Conditions
                        onChange={this.toggleApprove}
                        onSubmit={this.onSubmit}
                        submitEnabled={submitEnabled}
                    />
                </div>
            )
    }
}

export default ConsumerRegister
