// @flow
import * as React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import ReCAPTCHA from 'react-google-recaptcha'
import { apiUrl, logger } from '../../../config/constants'
import 'react-datepicker/dist/react-datepicker.css'
import {
  Field,
  FieldErrors,
  Form,
  Input,
  type FormState,
  validators as rules,
} from '../../Form'

// Set Finnish locale, required by react-datepicker
import 'moment/locale/fi'
import { inject, observer } from 'mobx-react/index'
import LoginStore from '../../../store/LoginStore'
import InputSelect from './InputSelect'
import getHeaders from '../../../utils/getHeaders'

moment.locale('fi')

type Props = {
  LoginStore: typeof LoginStore,
  location: {
    search: string,
  },
  match: any; // eslint-disable-line
}

type State = {
  date: string,
  errorMessage: string,
  form?: FormState,
  loading: boolean,
  success: boolean,
  recaptchaSuccess: boolean,
  sendCopy: boolean,
  g_response: string
}

class SubmitWaterMeasurementForm extends React.Component<Props, State> {
  state = {
    date: moment().format(),
    errorMessage: '',
    form: null,
    loading: false,
    success: false,
    recaptchaSuccess: false,
    sendCopy: false,
    g_response: '',
  }

  successDetailsWrapperStyle = {
    marginRight: "auto",
    marginLeft: "auto"
  }

  successDetailsFieldStyle = {
    textAlign: "left"
  }
  sendCopySpanStyle = {
    marginTop: "10px 0 0 0",
    pointerEvents: "none",
    position: "static",
  }

  componentDidMount = async () => {
    const { LoginStore } = this.props
    const { type } = this.props.match.params

    if (!LoginStore.logged && type) {
      await LoginStore.login(type, true)
    }
  }

  getFormState = () => this.state.form

  setFormState = (diff: FormState): Promise<void> =>
    new Promise(resolve => {
      this.setState(
        prevState => {
          const form = prevState.form
          if (!form) {
            return { form: diff }
          } else {
            return {
              form: {
                ...form,
                ...diff,
                fields: form.fields.merge(diff.fields),
              },
            }
          }
        },
        () => resolve()
      )
    })

  handleSubmit = async (/*event: SyntheticEvent<HTMLFormElement>*/) => {
    const { date, form, g_response, sendCopy } = this.state
    const { LoginStore } = this.props

    if (!form || !form.valid) return null

    this.setState({ errorMessage: '' })

    const formData = form.fields
      .mapEntries(([field, data]) => [field, data.value])
      .toJS()

    const body = {
      measurementPointId: formData.measurementpoint,
      meterId: formData.meternumber,
      date: moment(date).format('YYYY-MM-DD'),
      consumption: Number(formData.measurement),
      g_recaptcha_response: g_response,
    }
    if(sendCopy){
      body.email = formData.email;
    }

    const headers = getHeaders()

    const req = new Request(`${apiUrl}/water/submit${LoginStore.logged ? "/auth" : ""}`, {
      body: JSON.stringify(body),
      headers,
      method: 'POST',
      mode: 'cors',
    })
    try {
      this.setState({ loading: true })
      const response = await fetch(req)

      if (response.status === 404) {
        this.setState({
          loading: false,
          errorMessage: 'Tapahtui virhe, yritä myöhemmin uudelleen',
        })
        return null
      } else if (response.status === 500) {
        this.setState({
          loading: false,
          errorMessage: 'Tapahtui virhe, yritä myöhemmin uudelleen',
        })
        return null
      } else if (response.status === 502) {
        const body =  await response.json()
        if(body.error === "WATER_EMAIL_FAILED"){
          this.setState({
            loading: false,
            errorMessage: 'Sähköpostia ei voitu lähettää, yritä myöhemmin uudelleen',
          })
        } else if(body.error === "WATER_SAVE_FAILED"){
          this.setState({
            loading: false,
            errorMessage: 'Vesilukeman lähetys epäonnistui, yritä myöhemmin uudelleen',
          })
        }
        return null;
      } else if (response.ok) {
        this.setState({ success: true, loading: false })
        const properties = {
          measurementPointId: body.measurementPointId,
          meterId: body.meterId,
          measurementTime: body.date,
          consumption: body.consumption + '',
        }
        if (LoginStore.logged) {
          properties['userEmail'] = LoginStore.username
        }
        logger.trackEvent('Water measurement sent successfully', {
          eventType: 'WATER_MEASUREMENT_SUCCESS',
          ...properties,
        })
      } else {
        this.setState({
          loading: false,
          errorMessage: 'Virhe vesilukemaa lähettäessä',
        })
      }
    } catch (error) {
      this.setState({
        loading: false,
        errorMessage: 'Yhteysvirhe vesilukemaa lähettäessä',
      })
    }
  }

  handleChange = (date: typeof moment) => {
    this.setState({
      date: moment(date).format(),
    })
  }

  handleSendCopyChange = (event: SyntheticEvent<HTMLFormElement>) => {
    this.setState({
      sendCopy: event.target.checked
    });
  }

  waterMeasurementPoint = () => {
    let mp = undefined
    if (this.props.LoginStore.customerData) {
      this.props.LoginStore.customerData.forEach(
        cd =>
          cd &&
          cd.customerships &&
          cd.customerships.forEach(cus =>
            cus.contracts.forEach(con => {
              if (con.utility === 'water') {
                mp = con.mp
              }
            })
          )
      )
    }
    return mp
  }

  onReCaptchaChange = (value: string) => {
    this.setState({
      g_response: value,
      recaptchaSuccess: true,
    })
  }

  render() {
    const {
      date,
      loading,
      errorMessage,
      form,
      success,
      sendCopy,
      recaptchaSuccess,
    } = this.state
    if (success) {
      const formData = form.fields
        .mapEntries(([field, data]) => [field, data.value])
        .toJS()
      return (
        <div className="wrapper">
          <h1>Kiitos!</h1>
          <h5>Antamasi tiedot on lähetetty jatkokäsittelyyn</h5>
          <table style={this.successDetailsWrapperStyle}>
            <tbody>
              <tr>
                <td style={this.successDetailsFieldStyle}>Käyttöpaikan numero:</td><td style={this.successDetailsFieldStyle}>{formData.measurementpoint}</td>
              </tr>
              <tr>
                <td style={this.successDetailsFieldStyle}>Vesimittarin numero:</td><td style={this.successDetailsFieldStyle}>{formData.meternumber}</td>
              </tr>
              <tr>
                <td style={this.successDetailsFieldStyle}>Mittarilukema:</td><td style={this.successDetailsFieldStyle}>{formData.measurement}</td>
              </tr>
              <tr>
                <td style={this.successDetailsFieldStyle}>Mittarilukeman päiväys:</td><td style={this.successDetailsFieldStyle}>{ moment(date).format("DD.MM.YYYY")}</td>
              </tr>
              <tr>
                <td style={this.successDetailsFieldStyle}>Haluan kopion sähköpostiini:</td><td style={this.successDetailsFieldStyle}>{sendCopy ? "Kyllä" : "Ei"}</td>
              </tr>
              { sendCopy &&
                <tr>
                  <td style={this.successDetailsFieldStyle}>Sähköposti:</td><td style={this.successDetailsFieldStyle}>{formData.email}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      )
    }

    const mp = this.waterMeasurementPoint()

    const valid = form && form.valid
    let initialDeviceCode
    let deviceCodes = []
    // distinguish between backend phase 1 and phase 2
    if (mp && mp.deviceCode) {
      initialDeviceCode = mp.deviceCode
      deviceCodes = []
    } else if (mp && mp.deviceCodes) {
      initialDeviceCode =
        mp.deviceCodes.length === 1 ? mp.deviceCodes[0] : undefined
      deviceCodes = mp.deviceCodes
    }

    const emailValid = !sendCopy || (sendCopy && form.fields.get("email").value !== "");
    const hostName = window.location.origin
    const loginUrl = hostName + "/auth/realms/assari/protocol/openid-connect/auth?client_id=assari&redirect_uri=" + hostName
      + "/kirjaudu&response_type=code&scope=openid+email+profile"

    const loginSuggestion = <span>Kirjautumalla palveluun saat käyttöpaikan ja vesimittarin tiedot automaattisesti. <a href={loginUrl}>Kirjaudu tästä</a></span>
    return (
      <div className="wrapper">
        <h1>Vesimittarin lukema</h1>
        <h5> Täytä alla olevat tiedot ja paina Lähetä-nappia. </h5>
        {!LoginStore.logged && <h5>{loginSuggestion}</h5>}

        {errorMessage && <p className="errortext">{errorMessage}</p>}

        <Form
          getState={this.getFormState}
          setState={this.setFormState}
          className="form register"
          onSubmit={this.handleSubmit}
        >
          <Field
            name="measurementpoint"
            validators={validators.measurementpoint}
            initialValue={mp && mp.id}
          >
            <label
              htmlFor="measurementpoint"
              className="input-wrapper checkbox-input-wrapper"
              invalidClass="invalid"
            >
              <Input
                type="text"
                name="measurementpoint"
                className="form-input checkbox-form-input"
                maxLength={20}
              />
              <span>Käyttöpaikan numero</span>
              <FieldErrors className="errors" />
            </label>
          </Field>

          <Field
            name="meternumber"
            validators={validators.meternumber}
            initialValue={initialDeviceCode}
          >
            <label
              htmlFor="meternumber"
              className="input-wrapper"
              invalidClass="invalid"
            >
              <InputSelect name="meternumber" options={deviceCodes} />
              <span>Vesimittarin numero</span>
              <FieldErrors className="errors" />
            </label>
          </Field>

          <Field name="measurement" validators={validators.measurement}>
            <label
              htmlFor="measurement"
              className="input-wrapper"
              invalidClass="invalid"
            >
              <Input
                type="text"
                name="measurement"
                className="form-input water-input"
                maxLength={5}
              />
              <span>Vesimittarin lukema</span>
              <FieldErrors className="errors" />
            </label>
          </Field>

          <Field name="date">
            <label
              htmlFor="date"
              className="input-wrapper"
              invalidClass="invalid"
            >
              <DatePicker
                dateFormat="DD.MM.YYYY"
                locale="fi"
                maxDate={moment()}
                selected={moment(date)}
                onChange={this.handleChange}
              />
              <span>Lukeman päiväys</span>
              <FieldErrors className="errors" />
            </label>
          </Field>

          <Field name="sendCopy">
            <label
              htmlFor="sendCopy"
              className="input-wrapper checkbox-input-wrapper"
            >
              <span style={this.sendCopySpanStyle}>Lähetä kopio sähköpostiini</span>
              <input
                type="checkbox"
                name="sendCopy"
                className="form-input checkbox-form-input"
                onChange={this.handleSendCopyChange }
              />
            </label>
          </Field>

          <Field name="email" validators={validators.email} hidden={!sendCopy} initialValue={LoginStore.logged ? LoginStore.username : ""}>
            <label
              htmlFor="email"
              className="input-wrapper"
              invalidClass="invalid"
            >
              <Input type="email" name="email" className="form-input" />
              <span>Sähköposti</span>
              <FieldErrors className="errors" />
            </label>
          </Field>

          <div id="recaptcha-container" className="recaptcha-container" hidden={LoginStore.logged}>
            <ReCAPTCHA
              //ref="recaptcha"
              // TODO Make sure this works.
              ref={(el) => React.createRef()} // eslint-disable-line
              sitekey="6LcFx1IUAAAAAPYrWX94ahG36UeRLcGS5ivmLDov"
              onChange={this.onReCaptchaChange}
            />
          </div>

          <input
            type="submit"
            name="submit"
            value="Lähetä"
            disabled={!valid || loading || (!recaptchaSuccess && !LoginStore.logged) || !emailValid}
          />
        </Form>
      </div>
    )
  }
}

const validators = {
  measurementpoint: [
    rules.required('Käyttöpaikan numero on pakollinen'),
    rules.number('Käytä numeroita'),
  ],
  meternumber: [rules.required('Vesimittarin numero on pakollinen')],
  measurement: [
    rules.required('Lukema on pakollinen'),
    rules.number('Käytä numeroita'),
  ],
  email: [rules.email('Sähköpostiosoite on virheellinen')]
}

export default inject('LoginStore')(observer(SubmitWaterMeasurementForm))
