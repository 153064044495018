// @flow
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import LoginStore from '../../../store/LoginStore'
import Loading from '../Shared/Loading'

type Props = {
  LoginStore: typeof LoginStore,
}

type State = {
  errorMessage?: string | null,
  loading: boolean,
  noCustomership: boolean
}

class ConsumerLogin extends Component<Props, State> {
  state = {
    errorMessage: null,
    loading: true,
    noCustomership: false
  }

  componentDidMount = async () => {
    const response = await LoginStore.login('consumer');

    if (!response || response.status === 204) {
      this.setState({
        errorMessage: ' Käyttäjätietoja ei löydetty. Ongelmatilanteen jatkuessa ota yhteyttä asiakaspalveluun.',
        loading: false,
        noCustomership: true
      })
    }
    if (response && response.status === 408) {
      console.log('response status 408: ', response)
      this.setState({
        errorMessage: 'Taustajärjestelmissä huoltokatko, kulutustiedot eivät ole saatavilla. Yritä myöhemmin uudestaan',
        loading: false,
        noCustomership: true
      })
    }
    if (response && response.status === 401) {
      this.setState({
        errorMessage: 'Väärä sähköposti tai salasana',
        loading: false,
        noCustomership: true
      })
    } else if (response && response.status === 403) {
      this.setState({
        errorMessage:
          'Kirjautuminen epäonnistui. Virhe asiakkuustietoja hakiessa. Ota yhteyttä asiakaspalveluun.',
        loading: false,
        noCustomership: true
      })
    } else if (response && response.ok) {
      this.setState({ loading: false })
    }
  }

  render() {

    const { LoginStore } = this.props
    const { loading, errorMessage, noCustomership } = this.state

    if (loading) {
      return <Loading message="Ladataan käyttötietoja..." />
    }

    else if (LoginStore.type === 'consumer')
      return <Redirect to="/dashboard" />

    else if (errorMessage) {
      const hostName = window.location.origin
      const logoutUrl = "/oauth/logout?redirect=" +hostName
      return (
        <React.Fragment>
          <div style={{"height":"30vh", "padding": "3rem"}}>
            {errorMessage}
          { noCustomership && <a style={{"display":"block", "marginTop": "2rem"}} href={logoutUrl}>Kirjaudu ulos</a>}
          </div>
        </React.Fragment>
      )
    }
    else
      return <Redirect to="/forbidden" />
  }
}

export default inject('LoginStore')(observer(ConsumerLogin))
