// @flow
import React, { Component } from 'react'
import * as d3 from 'd3-axis'
import { select as d3Select } from 'd3-selection'
import moment, { MomentInput } from 'moment'
import type { Data, Tick } from './index'

// TODO Determine types, seems to be a third party module function.
type Props = {
  data?: Data,
  orient: string,
  scale: any, // eslint-disable-line
  tick?: Tick,
  tickSize: number,
  translate: string,
  isProductionData?: boolean  //-ADP
}

export default class Axis extends Component<Props> {
  axisElement: Element | string = '';  //add union type -ADP

  componentDidMount(): void {
    this.renderAxis()
  }

  componentDidUpdate(): void {
    this.renderAxis()
  }

  renderAxis(): void {
    const { data, tick, orient, scale, isProductionData } = this.props
    const axisType = `axis${orient}`

    if (data && tick && isProductionData) {
      /* x axis */
      const axis = d3[axisType](scale)  /* seems to still work this way -ADP */
     /*  .scale(scale) */
      .ticks([4])
      .tickFormat(()=> {return ''}) 

      d3Select(this.axisElement as Element).call(axis)
    } 

    else if (data && tick) {
      /* x axis */
      const axis = d3[axisType]()
        .scale(scale)
        .tickPadding([12])
        .ticks([4]) 
        .tickFormat((data:Data) => {
          if (tick.length) {
            return moment(data as MomentInput)
              .format(tick.labelFormat)
              .slice(0, tick.length)
          } else {
            return moment(data as MomentInput).format(tick.labelFormat)
          }
        }) 

      d3Select(this.axisElement as Element).call(axis)
    } 
    
    else {
      /* y axis */
      const axis = d3[axisType]()
        .scale(scale)
        .tickPadding([12])
        .ticks([4])

      d3Select(this.axisElement as Element).call(axis)
    }
  }

  render(): JSX.Element {
    const { orient, translate } = this.props
    return (
      <g
        className={`axis axis-${orient.toLowerCase()}`}
        ref={el => {
          if (el) this.axisElement = el
        }}
        transform={translate}
      />
    )
  }
}
