// @flow
import React from 'react'

// TODO Fix prop type.
const Input: React.FunctionComponent<any> = (props: any) => { // eslint-disable-line
  return (
    <input {...props} />
  );
}

export default Input
