// @flow
import React from 'react'
import B2BRegisterForm from './RegisterB2BForm'
import Loading from './Loading'

const B2BRegister: React.FunctionComponent = () => {
  const [status, setStatus] = React.useState<boolean>()

  // have the state empty (undefined) by default to render the registration form
  if (status === undefined) {
    return (
      <>
        <header className="b2b-register-header">
          <h1>Rekisteröidy yrityksenä tai isännöitsijänä</h1>
          <p>
            Täytä tietosi lomakkeelle, niin lähetämme sinulle
            pääkäyttäjätunnukset, joilla on mahdollista tarkastella kaikkia
            kulutustietoja sekä luoda ja hallinnoida muita käyttäjiä.
            Varmistathan etukäteen, että sinulla on oikeus tehdä ilmoittamasi
            rekisteröitymispyyntö.
          </p>
        </header>
        <B2BRegisterForm submitText="Lähetä" setStatus={setStatus} />
      </>
    )
  } else if (status) {
    return (
      <Loading
        message="Kiitos rekisteröitymispyynnöstäsi!
          Otamme sinuun yhteyttä, kun käyttäjatunnuksesi on valmis
          käytettäväksi."
      />
    )
  } else if (!status) {
    return (
      <Loading
        status={status}
        message="Rekisteröitymispyyntösi lähettäminen ei onnistunut.
          Lataa sivu ja yritä uudelleen."
      />
    )
  }
}

export default B2BRegister
