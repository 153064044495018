// @flow
import React, { Component } from 'react'
import Tupas from '../../Elements/Tupas'

type Props = {
  location: {
    search: string,
  },
}

class ManagerRegister extends Component<Props> {
  render(): JSX.Element {
    const { location } = this.props

    if (
      /(^\?error=cancel)/.test(location.search) ||
      /(^\?error=reject)/.test(location.search)
    ) {
      return (
        <div className="wrapper">
          <h1>Tunnistautuminen keskeytyi</h1>
          <h5>Jatka halutessasi takaisin tunnistautumiseen.</h5>
          <Tupas />
        </div>
      )
    }

    return (
      <div className="wrapper">
        <h1>Valitse pankkisi</h1>
        <Tupas isRegistration={true} />
      </div>
    )
  }
}

export default ManagerRegister
