// @flow
import React, { Component } from 'react'

type Props = {
  message?: string
  status?: boolean
}

class Loading extends Component<Props> {
  render(): JSX.Element {
    const { message, status } = this.props
    return (
      <div className={`wrapper loading${!status ? ' error' : ''}`}>
        <h1>{message || 'Ladataan...'}</h1>
      </div>
    )
  }
}

export default Loading
