// @flow
import React from 'react'

import AnimatedNumber from 'react-animated-number' //TODO add d.ts for untyped imports -ADP

type Props = {
  usageSum: number,
  duration?: number,
  type: string,
  heading: string
}

const AnimatedUsage: React.FunctionComponent<Props> = (props: Props) => {
  const { usageSum, type, duration = 100 , heading} = props
  const step = type === 'water' ? 3 : 0
  if (usageSum !== null && usageSum > 10) {
    return (
      <div className="usage">
        <h3>{heading}</h3>
        <AnimatedNumber
          value={usageSum}
          initialValue={0}
          duration={duration}
          stepPrecision={step}
        />
        <AnimatedNumber
          value={usageSum - 0}
          initialValue={usageSum}
          duration={duration * 2}
          stepPrecision={step}
        />
      </div>
    )
  }

  if (usageSum !== null) {
    return (
      <div className="usage">
         <h3>{heading}</h3>
        <AnimatedNumber
          value={usageSum}
          initialValue={0}
          duration={duration * 2}
          stepPrecision={step}
        />
      </div>
    )
  }

  return (
    <div className="usage">
      <span>–</span>
    </div>
  )
}

export default AnimatedUsage
