// @flow
import React from 'react'
import Axis from './Axis'
import { Scales, Margins, SvgDimensions, Data, Tick } from './index'

type Props = {
  scales: Scales
  margins: Margins
  svgDimensions: SvgDimensions
  unit: string
  location: string
  timeframe: string
  sum?: number
  data: Data
  tick?: Tick
  isProductionData: boolean //ADP
  contractFromId: { address: string }
}

const Axes: React.FunctionComponent<Props> = (props: Props) => {
  const {
    scales,
    margins,
    svgDimensions,
    unit,
    location,
    timeframe,
    sum,
    data,
    tick,
    isProductionData,  //ADP
    contractFromId
  } = props

  const { height, width } = svgDimensions

  const contract = contractFromId
  const isCompany = window.location.pathname.includes('yritys')

  // allow modification for downwards-pointing bars -ADP
  let verticalScale; //ScaleLinear<number, number, never>;
  let xOrient = '';
  let translateYAxis = '';
  let translateXAxis = ''

  
  if (isProductionData) {

   verticalScale = scales.yScale2
   xOrient = 'Top'
   translateYAxis = `translate(${margins.left}, ${margins.bottom * -1})` //test
   translateXAxis = `translate(0, 0)`//test without ${margins.bottom}
  } else { 
    verticalScale = scales.yScale
    xOrient = 'Bottom'
    translateYAxis = `translate(${margins.left}, 0)` 
    translateXAxis = `translate(0, ${height - margins.bottom})` 
  }

  return (
    <g className="graph-axes">

    { !isProductionData && 
    <text className="graph-unit" textAnchor="start" x="10" y="80">
        {unit}
      </text>
      }


      {!isProductionData && !isCompany ? (
        <text
          className="graph-usage-unit"
          textAnchor="middle"
          x={`${width / 2}`}
          y="0"
        >
          {unit}
        </text>
      ) : null}


      {!isProductionData && 
      <text
        className={`graph-period${isCompany ? ' larger-font' : ''}`}
        textAnchor="middle"
        x={`${width / 2}`}
        y="25"
      >
        {timeframe}
      </text>
      }
      {(!isProductionData && !isCompany) ? (

        <text
          className="graph-location"
          textAnchor="middle"
          x={`${width / 2}`}
          y="40"
        >
          {contract ? contract.address : location}
        </text>
      ) : null}


      {!isProductionData && sum && (
        <text className="graph-sum" textAnchor="end" x={`${width / 20}`} y="40">
          {sum} {unit} 
        </text>
      )}
    

      <Axis
        data={data}
        orient={xOrient}
        scale={scales.xScale}
        tick={tick}
        tickSize={height - margins.top - margins.bottom}
        translate={translateXAxis} //ADP 
        isProductionData={isProductionData ? true : false} //ADP
      />
      <Axis
        orient={'Left'}
        scale={verticalScale}
        tickSize={width - margins.left - margins.right}
        translate={translateYAxis} //ADP
      />
    </g>
  )
}

export default Axes