// @flow
import moment from 'moment-timezone'
import { timezone } from '../config/constants'
import type { DataPoint } from '../store/index'

const fillDates = (
  start: string,
  end: string,
  period: string,
  data?: Array<DataPoint>
): Array<DataPoint> | null => {
  // Return data contains only timestamps with datapoints
  // Create array of all dates and combine it with return data
  let timeframe;
  let currentDate;
  let endDate;

  if (!data) return null
  if (period === 'month') {
    timeframe = 'month';
    currentDate = moment(start).tz(timezone).startOf('month');
    endDate = moment(end).tz(timezone).startOf('month');
  } else if (period === 'hour') {
    timeframe = 'hour'
    currentDate = moment(start).tz(timezone).startOf('day');
    endDate = moment(end).tz(timezone).endOf('day');
  } 
    /* ADP */
    else if (period === 'quarter-hour') {
      timeframe = 'minute'
      currentDate = moment(start).tz(timezone).startOf('hour');
      endDate = moment(end).tz(timezone).endOf('hour');
    } 
  
  else {
    timeframe = 'day';
    currentDate = moment(start).tz(timezone).startOf('day');
    endDate = moment(end).tz(timezone).startOf('day');
  }

  const dateArray = []
  let increment = 1;
  if (period === "month") {
    while (currentDate.isSameOrBefore(endDate)) {
      const monthData = data.filter(d => moment(d.ts).tz(timezone).isSame(currentDate, 'month'));
      const totalV = monthData.reduce((sum, record) => sum + record.v, 0);
      const totalVp = monthData.reduce((sum, record) => sum + record.vp, 0);
      dateArray.push({
        ts: currentDate.toISOString(true),
        v: totalV,
        vp: totalVp
      });
      currentDate.add(1, 'month');
  }} else {
    while (currentDate.isSameOrBefore(endDate)) {
      dateArray.push({
        ts: currentDate.toISOString(true),
        v: 0,
        vp: 0 //ADP
      })
      if (timeframe === 'minute'){ increment = 15 }
      currentDate = currentDate.add(increment, timeframe)
    }
  }

  const formattedData: Array<DataPoint> = data.map(d => {
    d.ts = moment(d.ts).tz(timezone).toISOString(true)
    return d
  })

  for (let i = 0; i < dateArray.length; i++) {
    for (let j = 0; j < formattedData.length; j++) {
      if (dateArray[i].ts == formattedData[j].ts) {
        dateArray[i] = formattedData[j]
        break
      }
    }
  }

  return dateArray
}

export default fillDates
