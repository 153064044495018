// @flow
import React, { Component } from 'react'
import { apiUrl } from '../../../config/constants'
import {
  Field,
  FieldErrors,
  Form,
  Input,
  type FormState,
  validators as rules,
} from '../../Form'
import { EMAIL_REGEX } from '../../Form/validators'

type Props = {
  submitText: string,
  onSubmit: (value: FormState|null) => void,
  loading: boolean,
}

type State = {
  form: FormState|null,
  checked: boolean,
}

class SuperuserRegisterForm extends Component<Props, State> {
  state = {
    form: null,
    checked: false,
  }

  // TODO: Gonna need to correctly type this.
  getFormState = (): any => this.state.form // eslint-disable-line

  setFormState = (diff: FormState): Promise<void> =>
    new Promise(resolve => {
      this.setState(
        prevState => {
          const form = prevState.form
          if (!form) {
            return { form: diff }
          } else {
            return {
              form: {
                ...form,
                ...diff,
                fields: form.fields.merge(diff.fields),
              },
            }
          }
        },
        () => resolve()
      )
    })

  toggleApprove = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const item = e.target.name
    const value = !this.state[item]
    this.setState({ [item]: value })
  }

  render(): JSX.Element {
    const { loading } = this.props
    const { form, checked } = this.state
    const valid = form && form.valid
    const { submitText, onSubmit } = this.props

    return (
      <Form
        getState={this.getFormState}
        setState={this.setFormState}
        className="form register"
        onSubmit={() => onSubmit(form, checked)}
      >
        <Field name="firstName" validators={validators.firstName}>
          <label
            htmlFor="firstName"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="text" name="firstName" className="form-input" />
            <span>Etunimi *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="lastName" validators={validators.lastName}>
          <label
            htmlFor="lastName"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="text" name="lastName" className="form-input" />
            <span>Sukunimi *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="phone" validators={validators.phone}>
          <label htmlFor="tel" className="input-wrapper" invalidClass="invalid">
            <Input type="tel" name="tel" className="form-input" />
            <span>Puhelin </span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="email" validators={validators.email}>
          <label
            htmlFor="email"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="email" name="email" className="form-input" />
            <span>Sähköposti *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <div className="input-wrapper checkbox">
          <label>
            <input
              type="checkbox"
              name="checked"
              onChange={this.toggleApprove}
              style={{ display: 'none' }}
            />
            <div className="checkbox-check" />
            Vakuutan, että tämä henkilö on isännöintitoimistomme työntekijä
          </label>
        </div>

        <input
          className={loading ? 'loading' : ''}
          type="submit"
          name="submit"
          value={submitText}
          disabled={!valid}
        />
      </Form>
    )
  }
}

const emailExists = (error: string) => ({
  validate: async (value: string) => {
    if (!value || !EMAIL_REGEX.test(value)) return null

    try {
      const response = await fetch(`${apiUrl}/user/exists/?email=${value}`, {
        method: 'HEAD',
        mode: 'cors',
      })
      if (response.status === 200) {
        // console.log('Email address exists')
        return error
      } else if (response.status === 404) {
        // console.log('Email address free')
        return null
      } else {
        // console.log('Error checking email address availability')
        return null
      }
    } catch (err) {
      // console.log('Error checking email address availability')
      throw err
    }
  },
  trigger: 'blur',
})

const validators = {
  firstName: [
    rules.required('Etunimi on pakollinen'),
    rules.minLength(1, 'Etunimi on liian lyhyt'),
  ],
  lastName: [
    rules.required('Sukunimi on pakollinen'),
    rules.minLength(1, 'Sukunimi on liian lyhyt'),
  ],
  phone: [rules.phone('Lisää puhelinnumero muodossa +358123456789')],
  email: [
    rules.required('Sähköposti on pakollinen'),
    rules.email('Sähköpostiosoite on virheellinen'),
    emailExists('Sähköpostiosoite on jo olemassa'),
  ],
}

export default SuperuserRegisterForm
