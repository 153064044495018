// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import LoginStore from '../../store/LoginStore'
import { logger } from '../../config/constants'
import NavLinks from './NavLinks'

import styled from 'styled-components'

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: center;
  right: 0;
`

type Props = {
  LoginStore: typeof LoginStore,
}


class Nav extends Component<Props> {

  constructor(props: Props) {
    super(props)
    this.logout = this.logout.bind(this)
  }
  async logout() {
    const { LoginStore } = this.props
    const properties = {
      eventType: 'LOGOUT_SUCCESS',
    }
    logger.trackEvent('User have logged out', properties)
    await LoginStore.logout();
  }

  

  render() {
    const { LoginStore } = this.props
    const isannointiohjeURL = LoginStore.getValue("isannointiohje.pdf");
    const name = `${LoginStore.firstName} ${LoginStore.lastName}`;
    const isMobile = window.innerWidth <= 768

    const navLinksProps = {
      isMobile,
      name,
      role: LoginStore.type,
      logged: LoginStore.logged,
      logout: this.logout,
      isannointiohjeURL
    }
    return (
      <HeaderWrapper>
        <NavLinks {...navLinksProps}/>
      </HeaderWrapper>
    )
  }
}

export default inject('LoginStore')(observer(Nav))
