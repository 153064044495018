import axios from 'axios';

import { apiUrl } from '../config/constants';

// Idea taken from https://alligator.io/react/axios-react/
const API = axios.create({
  baseURL: apiUrl,
});

export default API;
