import React, { SetStateAction, useEffect } from 'react';

// Images
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/icon-warning.svg';
// Actions
import { removeCompanyUser, addOrEditCompanyUser } from '../../../actions/b2bUsers';
// Types
import { Customerships, FormData } from '../../../types/companies';
// Misc
import { initialFormState } from './CompanyDashboard';


const Modal = ({
  action,
  companies,
  handleModalOpening,
  handleFormChange,
  handleSubmit,
  personsData,
  setPersonsData,
  personIndex,
  formData,
  setFormData,
}: {
  action: string
  companies: Customerships[]|undefined
  handleModalOpening: () => void
  handleFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  personsData: FormData[]
  setPersonsData: React.Dispatch<SetStateAction<FormData[]>>
  personIndex: number
  formData: FormData
  setFormData: React.Dispatch<SetStateAction<FormData>>
}): JSX.Element => {
  // set the data of clicked person to a variable
  const personToEdit = personsData[personIndex];

  // when the edit person modal opens, initialize the form state with the current data the card has (personToEdit variable)
  useEffect(() => {
    action === 'edit' &&
      setFormData({
        firstName: personToEdit.firstName,
        lastName: personToEdit.lastName,
        email: personToEdit.email,
        companies: personToEdit.companies,
      });
  }, [action])

  // gather updated person info to `updatedPerson` through `formData` state and update the personsData array state with the edited person + close modal and reset form
  const handleEditPerson = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const person = personsData.filter((_, idx) => idx === personIndex);
    const updatedPerson = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      companies: formData.companies,
    }
    const userUpdated = await addOrEditCompanyUser(updatedPerson, false, person[0].email);

    if (userUpdated) {
      const newPersonsData = [...personsData]
      newPersonsData[personIndex] = updatedPerson
      setPersonsData(newPersonsData)
      handleModalOpening()
      setFormData(initialFormState)
    }
  }
  // deletes the correct person from the array of persons and closes the modal
  const handleDeletePerson = async (): Promise<void> => {
    const person = personsData.filter((_, idx) => idx === personIndex);
    const removed = await removeCompanyUser(person[0].email);

    if (removed) {
      setPersonsData(personsData.filter((_, idx) => idx !== personIndex))
      handleModalOpening()
    }
  }

  return (
    <div className="modal-wrapper">
      <div className="modal">
        <button className="action" onClick={handleModalOpening}>
          Sulje
          <CloseIcon />
        </button>
        <div className="modal-content">
          {action !== 'delete' && (
            <>
              <h2>
                {action === 'edit' ? (
                  <>Muokkaa henkilöä</>
                ) : (
                  <>Täytä uuden henkilön tiedot</>
                )}
              </h2>
              <form
                className="form"
                onSubmit={action === 'edit' ? handleEditPerson : handleSubmit}
              >
                <label htmlFor="firstname" className="input-wrapper">
                  Etunimi *
                  <input
                    type="text"
                    name="firstName"
                    className="form-input"
                    onChange={handleFormChange}
                    defaultValue={
                      action === 'edit' ? personToEdit.firstName : ''
                    }
                    required
                  />
                </label>
                <label htmlFor="lastname" className="input-wrapper">
                  Sukunimi *
                  <input
                    type="text"
                    name="lastName"
                    className="form-input"
                    onChange={handleFormChange}
                    defaultValue={
                      action === 'edit' ? personToEdit.lastName : ''
                    }
                    required
                  />
                </label>
                <label htmlFor="email" className="input-wrapper">
                  Sähköpostiosoite *
                  <input
                    type="text"
                    name="email"
                    className="form-input"
                    onChange={handleFormChange}
                    defaultValue={action === 'edit' ? personToEdit.email : ''}
                    required
                  />
                </label>
                <div className="checkboxes">
                  <p>Yritykset</p>
                  {companies && companies.map((company) => {
                    if (company) {
                      return (
                        <label
                          key={company.customerName}
                          htmlFor="company"
                          className="input-wrapper"
                        >
                          <input
                            type="checkbox"
                            name="company"
                            value={company.customerName}
                            className="form-input"
                            onChange={handleFormChange}
                            defaultChecked={
                              action === 'edit'
                                ? personToEdit.companies.find(
                                    item => item.companyName === company.customerName
                                  )
                                  ? true
                                  : false
                                : false
                            }
                          />
                          {company.customerName}
                        </label>
                      )
                    }
                  })}
                </div>
                <button type="submit" className="submit">
                  {action === 'edit' ? <>Tallenna</> : <>Lisää henkilö</>}
                </button>
              </form>
            </>
          )}

          {action === 'delete' &&
            personsData[personIndex] &&
            personsData.length && (
            <>
              <WarningIcon />
              <h2>Henkilön poistaminen</h2>
              <p>
                Vahvista henkilön{' '}
                <span className="name-of-deleted-person">
                  {`${personsData[personIndex].firstName} ${personsData[personIndex].lastName}`}
                </span>{' '}
                poistaminen.
              </p>
              <div className="actions">
                <button className="submit cancel" onClick={handleModalOpening}>
                  <span>Peruuta</span>
                </button>
                <button className="submit confirm" onClick={handleDeletePerson}>
                  <span>Poista</span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
