// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Products from './Dashboard/Products';
import MissingProduct from '../../Elements/MissingProduct';
import ElectricityIcon from '../../../assets/images/icon-electricity.svg';
import WaterIcon from '../../../assets/images/icon-water.svg';
import HeatIcon from '../../../assets/images/icon-heat.svg';
import FiberIcon from '../../../assets/images/icon-fiber.svg';
import ContractsIcon from '../../../assets/images/NEVE_Sopimukseni.png';
import InvoicesIcon from '../../../assets/images/NEVE_Laskut.png';
import Loading from '../Shared/Loading';
import pluralize from '../../../utils/pluralize';
import LinkBox from './Dashboard/LinkBox';

//Types
import { Props, State } from '../../../types/common';
import { Contract } from '../../../store/index'; /* ADP */

import { themeDetails } from '../../../theme/theme.ts';

class Dashboard extends Component<Props, State> {
  constructor() {
    super()
    this.state = {
      districtheatContracts: [],
      electricityContracts: [],
      waterContracts: [],
      errorMessage: '',
      hasData: true,
      loading: false,
      fibreAddressesByType: {},
    }
  }
  componentDidUpdate(): void {
    window.scrollTo(0, 0)
  }

  // TODO Determine type.
  componentDidMount = async (): Promise<any> => { // eslint-disable-line
    const { LoginStore } = this.props

    if (!LoginStore.logged) {
      await LoginStore.login('consumer', true)
    }

    const fibreAddressesByType = LoginStore?.fibreAvailableList?.reduce(
      (addressesByType, currentAddress) => {
        const currentAddressType = currentAddress.type
        if (addressesByType.hasOwnProperty(currentAddressType)) {
          addressesByType[currentAddressType].push(currentAddress.address)
        } else {
          addressesByType[currentAddressType] = [currentAddress.address]
        }
        return addressesByType
      },
      {}
    )

    const contracts = LoginStore.customerData[0].contracts;
    // Group contracts by usage type
    const electricity: Contract[] = []
    const districtheat: Contract[] = [] //CHECK -ADP
    const water: Contract[] = [] //CHECK -ADP

    contracts?.forEach(contract => {
      if (contract.contractType === 'Sähköverkkosopimus' || contract.contractType === 'Sähköntuotannon verkkosopimus') {
        electricity.push(contract)
      } else if (contract.contractType === 'Kaukolämmön myyntisopimus') {
        districtheat.push(contract)
      } else if (contract.contractType === 'water') {
        water.push(contract)
      }
    })
    this.setState({
      electricityContracts: electricity,
      districtheatContracts: districtheat,
      waterContracts: water,
      hasData: true,
      fibreAddressesByType,
    })
  }

  render() {
    const {
      hasData,
      electricityContracts,
      waterContracts,
      districtheatContracts,
      errorMessage,
      loading,
      fibreAddressesByType,
    } = this.state

    const { LoginStore } = this.props

    const { services, incomingServices } = themeDetails

    const isProvidedService = (service: string): boolean => { return services.indexOf(service) > -1 }
    const isIncomingService = (service: string): boolean => incomingServices.indexOf(service) > -1

    if (loading || !LoginStore.customerData) {  /* -ADP */
      return <Loading message="Ladataan käyttötietoja..." />
    }

    const infoboksi = LoginStore.getTemplate('infoboksi.html')

    return (
      <div className="wrapper dashboard container">
        <div className="products col-md-6">
          <h1 className="dashboardHeader">Kulutustietoni</h1>

          {errorMessage && <p className="errortext">{errorMessage}</p>}



          {(isIncomingService('electricity') ||
            (
              electricityContracts &&
              electricityContracts.length > 0)) && (
              <Products
                name={'Sähkö'}
                type={'electricity'}
                pathname={'sahko'}
                icon={ElectricityIcon}
                contracts={electricityContracts as Contract[]}
                duration={600}
                isIncoming={isIncomingService('electricity')}
              />
            )}

          {(isIncomingService('districtheat') ||
            districtheatContracts &&
            districtheatContracts.length > 0) && (
              <Products
                name={'Kaukolämpö'}
                type={'districtheat'}
                pathname={'kaukolampo'}
                icon={HeatIcon}
                contracts={districtheatContracts as Contract[]}
                duration={800}
                isIncoming={isIncomingService('districtheat')}
              />
            )}

          {(isIncomingService('water') ||
            (isProvidedService('water') &&
              waterContracts &&
              waterContracts.length > 0)) && (
              <Products
                name={'Vesi'}
                type={'water'}
                pathname={'vesi'}
                icon={WaterIcon}
                contracts={waterContracts as Contract[]}
                duration={700}
                isIncoming={isIncomingService('water')}
                isIncomingMessage="Veden kulutustiedot tulossa palveluun myöhemmin"
              />
            )}

          {!hasData && (
            <MissingProduct
              name={'Ei sopimustietoja'}
              productClass={'missing'}
            />
          )}

          {infoboksi && (
            <div
              className="missing"
              dangerouslySetInnerHTML={{ __html: infoboksi }}
            />
          )}
        </div>
        <div className="services col-md-6">
          {services.some(
            (service: string) => ['billingInfo', 'contractInfo'].indexOf(service) > -1
          ) && <h1 className="dashboardHeader">Asiakkuuteni</h1>}

          {isProvidedService('billingInfo') && (
            <LinkBox
              name={'Laskuni'}
              productClass={'invoices'}
              icon={InvoicesIcon}
              link={'/laskut'}
            />
          )}

          {isProvidedService('contractInfo') && (
            <LinkBox
              name={'Käyttöpaikan tiedot'}
              productClass={'contracts'}
              icon={ContractsIcon}
              link={'/sopimukset'}
            />
          )}
          <LinkBox
            name={'Laskutiedot'}
            productClass={'contracts'}
            icon={ContractsIcon}
            link={'/laskutustiedot'}
          />

          {isProvidedService('fibre') && (
            <>
              {Object.keys(fibreAddressesByType).length === 0 &&
                LoginStore.getTemplate(`fiber0.html`) && (
                  <div
                    className="fiberad fiberad-default"
                    dangerouslySetInnerHTML={{
                      __html: LoginStore.getTemplate(`fiber0.html`, {
                        $FIBER_IMG_SRC: FiberIcon,
                      }),
                    }}
                  ></div>
                )}
              {Object.keys(fibreAddressesByType).map(
                (availabilityType, index) => {
                  const addressesOfType = fibreAddressesByType[availabilityType]
                  const fiberAdTemplate = LoginStore.getTemplate(
                    `fiber${availabilityType}.html`,
                    {
                      $FIBER_AVAILABLE_ADDRESSES: addressesOfType
                        .map(
                          (address: string, index: number) =>
                            `<span><b>${address}</b>${index < addressesOfType.length - 1 ? ',' : ''
                            } </span>`
                        )
                        .join(''),
                      $FIBER_IMG_SRC: FiberIcon,
                    }
                  )
                  if (fiberAdTemplate) {
                    const textContent = pluralize(
                      fiberAdTemplate,
                      addressesOfType.length
                    )
                    return (
                      <div
                        className="fiberad"
                        key={index}
                        dangerouslySetInnerHTML={{ __html: textContent }}
                      ></div>
                    )
                  } else {
                    return null
                  }
                }
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}

export default inject('LoginStore')(observer(Dashboard))
