// @flow
import type { Customer, Contract, Customerships } from '../store/index'

export const getContracts = (customerData: Customerships[]): Array<Contract> | null => {
  const clients = customerData

  if (!clients || clients.length === 0) {
    return null
  }
  const contracts: Contract[] = []
  clients.map(client => {
    return client.customerships.map(customership => {
      return customership.contracts.map(contract => {
        contract.customer = updateCustomer(customership.customer)
        contracts.push(contract)
        return undefined
      })
    })
  })

  // Sort contracts by id
  contracts.sort((a, b) => {
    return a.mp.address.street > b.mp.address.street ? 1 : b.mp.address.street > a.mp.address.street ? -1 : 0
  })

  return contracts
}

// Combine consumer firstName and lastName to work consistently with manager users
const updateCustomer = (customer: Customer): Customer => {
  if (
    typeof customer.firstName !== 'undefined' &&
    typeof customer.lastName !== 'undefined'
  ) {
    customer.name = `${customer.firstName} ${customer.lastName}`

    delete customer.firstName
    delete customer.lastName
  }
  return customer
}
