// @flow
import React, { Component } from 'react'
import { apiUrl } from '../../../config/constants'
import {
  Field,
  FieldErrors,
  Form,
  Input,
  type FormState,
  validators as rules,
} from '../../Form'
import { EMAIL_REGEX } from '../../Form/validators'

type Props = {
  submitText: string,
  onSubmit: (value: FormState|null) => void,
  loading: boolean,
}

type State = {
  form: FormState|null,
}

class UserRegisterForm extends Component<Props, State> {
  state = {
    form: null,
  }


  // TODO Determine correct type, I doubt it will always be null.
  getFormState = (): any => this.state.form // eslint-disable-line

  setFormState = (diff: FormState): Promise<void> =>
    new Promise(resolve => {
      this.setState(
        prevState => {
          const form = prevState.form
          if (!form) {
            return { form: diff }
          } else {
            return {
              form: {
                ...form,
                ...diff,
                fields: form.fields.merge(diff.fields),
              },
            }
          }
        },
        () => resolve()
      )
    })

  // TODO Fix typing issues.
  render(): JSX.Element {
    const { loading } = this.props
    const { form } = this.state
    const valid = form && form.valid
    const { submitText, onSubmit } = this.props

    return (
      <Form
        getState={this.getFormState}
        setState={this.setFormState}
        className="form register"
        onSubmit={() => onSubmit(form)}
      >
        <Field name="firstName" validators={validators.firstName}>
          <label
            htmlFor="firstName"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="text" name="firstName" className="form-input" />
            <span>Etunimi *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="lastName" validators={validators.lastName}>
          <label
            htmlFor="lastName"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="text" name="lastName" className="form-input" />
            <span>Sukunimi *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="phone" validators={validators.phone}>
          <label htmlFor="tel" className="input-wrapper" invalidClass="invalid">
            <Input type="tel" name="tel" className="form-input" />
            <span>Puhelin *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="email" validators={validators.email}>
          <label
            htmlFor="email"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="email" name="email" className="form-input" />
            <span>Sähköposti *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="password" validators={validators.password1}>
          <label
            htmlFor="password"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="password" name="password" className="form-input" />
            <span>Luo salasana *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <Field name="password2" validators={validators.password2}>
          <label
            htmlFor="password2"
            className="input-wrapper"
            invalidClass="invalid"
          >
            <Input type="password" name="password2" className="form-input" />
            <span>Toista salasana *</span>
            <FieldErrors className="errors" />
          </label>
        </Field>

        <input
          className={loading ? 'loading' : ''}
          type="submit"
          name="submit"
          value={submitText}
          disabled={!valid}
        />
      </Form>
    )
  }
}

const emailExists = (error: string) => ({
  validate: async value => {
    if (!value || !EMAIL_REGEX.test(value)) return null

    try {
      const response = await fetch(`${apiUrl}/user/exists/?email=${value}`, {
        method: 'HEAD',
        mode: 'cors',
      })
      if (response.status === 200) {
        // console.log('Email address exists')
        return error
      } else if (response.status === 404) {
        // console.log('Email address free')
        return null
      } else {
        // console.log('Error checking email address availability')
        return null
      }
    } catch (err) {
      throw err
    }
  },
  trigger: 'blur',
})

const validators = {
  firstName: [
    rules.required('Etunimi on pakollinen'),
    rules.minLength(1, 'Etunimi on liian lyhyt'),
  ],
  lastName: [
    rules.required('Sukunimi on pakollinen'),
    rules.minLength(1, 'Sukunimi on liian lyhyt'),
  ],
  email: [
    rules.required('Sähköposti on pakollinen'),
    rules.email('Sähköpostiosoite on virheellinen'),
    emailExists('Sähköpostiosoite on jo olemassa'),
  ],
  phone: [
    rules.required('Puhelinnumero on pakollinen'),
    rules.phone('Lisää puhelinnumero muodossa +358123456789'),
  ],
  password1: [
    rules.required('Salasana on pakollinen'),
    rules.minLength(8, 'Salasanan on oltava vähintään 8 merkkiä pitkä'),
    rules.password(2, 'Salasana on liian yksinkertainen'),
  ],
  password2: [
    rules.required('Kenttä on pakollinen'),
    rules.samesAs('password', 'Salasanat eivät ole samat'),
  ],
}

export default UserRegisterForm
