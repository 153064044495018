// @flow
import React from 'react'

const ConsumerRegisterSuccess: React.FunctionComponent = () => {
  const hostName = window.location.origin
  const consumerDashboardUrl = `${hostName}/auth/realms/assari/protocol/openid-connect/auth?client_id=assari&redirect_uri=${hostName}/kirjaudu&response_type=code&scope=openid+email+profile`;
    // hostName +
    // '/auth/realms/assari/protocol/openid-connect/auth?client_id=assari&redirect_uri=' +
    // hostName +
    // '/kirjaudu&response_type=code&scope=openid+email+profile'

  return (
    <div className="wrapper">
      <h1>Tervetuloa Palveluun!</h1>
      <h5>
        Täältä löydät kaikki asiakkuuteesi liittyvät asiat yhdestä paikasta.
      </h5>
      <a href={consumerDashboardUrl}>
        <button className="submit">Astu sisään</button>
      </a>
    </div>
  )
}

export default ConsumerRegisterSuccess
