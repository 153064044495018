// @flow
import * as React from 'react'

type InputEvent = SyntheticInputEvent<HTMLInputElement>

type Props = {
  value?: string,
  name: string,
  options: string[],
  onBlur?: (event: InputEvent) => mixed,
  onChange?: (event: InputEvent) => mixed,
}

type State = {
  value: string,
  focus: boolean,
}

class InputSelect extends React.Component<Props, State> {
  state = {
    value: this.props.value || '',
    focus: false,
  }

  onChange = (e: InputEvent): void => {
    this.setState({ value: e.target.value })
    return this.props.onChange && this.props.onChange(e)
  }

  onSelect = (e: InputEvent, v: string): void => {
    this.setState({ value: v })
    e.currentTarget.value = v
    return this.props.onChange && this.props.onChange(e)
  }
  onBlur = (e: InputEvent): void => {
    this.setState({ focus: false })
    return this.props.onBlur && this.props.onBlur(e)
  }

  render(): JSX.Element {
    const { value, focus } = this.state
    const { options, name } = this.props
    return (
      <div className="input-select form-field">
        <input
          // ref="input"
          ref = { () => React.useRef()} // TODO Make sure this works.
          id={Math.random()}
          name={name}
          className="input form-input"
          type="text"
          value={this.state.value}
          onChange={this.onChange}
          onFocus={() => this.setState({ focus: true })}
          onBlur={this.onBlur}
          autoComplete="off"
        />
        <div className={'selector ' + (!value && focus ? '' : ' hidden')}>
          {options &&
            options.map(o => (
              <div
                key={o}
                className="option"
                onClick={(e: InputEvent) => this.onSelect(e, o)}
              >
                {o}
              </div>
            ))}
        </div>
      </div>
    )
  }
}

export default InputSelect
