// @flow
import React, { Component } from 'react'
import RegisterManagerForm from '../Shared/RegisterManagerForm'
import Conditions from '../../Elements/Conditions'
import { apiUrl } from '../../../config/constants'
import { Redirect } from 'react-router-dom'

type Props = {
  id: string,
  match: any, // eslint-disable-line
}

type State = {
  confirming: boolean,
  terms: string,
  privacy: string,
  conditionsApproved: boolean,
  formState: number,
  exists: boolean,
  success: boolean,
  errorMessage?: string,
  loading: boolean,
}

class ManagerCreatePassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { id } =
      this.props.match.params !== undefined
        ? this.props.match.params
        : this.props
    this.state = {
      id: id,
      confirming: true,
      terms: '',
      privacy: '',
      conditionsApproved: false,
      formState: 0,
      exists: false,
      success: false,
      errorMessage: null,
      loading: false,
    }
  }

  checkConditions = (type?: string): void => {
    let { terms, privacy } = this.state
    if (!type) {
      terms = localStorage.getItem('terms')
      privacy = localStorage.getItem('privacy')
    }

    const value = type || 'conditionsApproved'
    if (terms === 'approved' && privacy === 'approved') {
      this.setState({ [value]: true })
    } else {
      this.setState({ [value]: false })
    }
  }

  toggleApprove = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const item = e.target.name
    const value = this.state[item] === 'approved' ? 'declined' : 'approved'
    this.setState({ [item]: value }, () =>
      this.checkConditions('submitEnabled')
    )
  }

  onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    localStorage.setItem('terms', this.state.terms)
    localStorage.setItem('privacy', this.state.privacy)
    e.preventDefault()
    this.checkConditions()
  }

  handleSubmit = async (form: FormState): Promise<void> => {
    const { id, conditionsApproved } = this.state

    this.setState({ loading: true })

    if (!form || !form.valid) {
      // console.log('No form data or invalid form')
      this.setState({ loading: false })
      return
    } else if (!conditionsApproved) {
      // console.log('Condition not approved')
      this.setState({ loading: false })
      return
    }

    const formData = form.fields
      .mapEntries(([field, data]) => [field, data.value])
      .toJS()

    const body = {
      password: formData.password,
      token: id,
      termsAccepted: true,
      dataProtectionPlanAccepted: true,
    }

    const req = new Request(`${apiUrl}/user/password/setNew`, {
      body: JSON.stringify(body),
      method: 'PATCH',
      mode: 'cors',
    })
    let response
    try {
      response = await fetch(req)
      if (response.status === 404) {
        this.setState({ errorMessage: 'Käyttäjää ei löytynyt', loading: false })
      } else if (response.ok) {
        this.setState({ success: true, loading: false })
      } else {
        this.setState({
          errorMessage: 'Yhteysvirhe salasanaa luodessa',
          loading: false,
        })
      }
    } catch (error) {
      throw error
    }
  }

  render(): JSX.Element {
    const {
      conditionsApproved,
      submitEnabled,
      loading,
      success,
      errorMessage,
    } = this.state

    if (success)
      return <Redirect to="/isannointi/rekisteroidy/success" push={true} />
    return conditionsApproved ? (
      <div className="wrapper">
        <h1>Salasana</h1>
        {errorMessage && <p className="errortext">{errorMessage}</p>}
        <RegisterManagerForm
          onSubmit={this.handleSubmit}
          submitText={loading ? 'Lataa..' : 'Uusi salasana'}
          loading={loading}
        />
      </div>
    ) : (
      <div className="wrapper">
        <h1>Palvelun käyttöehdot ja tietosuojaseloste</h1>
        <Conditions
          onChange={this.toggleApprove}
          onSubmit={this.onSubmit}
          submitEnabled={submitEnabled}
        />
      </div>
    )
  }
}

export default ManagerCreatePassword
