// @flow
import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { themeDetails } from '../../theme/theme.ts'

type Props = {
  logged: boolean
  role: string
  name: string
  isMobile: boolean
  isannointiohjeURL: string
  logout: () => void
}

const NavLinkList: React.FunctionComponent<Props> = (props: Props) => {
  const { logged, role, isMobile, isannointiohjeURL, logout } = props

  const { services } = themeDetails

  let frontpageURL = '/dashboard'
  if (logged && role === 'remUser') {
    frontpageURL = '/isannointi/dashboard'
  } else if (logged && role === 'remCompany') {
    frontpageURL = '/paakayttaja/dashboard'
  } else if ((logged && role === 'companyUser') || role === 'companyManager') {
    frontpageURL = '/yritys/yleisnakyma'
  } else if (!logged) {
    frontpageURL = '/'
  }

  return (
    <ul>
      <HeaderLink>
        <NavLink to={frontpageURL}>Etusivu</NavLink>
      </HeaderLink>

      {isMobile && services.indexOf('water') > -1 && (
        <HeaderLink>
          <NavLink to="/vesilukema" activeClassName="active">
            Ilmoita vesilukema
          </NavLink>
        </HeaderLink>
      )}

      {logged && (
        <HeaderLink>
          <NavLink to="/feedback">Ota yhteyttä</NavLink>
        </HeaderLink>
      )}
      {logged && role === 'consumer' && (
        <HeaderLink>
          <NavLink to="/yhteystiedot">Yhteystietojen muutos</NavLink>
        </HeaderLink>
      )}
      {logged && ['remCompany', 'remUser'].indexOf(role) !== -1 && (
        <HeaderLink>
          <a href={isannointiohjeURL} target="_blank" rel="noreferrer" download>
            Käyttöohje
          </a>
        </HeaderLink>
      )}
      {logged && (
        <HeaderLink>
          <NavLink
            to="/"
            onClick={async (): Promise<void> => {
              await logout()
            }}
          >
            Kirjaudu ulos
          </NavLink>
        </HeaderLink>
      )}
    </ul>
  )
}

export const HeaderLink = styled.li`
  font-size: 20px;
  font-weight: 100;
  padding: 12px 0;
  & > a {
    color: ${props => props.theme['nav-color'] || '#000'};
  }
  & > a:hover {
    color: ${props => props.theme['nav-color:hover'] || '#000'};
    text-decoration: underline;
  }
`

export const HeaderLinkList = styled.ul`
  margin-top: 9px;
`

export default NavLinkList
