// @flow
import React from 'react'
import { List } from 'immutable'

import { Input, FieldErrors, type Validator } from './'
import InputSelect from '../Views/Consumer/InputSelect'

// TODO Test to see if this works. The code thinks the events are strings but this seems weird.
export type Props = {
  children: any, // eslint-disable-line
  errors: List<string>,
  name: string,
  onBlur: (
    field: string,
    event: string
  ) => Promise<void>,
  onChange: (
    field: string,
    event: string
  ) => Promise<void>,
  validators?: Validator[],
  value: string,
  initialValue?: string,
}

type FormProps = {
  value?: string;
  onChange?: (
    field: string,
    event: string
  ) => Promise<void>;
  onBlur?: (
    field: string,
    event: string
  ) => Promise<void>,
  className?: any; // eslint-disable-line
  errors?: List<string>;
}

const Field: React.FunctionComponent<Props> = (props: Props) => {
  const {
    children,
    errors,
    name,
    onBlur,
    onChange,
    //validators,
    value,
    //initialValue,
    ...other
  } = props

  const addFormProps = (children: any) => { // eslint-disable-line
    return React.Children.map(children, child => {
      if (!child.props) {
        return child
      }
      const { children, invalidClass, ...other } = child.props
      const enhancedChildren = children ? addFormProps(children) : null

      const formProps: FormProps = {}
      if (child.type === Input) {
        formProps.value = value
        formProps.onChange = (e) => onChange(name, e)
        formProps.onBlur = (e) => onBlur(name, e)
      }

      if (child.type === InputSelect) {
        formProps.value = value
        formProps.onChange = (e) => onChange(name, e)
        formProps.onBlur = (e) => onBlur(name, e)
      }

      if (child.type === FieldErrors && errors) {
        formProps.errors = errors
      }

      if (invalidClass && errors && errors.size !== 0) {
        formProps.className = [other.className, invalidClass]
          .filter(_ => _)
          .join(' ')
      }

      return (
        <child.type {...other} {...formProps}>
          {enhancedChildren}
        </child.type>
      )
    })
  }

  return (
    <div className="form-field" {...other}>
      {addFormProps(children)}
    </div>
  )
}

Field.defaultProps = {
  value: '',
  errors: List(),
  onChange: () => Promise.resolve(undefined),
  onBlur: () => Promise.resolve(undefined),
}

export default Field
