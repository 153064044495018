export const themeDetails = {
  company: 'Koillis-Lapin Sähkö Oy',
  brandName: 'Sähkö Online',
  theme: 'kls',
  services: ['electricity', 'contractInfo'],
  incomingServices: [],
  customerServiceEmail: 'laskutus@koillislapinsahko.fi',
  feedbackEmail: 'laskutus@koillislapinsahko.fi',
}


/* added property to control drillable value -ADP */
export const Options = {
  quarterlyDataAllowed: false, 
}

export const styledComponentsThemes = {
  default: {
    'nav-color': '#005685',
    'inline-nav-color': '#005685',
    'nav-color:hover': '#009fe3',
  },
  inverted: {
    'nav-color': '#fff',
    'inline-nav-color': '#fff',
    'nav-color:hover': '#fff',
  },
}

export const textStrings = {
  home: {
    introduction: 'Tervetuloa Online-palveluumme!',
    subHeader:
      'Täältä löydät ajankohtaiset tiedot liittyen energiankulutukseesi.',
  },
}

export const footerContent = {
  contactInfo: [
    {
      icon: 'fa fa-map-marker-alt',
      type: 'address',
      href: '',
      title: 'Koillis-Lapin Sähkö Oy - Y-tunnus: 1680113-6',
      content: ['Annaliisankatu 4,', '98100 Kemijärvi'],
    },
    {
      icon: 'fa fa-envelope',
      type: 'mixed',
      href: 'tel:0408250064',
      href2: 'mailto:laskutus@koillislapinsahko.fi',
      title: 'Asiakaspalvelu',
      content: ['puh. 040 825 0064', 'laskutus@koillislapinsahko.fi'],
    },
  ],
  faultReports: {
    contacts: [
      {
        href: 'tel:016821896',
        text: 'puh. ',
        linkText: '016 821 896',
      },
    ],
  },
  website: 'https://www.koillislapinsahko.fi',
}
