import API from './Api'
import { AxiosResponse } from 'axios'
import { BusinessRegistration } from '../store'
import { Company, CompanyUser, FormData, FormCompany } from '../types/companies'

const headers = {
  'Content-Type': 'application/json',
  'cache-control': 'no-store',
}

export const handleB2BRegistration = async (
  body: BusinessRegistration
): Promise<boolean> => {
  const applyingCredsFor =
    body.applyingCreds === 'business'
      ? 'yritykselle'
      : body.applyingCreds === 'manager'
      ? 'isännöintitoimistolle'
      : null

  const adjustedBody = JSON.stringify({
    ...body,
    applyingCreds: applyingCredsFor,
  })

  const response: AxiosResponse<boolean> = await API({
    headers: headers,
    data: adjustedBody,
    method: 'post',
    url: '/business/register',
  })

  return response.status === 200
}

export const getCompanyUserDetails = async (): Promise<Company> => {
  const userDetails: AxiosResponse<Company> = await API({
    method: 'get',
    url: `/user/details/company`,
  })
  const company = userDetails.data

  return company
}

export const addOrEditCompanyUser = async (
  body: FormData,
  isNew: boolean,
  identifyingEmail = ''
): Promise<boolean> => {
  const axiosAction = isNew ? 'post' : 'patch'
  const response: AxiosResponse<string> = await API({
    headers: headers,
    data: buildBody(body, identifyingEmail),
    method: axiosAction,
    url: '/business/companyUser',
  })

  return response.status === 200
}

export const removeCompanyUser = async (email: string): Promise<boolean> => {
  const response: AxiosResponse<string> = await API({
    headers: headers,
    data: { email },
    method: 'delete',
    url: '/business/companyUser',
  })

  return response.status === 200
}

const buildBody = (body: FormData, identifyingEmail = '') => {
  const data: CompanyUser = {
    firstName: body.firstName,
    lastName: body.lastName,
    email: body.email,
    companies: getBusinessIds(body.companies),
  }

  if (identifyingEmail !== '') {
    data.identifier = identifyingEmail
  }

  return data
}

/**
 * Gets the company ids from the company object.
 *
 * @param companies FormCompany
 * @returns An array of strings
 */
const getBusinessIds = (companies: FormCompany[]): string[] => {
  const companyArray: string[] = []
  companies.map(company => {
    companyArray.push(company.businessId)
  })

  return companyArray
}
