
// @flow
import React from 'react'
import styled from 'styled-components'
import ProductTypesService from '../../../Services/ProductTypesService'
//import ProductTabView from '../../Elements/ProductTabView'
import Loading from '../../Shared/Loading'
type Props = {
    initialProductType: string,
    children: any, // eslint-disable-line
}
type State = {
}

export const TabViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`
export const TabViewSection = styled.div`
    display: flex;
    justify-content: center;

    & > div {
        flex-grow: 1;
        max-width: 900px;
    }
`

const ProductTabView = React.lazy(() => import('../../../Elements/ProductTabView'));
class ConsumerProductTypesTabView extends React.Component<Props,State> {
    render(): JSX.Element {
        const { children, initialProductType } = this.props
        return (
                <React.Suspense fallback={<Loading message="Ladataan tuotteita"/>}>
                    <ProductTypesService>
                        {({ productTypes, errorMessage, loading }) => {
                                if(loading){
                                    return <Loading message="Ladataan tuotteita"/>
                                } else {
                                    return (
                                        <React.Suspense fallback={<Loading message="Ladataan tuotteita"/>}>
                                            <ProductTabView productTypes={productTypes} initialProductType={initialProductType} errorMessage={errorMessage}>
                                                {tabviewProps => children(tabviewProps)}
                                            </ProductTabView>
                                        </React.Suspense>
                                    )
                                }
                            }}
                    </ProductTypesService>       
                </React.Suspense>
        )
    }
}

export default ConsumerProductTypesTabView