// @flow
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

//type Props = {}

class ManagerRegisterSuccess extends Component/*<Props>*/ {
  render(): JSX.Element {
    return (
      <div className="wrapper">
        <h1>Tervetuloa Palveluun!</h1>
        <h5>
          Täältä löydät kaikki isännöimiesi taloyhtiöiden asiakkuuteen liittyvät
          asiat yhdestä paikasta.
        </h5>
        <NavLink to="/isannointi/kirjaudu">
          <button className="submit">Astu sisään</button>
        </NavLink>
      </div>
    )
  }
}

export default ManagerRegisterSuccess
