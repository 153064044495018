// @flow
import React, { Component } from 'react'

type Props = {
  toggleManagerOpen: () => void,
  toggleHouseOpen: () => void,
}

type State = {
  managerClicked: boolean,
  houseClicked: boolean,
}

class SuperuserTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      managerClicked: false,
      houseClicked: false,
    }
  }

  componentDidMount = async (): Promise<void> => {
    this.setState({ managerClicked: true })
  }

  managerOpen = async (): Promise<void> => {
    const { toggleManagerOpen } = this.props

    this.setState({
      managerClicked: true,
      houseClicked: false,
    })
    await toggleManagerOpen()
  }

  houseOpen = async (): Promise<void> => {
    const { toggleHouseOpen } = this.props

    this.setState({
      managerClicked: false,
      houseClicked: true,
    })
    await toggleHouseOpen()
  }

  render(): JSX.Element {
    const { managerClicked, houseClicked } = this.state

    return (
      <div className="tablist">
        <ul>
          <li>
            <h3
              id="manager-tab-trigger"
              className={`manager-link select${managerClicked ? 'ed' : ''}`}
              onClick={this.managerOpen}
            >
              Isännöitsijät
            </h3>
          </li>
          <li>
            <h3
              id="house-tab-trigger"
              className={`manager-link select${houseClicked ? 'ed' : ''}`}
              onClick={this.houseOpen}
            >
              Taloyhtiöt
            </h3>
          </li>
        </ul>
      </div>
    )
  }
}

export default SuperuserTab
