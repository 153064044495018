// @flow
import React from 'react'
import { inject, observer } from 'mobx-react/index'
import { NavLink } from 'react-router-dom'

import Nav from '../../../src/components/Elements/Nav'
import Logo from '../../theme/images/logo.svg'
import AppTheme from '../../../src/components/Providers/Theme'
import LoginStore from '../../store/LoginStore'
import { themeDetails } from '../../theme/theme.ts'

type Props = {
  location: Location
  LoginStore: typeof LoginStore
}

const Header = (props: Props): JSX.Element => {
  const { LoginStore, location } = props
  const { logged, firstName, lastName } = LoginStore
  const onLandingPage = location.pathname === '/'

  const name = firstName + ' ' + lastName

  const waterServices = themeDetails.services.indexOf('water') > -1

  return (
    <AppTheme themeName={onLandingPage ? 'inverted' : 'default'}>
      <div className="header">
        <div className="container">
          <NavLink to="/">
            <img src={Logo} className="logo" alt="logo" />
          </NavLink>
          <div className="container__items">
            {logged && waterServices && (
              <NavLink to="/vesilukema" activeClassName="active">
                Ilmoita vesilukema
              </NavLink>
            )}
            {logged && !onLandingPage && <h5>{name}</h5>}
            {logged && <Nav LoginStore={LoginStore} />}
            {!logged && !onLandingPage && (
              <NavLink className="home-link" to="/">
                Etusivu
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </AppTheme>
  )
}

export default inject('LoginStore')(observer(Header))
