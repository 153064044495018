// @flow
import React, { Component } from 'react'

// type Props = {}

class NotFound extends Component/*<Prop>*/ {
  render(): JSX.Element {
    return (
      <div className="notfound-wrapper container">
        <h1>404: Sivua ei löydy</h1>
      </div>
    )
  }
}

export default NotFound
