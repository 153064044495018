import React from 'react'
import { useLocation } from 'react-router-dom'
import BurgerMenu from './BurgerMenu'
import AppTheme from '../Providers/Theme'
import NavLinkList from './NavLinkList'


type Props = {
    logged: boolean;
    role: string;
    name: string;
    isMobile: boolean;
    isannointiohjeURL: string;
    logout: () => void;
}

export default function NavLinks(props: Props): JSX.Element {
    const { isMobile, name } = props
    const location = useLocation()
    return (
        <AppTheme themeName={location.pathname === '/' ? 'inverted' : 'default'}>
            <BurgerMenu title={name} isMobile={isMobile}>
                <AppTheme themeName="default">
                    <NavLinkList {...props}/>
                </AppTheme>
            </BurgerMenu>
        </AppTheme>
    )
}