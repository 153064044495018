import React, { Component } from 'react'
import ConfirmationDialog from './ConfirmationDialog'
import UserImg from '../../../../assets/images/NEVE_ihmishahmo.png'
import DownArrow from '../../../../assets/images/NEVE_alas.png'
import UpArrow from '../../../../assets/images/NEVE_ylös.png'
import Delete from '../../../../assets/images/NEVE_Roskakori.png'
import { Manager } from '../../../../store/index'

type Props = {
  value: number;
  manager: Manager;
  removeManager: (value: string) => void;
}

type State = {
  info: boolean;
  isOpen: boolean;
  email: string;
  name: string;
  loading: boolean;
}

class ManagerSingleton extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      info: false,
      isOpen: false,
      email: '',
      name: '',
    }
  }

  componentDidMount = async (): Promise<void> => {
    const { manager } = this.props
    this.setState({
      email: manager.remUser.email,
      name: manager.remUser.firstName + ' ' + manager.remUser.lastName,
    })
  }

  showAdditionalInfo = (): void => {
    const { info } = this.state
    this.setState({ info: !info })
  }

  openConfirmationDialog = async (): Promise<void> => {
    this.setState({ isOpen: true })
  }

  closeConfirmationDialog = async (): Promise<void> => {
    this.setState({ isOpen: false })
  }

  removeManager = async (): Promise<void> => {
    const { removeManager } = this.props
    const { name } = this.state
    await this.closeConfirmationDialog()
    await removeManager(name)
  }

  render(): JSX.Element|null {
    const { manager, value } = this.props
    const { info, isOpen, email } = this.state

    if (!manager) {
      return null
    }

    return (
      <li key={value} className="managerlisting">
        <div className="manager-container">
          <img src={UserImg} className="manager-img" alt="manager-img" />
          <div className="details">
            <p className="title name">
              {manager.remUser.firstName} {manager.remUser.lastName}
            </p>
          </div>
          <ConfirmationDialog
            isOpen={isOpen}
            email={email}
            removeManager={this.removeManager}
            closeConfirmationDialog={this.closeConfirmationDialog}
          />
          <div className="delete-link">
            <h3
              id="manager-delete-trigger"
              className="manager-listing-link manager-link"
              email={email}
              onClick={() => this.openConfirmationDialog(manager)}
            >
              <img src={Delete} className="delete-img" alt="delete" /> Poista
              isännöitsijä
            </h3>
          </div>
          <div className="additional-info-link">
            {info ? (
              <h3
                id="manager-show-less-trigger"
                className="manager-listing-link manager-link"
                onClick={() => this.showAdditionalInfo()}
              >
                Näytä vähemmän{' '}
                <img src={UpArrow} className="arrow-img " alt="down-arrow" />
              </h3>
            ) : (
              <h3
                id="manager-show-more-trigger"
                className="manager-listing-link manager-link"
                onClick={() => this.showAdditionalInfo()}
              >
                Näytä lisää{' '}
                <img src={DownArrow} className="arrow-img " alt="down-arrow" />
              </h3>
            )}
          </div>
        </div>
        {info && (
          <div className="additional-info">
            Taloyhtiöt:
            {manager.clients.map(client => (
              <div
                key={client.customerships[0].customer.id}
                className="manager-contract"
              >
                {client.customerships[0].customer.name}
              </div>
            ))}
          </div>
        )}
      </li>
    )
  }
}

export default ManagerSingleton
