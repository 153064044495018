// @flow
import React from 'react'
import { inject, observer } from 'mobx-react'
import { apiUrl } from '../../config/constants'
import { UsageData, Customer, Contract, Usage } from '../../store/index'
import getHeaders from '../../utils/getHeaders'
import { saveAs } from 'file-saver'
import Blob from 'blob'
import Moment from 'moment'
import { logger } from '../../config/constants'
import LoginStore from '../../store/LoginStore.js'

type Props = {
  customer?: Customer
  contract: Contract
  id: string
  type: string
  usageData?: UsageData | Usage
  LoginStore: typeof LoginStore
  isCompany: boolean
  start?: moment.Moment
  end?: moment.Moment
  text?: string
  inDashboard?: boolean
  companyID: string
}

type State = {
  success: boolean
  loading: boolean
  errorMessage: string
}

class ExcelButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      success: false,
      loading: false,
      errorMessage: '',
    }
  }

  loadData = async (): Promise<unknown> => {
    // eslint-disable-line
    const { LoginStore, contract, usageData } = this.props
    if (!usageData) return null

    this.setState({ loading: true })

    let id = ''
    const headers = getHeaders(true)

    if (LoginStore.type === 'consumer') {
      id = contract
        ? contract.mp.id
        : this.props.id || window.location.pathname.split('/')[2]
    } else {
      id = contract
        ? contract.mp.id
        : this.props.id || window.location.pathname.split('/')[3]
    }

    let start, end
    if (this.props.inDashboard) {
      // start and end date come from selected time frame on the card through props
      start = this.props.start
      end = this.props.end
    } else {
      const data = usageData as UsageData
      start = data.start
      end = data.end
    }

    const periodsForProductTypes = {
      heat: 'hour',
      water: 'day',
      electricity: 'hour', //should this really be data for every hour? -ADP
    }

    let period: string

    const type = (usageData as UsageData).type || this.props.contract.utility

    switch (type) {
      case 'heat':
        period = periodsForProductTypes.heat
        break
      case 'water':
        period = periodsForProductTypes.water
        break
      case 'electricity':
        period = periodsForProductTypes.electricity
        break
      default:
        // Electricity as default.
        period = periodsForProductTypes.electricity
    }

    if (period === 'month') {
      start = Moment(start).format('YYYY-MM')
      end = Moment(end).format('YYYY-MM')
    }

    const req = new Request(
      `${apiUrl}/${type}/${period}?mp=${id}&start=${start}&end=${end}&companyid=${this.props.companyID}&download=true&logintype=${this.props.LoginStore.type}`,
      {
        headers,
        method: 'GET',
        mode: 'cors',
      }
    )

    try {
      const response = await fetch(req)
      if (response.status === 404) {
        this.setState({
          errorMessage: 'Tietoa ei löytynyt',
          loading: false,
        })
      } else if (response.ok) {
        const data = await response.json()
        this.setState({ loading: false })
        const newId = this.props.id !== undefined ? this.props.id : id
        saveAs(
          new Blob([this.s2ab(data)], { type: 'application/octet-stream' }),
          'Kulutusdata_' +
          newId +
          '_' +
          this.props.type +
          '_' +
          this.currentDate() +
          '.xlsx'
        )

        const properties = {
          eventType: 'EXCEL_DOWNLOAD_SUCCESS',
          measurementPointId: id,
          category: type,
          userEmail: LoginStore.username,
        }
        logger.trackEvent('Excel (' + type + ') download success', properties)
      } else {
        this.setState({
          errorMessage: 'Lataaminen epäonnistui.',
          loading: false,
        })
      }
    } catch (error) {
      this.setState({
        errorMessage: 'Virhe tiedoston latauksessa.',
        loading: false,
      })
    }
  }

  // convert binary data to octet stream
  s2ab(s: string) {
    // eslint-disable-line
    const buf = new ArrayBuffer(s.length) // convert s to arrayBuffer
    const view = new Uint8Array(buf) // create uint8array as viewer
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff
    } // convert to octet
    return buf
  }

  currentDate() {
    const d = new Date()
    const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`
    const month =
      d.getMonth() < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}` // getMonth is zero-based so adding + 1
    const year = d.getFullYear().toString()
    return year + month + day
  }

  render() {
    const { loading } = this.state
    const buttonClass = loading
      ? 'download-button excel loading'
      : 'download-button excel'
    const loadingText = loading
      ? 'Ladataan'
      : this.props.inDashboard
        ? this.props.text
        : 'Lataa Excel-tiedostoon'

    return (
      <span className="btn-wrapper">
        <button
          className={buttonClass}
          onClick={this.loadData}
          disabled={loading}
        >
          {loadingText}
        </button>
      </span>
    )
  }
}

export default inject('LoginStore')(observer(ExcelButton))
