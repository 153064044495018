// @flow
import React, { Component } from 'react'

type Props = {
  name: string,
  productClass: string,
  message?: string,
  htmlContent?: string,
  innerHtml?: boolean,
}

class MissingProduct extends Component<Props> {
  createMarkup(markup: string): {__html: string} {
    return { __html: markup }
  }

  render(): JSX.Element {
    const {
      name,
      productClass,
      message = '',
      htmlContent,
      innerHtml,
    } = this.props
    return (
      <div className={productClass}>
        <div className="right">
          <div className="period">
            <p className="name">{name}</p>
          </div>
          <div className="heading">
            {innerHtml &&
              htmlContent && (
                <div dangerouslySetInnerHTML={this.createMarkup(htmlContent)} />
              )}
            <h2>{message}</h2>
          </div>
        </div>
      </div>
    )
  }
}

export default MissingProduct
