// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Modal from 'react-modal'
import { apiUrl } from '../../../config/constants'
import LoginStore from '../../../store/LoginStore'
import getHeaders from '../../../utils/getHeaders'

type Props = {
  modalIsOpen: boolean,
  updateModalState: Promise<void>,
  updateClients: Promise<void>,
  LoginStore: typeof LoginStore,
}

type State = {
  acceptConditionsCheckbox: boolean,
  companyId: string,
  customerName: string,
  errorMessage: string,
  loading: boolean,
  modalIsOpen: boolean,
  officialNumber: string,
  searchInfo: string,
  showHouseInfo: boolean,
  detailedError: any, // eslint-disable-line
}

class HouseModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { modalIsOpen } = this.props
    this.state = {
      acceptConditionsCheckbox: false,
      companyId: '',
      customerName: '',
      errorMessage: '',
      loading: false,
      modalIsOpen: modalIsOpen,
      officialNumber: '',
      searchInfo: '',
      showHouseInfo: false,
      detailedError: null,
    }
  }

    scrollableViewStyle = {
        maxHeight: 100,
        overflowY: "auto"
    }

    UNSAFE_componentWillMount = async () => {
    Modal.setAppElement('body')
  }

  UNSAFE_componentWillReceiveProps = async (nextProps: Props) => {
    await this.setState({ modalIsOpen: nextProps.modalIsOpen })
  }

  closeModal = async () => {
    const { updateModalState } = this.props
    this.setState({
      companyId: '',
      errorMessage: '',
      showHouseInfo: false,
      acceptConditionsCheckbox: false,
      detailedError: null,
    })
    await updateModalState()
  }

  handleSubmit = async (event: Event) => {
    event.preventDefault();
    const { companyId } = this.state

    this.setState({
      loading: true,
      errorMessage: '',
      acceptConditionsCheckbox: false,
      detailedError: null
    })


    // Must be valid remCompany user to call /remcompany/addClient
    const req = new Request(
      `${apiUrl}/remcompany/customership/${companyId.trim()}`,
      {
        method: 'GET',
        mode: 'cors',
      }
    )

    try {
      const response = await fetch(req)
      if (response.status === 400) {
        this.setState({
          errorMessage: 'Ei hakutuloksia',
          showHouseInfo: false,
        })
      } else if (response.status === 404) {
        this.setState({
          errorMessage: 'Ei hakutuloksia',
          showHouseInfo: false,
        })
      } else if (response.ok) {
        const data = await response.json()
        if (data.length > 0) {
          this.setState({
            showHouseInfo: true,
            errorMessage: '',
            officialNumber: data[0].customer.official,
            customerName: data[0].customer.name,
          })
        } else {
          this.setState({
            errorMessage: 'Ei hakutuloksia',
            showHouseInfo: false,
          })
        }
      } else {
        this.setState({
          errorMessage: 'Virhe hakua suorittaessa',
          showHouseInfo: false,
          loading: false,
        })
      }

      this.setState({ loading: false })
    } catch (error) {
      this.setState({
        errorMessage: 'Yhteysvirhe hakua suorittaessa',
        showHouseInfo: false,
        loading: false,
      })
    }
  }

  saveCompany = async () => {
    const { updateClients } = this.props
    const { acceptConditionsCheckbox, companyId } = this.state

    if (!acceptConditionsCheckbox) {
      this.setState({ errorMessage: 'Hyväksy ehdot ennen tallentamista' })
      return
    }

    this.setState({ loading: true, showHouseInfo: false, errorMessage: '' })

    const body = JSON.stringify({
      clientId: companyId,
    })

    const headers = getHeaders()

    // Must be valid remCompany user to call /remcompany/addClient
    const req = new Request(`${apiUrl}/remcompany/addClient`, {
      body,
      headers: headers,
      method: 'POST',
      mode: 'cors',
    })

    try {
      const response = await fetch(req)
      if (response.status === 404) {
        return null
      } else if (response.status === 403) {
        const json = await response.json()

        let innerMsg = null;
        try {
          innerMsg = JSON.parse(json.error);
        } catch(err) {
          // Normal message
        }
        if (innerMsg && innerMsg.error === 'PRH_ERROR') {
          this.setState({
            errorMessage: null,
            loading: false,
            showHouseInfo: false,
            detailedError: innerMsg,
          })
        } else {
          this.setState({
            errorMessage: 'Virhe asuntoyhtiölistaa virkistäessä',
            loading: false,
            showHouseInfo: false,
          })
        }
      } else if (response.ok) {
        await LoginStore.login('rem', true)
        await updateClients()
      } else {
        this.setState({
          errorMessage: 'Virhe asuntoyhtiölistaa virkistäessä',
          loading: false,
          showHouseInfo: false,
        })
      }
    } catch (error) {
      this.setState({
        errorMessage: 'Yhteysvirhe asuntoyhtiölistaa virkistäessä',
        loading: false,
        showHouseInfo: false,
      })
    }
  }

  handleInputChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value.trim()
    const id = target.id

    this.setState({
      [id]: value,
    })
  }

  render() {
    const {
      loading,
      modalIsOpen,
      companyId,
      searchInfo,
      showHouseInfo,
      customerName,
      officialNumber,
      acceptConditionsCheckbox,
      errorMessage,
      detailedError,
    } = this.state

    const ytj_ohje = LoginStore.getTemplate('ytj-ohje.html')

    return (
      <Modal
        isOpen={modalIsOpen}
        className={'house-modal'}
        overlayClassName={'house-modal-overlay'}
        shouldCloseOnOverlayClick={true}
      >
        <input
          type="submit"
          value="Sulje"
          id="close-button"
          onClick={this.closeModal}
          disabled={loading}
        />

        <form onSubmit={this.handleSubmit} id="addClientForm">
          <label htmlFor="companyId">Y-tunnus</label>
          <input
            type="text"
            id="companyId"
            onChange={this.handleInputChange}
            value={companyId}
          />
          <input type="submit" value="Hae" disabled={loading} />
        </form>

        {loading && <p>Ladataan</p>}

        {searchInfo && <p className="errortext">{searchInfo}</p>}

        {showHouseInfo && (
          <div className="customer-information">
            <p>Asiakkaan nimi: {customerName}</p>
            <p>
              Y-tunnus: <span id="customerCode">{officialNumber}</span>
            </p>
            <div>
              <input
                type="checkbox"
                id="acceptConditionsCheckbox"
                checked={acceptConditionsCheckbox}
                onChange={this.handleInputChange}
              />
              <label htmlFor="acceptConditionsCheckbox">
                Vakuutan olevani kyseisen asunto-osakeyhtiön edustaja
              </label>
              <p>
                Asunto-osakeyhtiö voi olla vain yhdellä isännöitsijätoimistolla
                kerrallaan
              </p>
            </div>

            <input type="submit" value="Tallenna" onClick={this.saveCompany} />
          </div>
        )}

        {errorMessage && <p className="errortext">{errorMessage}</p>}

        {detailedError && (
          <div>
            <p className="errortext">Isännöitsijän ja asunto-osakeyhtiön tiedot eivät täsmää.
            Palvelu tuntee toimistonne seuraavilla nimillä:</p>
            <ul style={this.scrollableViewStyle}>
              {detailedError.remNames.map((name, index) => <li key={index}><i>{name}</i></li>)}
            </ul>
            <p className="errortext">mutta taloyhtiön voimassaolevista osoitetiedoista YTJ:stä löytyvät vain seuraavat nimet:</p>
            <ul style={this.scrollableViewStyle}>
              {detailedError.prhNames.map((name, index) => <li key={index}><i>{name == null ? "[Tyhjä]" : name}</i></li>)}
            </ul>
            <p className="errortext">Ole hyvä ja päivitä taloyhtiön osoitetiedot PRH:n YTJ-järjestelmään siten, että posti- tai käyntiosoitteen
              c/o-tarkenteessa on joku toimistonne em. nimistä ja yritä sen jälkeen uudelleen.</p>
            {ytj_ohje && (
              <div
                dangerouslySetInnerHTML={{ __html: ytj_ohje }}
              />
            )}
          </div>
        )}
      </Modal>
    )
  }
}

export default inject('LoginStore')(observer(HouseModal))
