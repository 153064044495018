// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { apiUrl } from '../../../../config/constants'
import Modal from 'react-modal'
import LoginStore from '../../../../store/LoginStore'

type Props = {
  isOpen: boolean,
  email: string,
  removeManager: (value: string) => void,
  closeConfirmationDialog: () => void,
  LoginStore: typeof LoginStore,
}

type State = {
  loading: boolean,
  isOpen: boolean,
  errorMessage: string,
}

class ConfirmationDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { isOpen } = this.props
    this.state = {
      loading: false,
      isOpen: isOpen,
      errorMessage: '',
    }
  }

  UNSAFE_componentWillMount = async () => {
    Modal.setAppElement('body')
  }

  UNSAFE_componentWillReceiveProps = async (nextProps: Props) => {
    await this.setState({ isOpen: nextProps.isOpen })
  }

  closeModal = async () => {
    const { closeConfirmationDialog } = this.props
    this.setState({
      isOpen: false,
    })
    await closeConfirmationDialog()
  }

  removeManager = async () => {
    const { email, removeManager } = this.props

    this.setState({ loading: true })

    const req = new Request(`${apiUrl}/user/${email}`, {
      method: 'DELETE',
      mode: 'cors',
    })
    try {
      const response = await fetch(req)
      if (response.status === 403) {
        // console.log('403: not found')
        this.setState({
          errorMessage: 'Käyttäjää ei löytynyt',
          loading: false,
        })
      } else if (response.status === 409) {
        // console.log('401: unauothorized')
        this.setState({
          errorMessage: 'Virhe käyttäjätunnusta poistaessa',
          loading: false,
        })
      } else if (response.ok) {
        this.setState({
          loading: false,
          isOpen: false,
        })
        await removeManager()
      } else {
        this.setState({
          loading: false,
          errorMessage: 'Virhe käyttäjätunnusta poistaessa',
        })
      }
    } catch (error) {
      this.setState({
        errorMessage: 'Virhe käyttäjätunnusta poistaessa',
        loading: false,
      })
    }
  }

  render() {
    const { loading, isOpen } = this.state

    return (
      <Modal
        isOpen={isOpen}
        className={'confirmation-dialog'}
        overlayClassName={'confirmation-dialog-overlay'}
        shouldCloseOnOverlayClick={true}
      >
        <h1>Haluatko varmasti poistaa isännöitsijän?</h1>

        <input
          type="submit"
          value="Sulje"
          id="close-button"
          onClick={this.closeModal}
          disabled={loading}
        />

        <input
          className={loading ? 'loading' : ''}
          type="submit"
          name="submit"
          value="Kyllä"
          onClick={this.removeManager}
          disabled={loading}
        />
      </Modal>
    )
  }
}

export default inject('LoginStore')(observer(ConfirmationDialog))
