// @flow
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import FiberIcon from '../../../assets/images/icon-fiber.svg'
import HouseModal from './HouseModal'
import { getMappedContracts } from '../../../utils/getMappedContracts'
import LoginStore from '../../../store/LoginStore'
import type { ContractMapped } from '../../../store/index'
import Loading from '../Shared/Loading'
import HouseSingleton from './Dashboard/HouseSingleton'

import { themeDetails } from '../../../theme/theme.ts'

type Props = {
  LoginStore: typeof LoginStore,
}

type State = {
  contracts?: Array<ContractMapped>,
  hasData: boolean,
  loading: boolean,
  modalIsOpen: boolean,
  errorMessage: string,
}

class ManagerDashboard extends Component<Props, State> {
  constructor() {
    super()
    this.state = {
      contracts: null,
      hasData: false,
      loading: true,
      modalIsOpen: false,
      errorMessage: '',
    }
  }

  serviceWrapperStyle = {
    clear: 'both',
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    if (!LoginStore.logged) {
      await LoginStore.login('rem', true)
    }

    await this.getData()
    this.setState({ loading: false })
  }

  getData = async () => {
    // Get all mapped contracts
    const contractsMapped = getMappedContracts()

    if (contractsMapped && contractsMapped.length > 0) {
      await this.setState({
        contracts: contractsMapped,
        hasData: true,
        loading: true,
      })
    } else {
      // No contract data
      this.setState({ hasData: false })
    }
  }

  toggleModal = async () => {
    const { modalIsOpen } = this.state

    await this.setState({
      modalIsOpen: !modalIsOpen,
    })
  }

  updateModalState = async () => {
    await this.toggleModal()
  }

  updateClients = async () => {
    await this.setState({ loading: true, contracts: [] })
    await this.getData()
    await this.setState({ loading: false, modalIsOpen: false })
  }

  render() {
    const {
      contracts,
      hasData,
      loading,
      errorMessage,
      modalIsOpen,
    } = this.state
    if (loading) {
      return <Loading message="Ladataan käyttötietoja..." />
    }
    return (
      <div className="wrapper dashboard manager container">
        <div className="products col-md-12">
          <h1>Edustamani asunto-osakeyhtiöt</h1>
          <div className="open-modal">
            <h3
              id="house-add-trigger"
              className="manager-link open-modal-trigger"
              onClick={this.toggleModal}
            >
              Lisää asunto-osakeyhtiö
            </h3>
          </div>

          <HouseModal
            modalIsOpen={modalIsOpen}
            updateModalState={this.updateModalState}
            updateClients={this.updateClients}
          />

          {!hasData && <h5>Ei lisättyjä asunto-osakeyhtiöitä</h5>}

          {errorMessage && <p className="errortext">{errorMessage}</p>}

          {hasData && (
            <ul className="houselist">
              {contracts.map((item, index) => (
                <HouseSingleton key={index} contractsMapped={item} />
              ))}
            </ul>
          )}
        </div>

        {themeDetails.services.indexOf('fibre') > -1 && (
          <div className="col-md-12" style={this.serviceWrapperStyle}>
            <div className="row">
              <div className="services col-md-6">
                <div
                  className="fiberad"
                  dangerouslySetInnerHTML={{
                    __html: LoginStore.getTemplate(`fiber0.html`, {
                      $FIBER_IMG_SRC: FiberIcon,
                    }),
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default inject('LoginStore')(observer(ManagerDashboard))
