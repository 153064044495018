import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import { postDueDateChange } from '../../../../actions/getRopoDetails';
import { ropoInvoice } from '../../../../types/ropo';


interface PostponeInvoiceDateProps {
  invoiceNumber: string;
  paymentAgreementMaxDueDate: string | null
  originalDueDate: string
  setInvoices: React.Dispatch<React.SetStateAction<ropoInvoice[]>>;
}

const PostponeInvoiceDate: React.FC<PostponeInvoiceDateProps> = ({
  invoiceNumber,
  paymentAgreementMaxDueDate,
  originalDueDate,
  setInvoices
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [date, setDate] = useState<Moment | null>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (successMessage) {
      timer = setTimeout(() => {
        setModalIsOpen(false);
        setSuccessMessage("");
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [successMessage]);

  const confirmDateChange = async () => {
    if (date) {
      setIsLoading(true);
      setErrorMessage("");

      try {
        setIsLoading(true);
        const formattedDate = date.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
        const updatedInvoice = await postDueDateChange(invoiceNumber, formattedDate);

        setInvoices((prevInvoices: ropoInvoice[]) => {

          if (!prevInvoices) {
            throw new Error('prevInvoices is undefined');
          }

          return prevInvoices.map(inv => {
            if (inv.invoice_number === updatedInvoice.invoice_number) {
              // Update only certain fields for invoices. Dont replace updated invoice with old one
              return {
                ...inv,
                payment_agreement_max_duedate: updatedInvoice.payment_agreement_max_duedate,
                is_payment_agreement_allowed: updatedInvoice.is_payment_agreement_allowed,
                payment_agreement_type: updatedInvoice.payment_agreement_type,
                duedate_changed: updatedInvoice.duedate_changed,
                duedate: updatedInvoice.duedate,
              };
            }
            return inv;
          });
        });

        setIsLoading(false);
        setSuccessMessage("Laskun siirto onnistui");
      } catch (error) {
        console.error("Päivämäärän päivityksessä tapahtui virhe:", error);
        setIsLoading(false);
        setErrorMessage("Laskun eräpäivän siirto epäonnistui");
      }
    }
  };

  const maxDueDate = moment(paymentAgreementMaxDueDate);
  const minDate = moment(originalDueDate);
  const isOutsideRange = (day: Moment) => {
    return day.isBefore(minDate, 'day') || day.isAfter(maxDueDate.clone().subtract(1, 'days'), 'day');
  };


  return (
    <td>
      <button className="open-modal-btn" onClick={() => setModalIsOpen(true)}>Siirrä eräpäivää</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false)
          setErrorMessage("");
          setSuccessMessage("");
        }}
        className="custom-modal"
        overlayClassName="custom-overlay"
        contentLabel="Valitse uusi eräpäivä"
      >
        <div className="datepicker-wrapper">
          {isLoading ? (
            <div>Ladataan...</div>
          ) : errorMessage ? (
            <div>{errorMessage}</div>
          ) : successMessage ? (
            <div>{successMessage}</div>
          ) : successMessage ? (
            <div>{successMessage}</div>
          ) : (
            <>
              <SingleDatePicker
                date={date}
                onDateChange={setDate}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                id="date_input"
                placeholder="Valitse uusi eräpäivä"
                isOutsideRange={isOutsideRange}
                numberOfMonths={1}
                withPortal={true}
                initialVisibleMonth={() => maxDueDate}
              />
              <button className="confirm-btn" onClick={confirmDateChange}>Vahvista eräpäivän muutos</button>
            </>
          )}
        </div>
      </Modal>
    </td>
  );
};

export default PostponeInvoiceDate;
